import { z } from 'zod';

import { dataWithTempCopyWithVersionAndWarnings, ResponseDetailErrorSchema, VersionSchema } from '@app/utils/zod.utils';
import {
  SubmissionGiCalcDataOutDtoSchema,
  SubmissionRiskFactorsOutDtoSchema,
} from '@app/domain/submission/schemas/submission.schema';
import { EImpact, EOutcomeType, EStiGrade, ETrendExpected } from '@app/swagger-types';

const StateComparisonSchema = z.object({
  state: z.string().optional(),
  impactWeight: z.number().optional(),
  blsSourceUsed: z.string().optional(),
  perfectMatch: z.string().optional(),
  naicsCodeUsed: z.string().optional(),
  phraseology: z.string().optional(),
  impactWeightPercent: z.number().optional(),
});

const StateNcciComparisonSchema = z.object({
  stateClassCode: z.string().optional(),
  impactWeight: z.number().optional(),
  ncciReportedPolicies: z.string().optional(),
  phraseology: z.string().optional(),
  impactWeightPercent: z.number().optional(),
  companyPayroll: z.number().optional(),
  avgNcciPayroll: z.number().optional(),
  ncciAvgMedIncurred: z.number().optional(),
  avgNcciIndemIncurred: z.number().optional(),
  ncciAvgTotalIncurred: z.number().optional(),
});

const ComparisonSchema = z.object({
  emr: z.string().optional(),
  trir: z.string().optional(),
  dart: z.string().optional(),
  medicalClaims: z.string().optional(),
  indemnityClaims: z.string().optional(),
  totalClaims: z.string().optional(),
  medicalLosses: z.string().optional(),
  indemnityLossed: z.string().optional(),
  totalLosses: z.string().optional(),
});

const ComparisonsSchema = z.object({
  company: ComparisonSchema.optional(),
  expected: ComparisonSchema.optional(),
  states: z.array(StateComparisonSchema).optional(),
  stateNcci: z.array(StateNcciComparisonSchema).optional(),
});

export const StateOutDtoSchema = z.object({
  id: z.number(),
  code: z.string(),
  title: z.string(),
});

export const VersionsOutDtoSchema = z.object({
  responseMessage: z.string(),
  responseCode: z.number(),
  versions: z.array(z.string()),
});

export const StatesOutDtoSchema = z.array(StateOutDtoSchema);

export const NCCIOutDtoSchema = z.object({
  id: z.number(),
  ncci: z.string(),
  classificationWording: z.string(),
});

export const NaicsOutDtoSchema = z.object({
  id: z.number(),
  naics: z.string(),
  description: z.string(),
});

export const IncidentStatOutDtoSchema = z.object({
  impact: z.nativeEnum(EImpact),
  text: z.string(),
});

export const CalcResponseStateOutDtoSchema = z.object({
  classCode: z.string(),
  code: z.string(),
  expectedClaims: z.number(),
  filing: z.string(),
  finalCrDB: z.number(),
  finalPremium: z.number(),
  netFinalRate: z.number(),
  payroll: z.number(),
  safeTierPremium: z.number(),
  stiCrDB: z.string(),
  formattedStiCrDb: z.string(),
});

export const AwsResponseSateOutDtoSchema = z.object({
  stateCode: z.string(),
  ncciCode: z.string(),
  payroll: z.string(),
  finalPremium: z.string(),
  finalCrDb: z.string(),
  filingType: z.string(),
  projectedLosses: z.string(),
  lc: z.string(),
  lcm: z.string(),
});

export const ClaimsOutDtoSchema = z.object({
  actualClaims: z.number(),
  expectedClaims: z.number(),
  actualLossess: z.number(),
  expectedLosses: z.number(),
});

export const AssessmentClaimOutDtoSchema = z.object({
  trendOver: z.string(),
  trendExpected: z.nativeEnum(ETrendExpected),
});

export const AwsCalcOutDtoSchema = z.object({
  companyName: z.string(),
  sti: z.number(),
  premium: z.string(),
  coEmr: z.number(),
  expectedEmr: z.number(),
  claimsSummary: z.object({
    medical: ClaimsOutDtoSchema,
    indemnity: ClaimsOutDtoSchema,
    totals: ClaimsOutDtoSchema,
  }),
  projectedLosses: z.number(),
  assessmentSummary: z.object({
    medicalClaims: AssessmentClaimOutDtoSchema,
    indemnityClaims: AssessmentClaimOutDtoSchema,
    avgMedicalLossPerIndicent: AssessmentClaimOutDtoSchema,
    avgIndemnityLossPerIncident: AssessmentClaimOutDtoSchema,
  }),
  stateOutputs: z.array(AwsResponseSateOutDtoSchema),
  comparisons: ComparisonsSchema.optional(),
});

export const StateInputDtoSchema = z.object({
  stateCode: z.string(),
  ncci: z.string(),
  payroll: z.number(),
});

export const AwsStateInputDtoSchema = StateInputDtoSchema.extend({
  zipCode: z.string(),
});

export const ClaimsDtoSchema = z.object({
  medicalClaims: z.number(),
  indemnityClaims: z.number(),
  totalMedicalLosses: z.number(),
  totalIndemnityLosses: z.number(),
});

export const FullCalcInDtoSchema = z.object({
  companyName: z.string().optional().nullable(),
  naicsCode: z.string(),
  emr: z.number(),
  trir: z.number(),
  da: z.number(),
  rt: z.number(),
  safety: z.boolean(),
  health: z.boolean(),
  supplemental: z.boolean(),
  stateInputs: z.array(StateInputDtoSchema),
});

export const AwsCalcInDtoSchema = z.object({
  companyName: z.string().optional().nullable(),
  emr: z.number(),
  yearsInBusiness: z.number(),
  targetLossRatio: z.number().optional().nullable(),
  mostRecent: ClaimsDtoSchema,
  previous: ClaimsDtoSchema.optional().nullable(),
  twoYearsPrevious: ClaimsDtoSchema.optional().nullable(),
  stateInputs: z.array(AwsStateInputDtoSchema),
});

export const FullCalcOutDtoSchema = z.object({
  companyName: z.string(),
  sti: z.number(),
  stiGrade: z.nativeEnum(EStiGrade),
  confidence: z.string(),
  stpPremium: z.number(),
  totalExpClaims: z.number(),
  finalPremium: z.number(),
  coEmr: z.number(),
  coTrir: z.number(),
  coDa: z.number(),
  coRt: z.number(),
  coSafety: z.number().optional(),
  coSupplemental: z.number().optional(),
  coHealth: z.number().optional(),
  expectedEmr: z.number(),
  expectedTrir: z.number(),
  expectedRt: z.number(),
  expectedDa: z.number(),
  assocDues: z.number(),
  states: z.array(CalcResponseStateOutDtoSchema),
  chartData: z.array(
    z.object({
      webp_without_rt: z.string(),
    })
  ),
  incidentStats: z.array(IncidentStatOutDtoSchema),
  comparisons: ComparisonsSchema.optional(),
});

export const FullCalcOutDtoResponseSchema = z
  .object({
    originalCrDb: z.number().optional(),
    originalStates: z.array(CalcResponseStateOutDtoSchema).optional(),
    riskFactors: SubmissionRiskFactorsOutDtoSchema.optional(),
  })
  .merge(dataWithTempCopyWithVersionAndWarnings(FullCalcOutDtoSchema, FullCalcInDtoSchema));
export const AwsCalcOutDtoResSchema = dataWithTempCopyWithVersionAndWarnings(AwsCalcOutDtoSchema, AwsCalcInDtoSchema);

export const AbstractCalcOutDtoSchema = z.union([
  z.object({
    isSuccessful: z.boolean(),
    responseId: z.number().optional(),
    responseType: z.nativeEnum(EOutcomeType),
    version: VersionSchema.optional(),
    createdDate: z.string().optional(),
    data: z.union([FullCalcOutDtoSchema, AwsCalcOutDtoSchema, SubmissionGiCalcDataOutDtoSchema]),
    payload: z.union([FullCalcInDtoSchema, AwsCalcInDtoSchema, z.undefined()]),
    warnings: z.array(ResponseDetailErrorSchema).optional(),
    errors: z.array(ResponseDetailErrorSchema).optional(),
    riskFactors: SubmissionRiskFactorsOutDtoSchema.optional(),
  }),
  z.string(),
]);

export const SubmissionInsightsDtoSchema = z.object({
  insights: z.array(
    z.object({
      question: z.string(),
      answer: z.string(),
    })
  ),
});
