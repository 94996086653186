import { lazy } from 'react';

import { Routes } from '@app/constants/routes';
import { MyRouteObject } from '@app/models/router';
import { TypedNavigate } from '@app/router';
import { LossRatioModule } from './LossRatioModule';
import { HAS_ACCESS_TO_LOSS_RATIO } from '@app/environment/typed-env';

const LossRatiosPage = lazy(() => import('../loss-ratio/pages/LossRatiosPage'));
const LossRatioChartPdfPage = lazy(() => import('../loss-ratio/pdf/LossRatioChartPdfPage'));

export const LossRatioRoutes: MyRouteObject = {
  path: Routes.lossRatio.index,
  element: <LossRatioModule />,
  disabled: !HAS_ACCESS_TO_LOSS_RATIO,
  children: [
    { index: true, element: <LossRatiosPage /> },
    { path: Routes.lossRatio.chartPdf, element: <LossRatioChartPdfPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.lossRatio.index} /> },
  ],
};
