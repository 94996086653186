import { Outlet } from 'react-router-dom';

import { UserPoolContextProvider } from '@app/domain/user/context/UserPoolContext';

export const AdminModule = () => {
  return (
    <UserPoolContextProvider>
      <Outlet />
    </UserPoolContextProvider>
  );
};
