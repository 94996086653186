import { EImpact } from '../../../swagger-types';

export const renderImpactColor = (impact: EImpact) => {
  switch (impact) {
    case EImpact.NEGATIVE_IMPACT:
    case EImpact.POSITIVE_IMPACT:
    case EImpact.SAFE_TIER_SCORE:
      return '#FFFFFF';
    case EImpact.NEUTRAL_IMPACT:
      return '#1F2937';
    default:
      return '#1F2937';
  }
};

export const renderImpactBgColor = (impact: EImpact) => {
  switch (impact) {
    case EImpact.NEGATIVE_IMPACT:
      return '#C00000';
    case EImpact.NEUTRAL_IMPACT:
      return '#D9D9D9';
    case EImpact.POSITIVE_IMPACT:
      return '#385723';
    case EImpact.SAFE_TIER_SCORE:
      return '#1F2937';
    default:
      return '#fff';
  }
};

export const renderImpactLabel = (impact: EImpact) => {
  switch (impact) {
    case EImpact.NEGATIVE_IMPACT:
      return 'Negative';
    case EImpact.NEUTRAL_IMPACT:
      return 'Neutral';
    case EImpact.POSITIVE_IMPACT:
      return 'Positive';
    case EImpact.SAFE_TIER_SCORE:
      return <>SAFE-TIER&#8482; SCORE</>;
    default:
      return 'Unknown';
  }
};
