import { FC, useState } from 'react';
import { Chip, MenuItem, Typography } from '@mui/material';
import { Checkbox } from '@app/components/checkbox/Checkbox';
import { Autocomplete } from '@app/components/fields/autocomplete/Autocomplete';

export const AutocompleteExamples: FC = () => {
  const [values, setValues] = useState([{ label: '1', value: '1' }]);

  return (
    <>
      <Typography variant="h4" className="py-4">
        Autocompletes:
      </Typography>
      <Autocomplete
        className="my-1"
        browserAutocompleteOff
        disableCloseOnSelect
        InputProps={{
          placeholder: 'placeholder...',
          label: 'autocomplete',
        }}
        isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
        getOptions={async () => [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
        ]}
      />
      <Autocomplete
        className="my-1"
        multiple
        browserAutocompleteOff
        disableCloseOnSelect
        value={values}
        InputProps={{
          placeholder: 'placeholder...',
          label: 'multiple autocomplete',
        }}
        renderTags={() => null}
        isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
        onChange={(_, value) => {
          setValues(value || []);
        }}
        getOptions={async () => [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
        ]}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props}>
            <Checkbox label={option.label} checked={selected} disableRipple className="scale-75 py-0" />
          </MenuItem>
        )}
      />
      <div className="flex gap-2">
        {values.map((value, id) => (
          <Chip
            key={id}
            label={value.label}
            onDelete={() =>
              setValues((prevValues) => prevValues.filter((prevValue) => prevValue.value !== value.value))
            }
          />
        ))}
      </div>
    </>
  );
};
