import { typedEnv } from '@app/environment/typed-env';
import { DevKitSection } from './DevKitSection';
import { AuthDevKitSection } from './section/AuthDevKitSection';
import { EnvOverrideDevKitSection } from './section/EnvOverrideDevKitSection';

export const DevKitPage: React.FC = () => {
  return (
    <div className="mx-auto max-w-5xl p-8">
      <h3 className="mt-2 text-center font-bold">Dev Kit</h3>
      <AuthDevKitSection />
      <EnvOverrideDevKitSection />
      <DevKitSection title="Environment">
        <pre>{JSON.stringify(typedEnv, null, 2)}</pre>
      </DevKitSection>
    </div>
  );
};
