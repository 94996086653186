import Papa from 'papaparse';

import { getCurrentDateCST } from '../../../utils/date.utils';
import { AwsCalcOutDto, FullCalcOutDto } from '../types/calc.types';
import { renderTrendDetectedLabel } from './aws.utils';
import { renderDollarAmountString } from '@app/utils/currency/currency.utils';
import { SubmissionGiCalcDataOutDto } from '@app/swagger-types';

export const createCsvFileFromFullCalcResDto = (payload: FullCalcOutDto) => {
  const stiData = [
    ['STI', 'CONFIDENCE', 'STP PREMIUM', 'TOTAL EXP. CLAIMS', 'FINAL PREMIUM'],
    [
      payload.sti.toString(),
      `${payload.confidence}%`,
      renderDollarAmountString(payload.stpPremium),
      renderDollarAmountString(payload.totalExpClaims),
      renderDollarAmountString(payload.finalPremium),
    ],
  ];

  const stateData = [
    [
      'STATE',
      'CLASS CODE',
      'PAYROLL',
      'SAFE-TIER PREMIUM',
      'STI CR/DB',
      'EXPECTED CLAIMS',
      'FINAL PREMIUM',
      'FINAL CR/DB',
      'NET FINAL RATE',
      'FILLING',
    ],
  ];

  payload.states.forEach((state) => {
    stateData.push([
      state.code,
      state.classCode,
      renderDollarAmountString(state.payroll),
      renderDollarAmountString(state.safeTierPremium),
      `${state.stiCrDB}%`,
      renderDollarAmountString(state.expectedClaims),
      renderDollarAmountString(state.finalPremium),
      state.finalCrDB.toString(),
      state.netFinalRate.toString(),
      state.filing,
    ]);
  });

  const csvString = Papa.unparse([...stiData, [], ...stateData]);

  const csvDataBlob = new Blob([csvString], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvDataBlob);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = `exported_data_${getCurrentDateCST()}.csv`;
  link.click();
};

export const createCsvFileFromAwsCalcOutDto = (payload: AwsCalcOutDto, giData?: SubmissionGiCalcDataOutDto) => {
  // STI TABLE
  const stiData = [
    ['STI', 'Premium'],
    [payload.sti.toString(), renderDollarAmountString(Number(payload.premium))],
  ];

  // ALTERNATIVE RESULTS TABLE
  const alternativeResultsData = giData
    ? [
        ['Score', 'Predicted Losses'],
        [giData.stig.toString(), renderDollarAmountString(giData.predictedLosses)],
      ]
    : undefined;

  // EMR TABLE
  const emrData = [
    ['', 'Actual', 'Expected'],
    ['EMR', payload.coEmr.toString(), payload.expectedEmr.toString()],
  ];

  // CLAIMS SUMMARY TABLE
  const claimsSummaryData = [
    ['', 'Actual Claims', 'Expected Claims', 'Actual Losses', 'Expected Losses'],
    [
      'Medical',
      payload.claimsSummary.medical.actualClaims.toString(),
      payload.claimsSummary.medical.expectedClaims.toString(),
      payload.claimsSummary.medical.actualLossess.toString(),
      renderDollarAmountString(Number(payload.claimsSummary.medical.expectedLosses.toString())),
    ],
    [
      'Indemnity',
      payload.claimsSummary.indemnity.actualClaims.toString(),
      payload.claimsSummary.indemnity.expectedClaims.toString(),
      payload.claimsSummary.indemnity.actualLossess.toString(),
      renderDollarAmountString(Number(payload.claimsSummary.indemnity.expectedLosses.toString())),
    ],
    [
      'Total',
      payload.claimsSummary.totals.actualClaims.toString(),
      payload.claimsSummary.totals.expectedClaims.toString(),
      payload.claimsSummary.totals.actualLossess.toString(),
      renderDollarAmountString(Number(payload.claimsSummary.totals.expectedLosses.toString())),
    ],
  ];

  // STATES TABLE
  const stateData = [['State', 'Class Code', 'Payroll', 'Premium', 'CR/DB', 'Filling', 'LC', 'LCM']];

  payload.stateOutputs.forEach(({ stateCode, ncciCode, payroll, finalPremium, finalCrDb, filingType, lc, lcm }) => {
    stateData.push([
      stateCode,
      ncciCode,
      renderDollarAmountString(Number(payroll)),
      renderDollarAmountString(Number(finalPremium)),
      finalCrDb,
      filingType,
      lc,
      lcm,
    ]);
  });

  // ASSESSMENT SUMMARY TABLE
  const assessmentSummaryData = [
    ['Macro Claims and Avg Loss Analysis', 'Trend Over', 'Trend Detected'],
    [
      'Medical claims',
      payload.assessmentSummary.medicalClaims.trendOver,
      renderTrendDetectedLabel(payload.assessmentSummary.medicalClaims.trendExpected),
    ],
    [
      'Indemnity claims',
      payload.assessmentSummary.indemnityClaims.trendOver,
      renderTrendDetectedLabel(payload.assessmentSummary.indemnityClaims.trendExpected),
    ],
    [
      'Avg medical loss per incident',
      payload.assessmentSummary.avgMedicalLossPerIndicent.trendOver,
      renderTrendDetectedLabel(payload.assessmentSummary.avgMedicalLossPerIndicent.trendExpected),
    ],
    [
      'Avg indemnity loss per incident',
      payload.assessmentSummary.avgIndemnityLossPerIncident.trendOver,
      renderTrendDetectedLabel(payload.assessmentSummary.avgIndemnityLossPerIncident.trendExpected),
    ],
  ];

  const csvString = Papa.unparse(
    alternativeResultsData
      ? [
          [],
          ...stiData,
          [],
          ...alternativeResultsData,
          [],
          ...emrData,
          [],
          ...claimsSummaryData,
          [],
          ...stateData,
          [],
          ...assessmentSummaryData,
        ]
      : [[], ...stiData, [], ...emrData, [], ...claimsSummaryData, [], ...stateData, [], ...assessmentSummaryData]
  );

  const csvDataBlob = new Blob([csvString], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvDataBlob);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = `exported_data_${getCurrentDateCST()}.csv`;
  link.click();
};
