import styles from './Select.module.scss';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React from 'react';

import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import { Input, InputProps } from '@app/components/fields/input/Input';
import { CircularProgress } from '@app/components/CircularProgress';

export interface SelectProps<T> extends Omit<MuiSelectProps<T>, 'inputProps' | 'size'> {
  inputProps?: InputProps;
  size?: 'large' | 'medium' | 'small';
  withPlaceholder?: boolean;
  paperClassName?: string;
  endIcon?: React.ReactNode;
  isLoading?: boolean;
}

function SelectComponent<T>(
  {
    inputProps,
    children,
    disabled,
    MenuProps,
    className,
    paperClassName,
    withPlaceholder,
    size = 'medium',
    endIcon,
    isLoading,
    ...props
  }: SelectProps<T>,
  ref: React.Ref<unknown>
) {
  return (
    <MuiSelect
      input={<Input classes={{ root: 'flex-1' }} {...inputProps} />}
      variant={inputProps?.variant}
      disabled={disabled || isLoading}
      MenuProps={{
        classes: { paper: clsx(props.multiple ? styles.SelectPaperMultiple : styles.SelectPaper, paperClassName) },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        elevation: 1,
        ...MenuProps,
      }}
      classes={{
        select: clsx(
          styles.Select,
          styles[capitalize(size)],
          withPlaceholder && !props.value ? styles.WithPlaceholder : null,
          className
        ),
        disabled: styles.Disabled,
      }}
      ref={ref}
      IconComponent={(props) => {
        return isLoading ? (
          <div className="absolute right-3 flex items-center justify-start">
            <CircularProgress size={20} />
          </div>
        ) : !disabled ? (
          <>
            <div>{endIcon}</div>
            <div className={clsx(styles.ArrowIconWrapper, styles[capitalize(size)])}>
              <div
                className={clsx(
                  inputProps?.variant === 'filled' ? styles.ArrowNoBg : null,
                  styles.ArrowIcon,
                  props.className.includes('MuiSelect-iconOpen') ? styles.Expanded : null,
                  styles[capitalize(size)]
                )}
              >
                <ChevronDownIcon />
              </div>
            </div>
          </>
        ) : null;
      }}
      {...props}
    >
      {children}
    </MuiSelect>
  );
}

export const Select = React.forwardRef(SelectComponent) as <T>(
  p: React.PropsWithChildren<SelectProps<T>> & { ref?: React.Ref<unknown> }
) => React.ReactElement;
