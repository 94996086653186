import { FC, memo } from 'react';
import queryString from 'query-string';

import { ResponsiveManager, ThemeWrapper } from '@app/hoc';
import { QueryParamOptions, QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { DialogRenderComponent } from '@app/services/dialog-manager';
import { RootModule } from './RootModule';

const queryStringifyOptions: QueryParamOptions = {
  searchStringToObject: queryString.parse,
  objectToSearchString: queryString.stringify,
};

export const RootLayout: FC = memo(() => {
  return (
    <ThemeWrapper>
      <QueryParamProvider adapter={ReactRouter6Adapter} options={queryStringifyOptions}>
        <ResponsiveManager>
          <DialogRenderComponent />
          <RootModule />
        </ResponsiveManager>
      </QueryParamProvider>
    </ThemeWrapper>
  );
});
