import { Outlet } from 'react-router-dom';

import { LossRatioFiltersContextProvider } from '@app/domain/loss-ratios/context/LossRatioFiltersContext';

export const LossRatioModule = () => {
  return (
    <LossRatioFiltersContextProvider>
      <Outlet />
    </LossRatioFiltersContextProvider>
  );
};
