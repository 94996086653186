export const isString = (value: any): value is string => {
  return typeof value === 'string';
};

export const isBoolean = (value: any): value is boolean => {
  return typeof value === 'boolean';
};

export const guardForOneOf = <T>(key: any, ...arr: T[]): key is T => arr.includes(key);

export const guardForEnum = <T extends Record<string, any>>(key: any, obj: T): key is T[keyof T] =>
  Object.values(obj).includes(key);

export const guardForObjectWithKey = <K extends string, T>(key: K, obj: T): obj is T & Record<K, unknown> => {
  if (obj instanceof Object) {
    return key in obj;
  }
  return false;
};
