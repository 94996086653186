import { Routes } from '@app/constants/routes';

import { RoadmapModule } from './RoadmapModule';
import { RoadmapPage } from './pages/roadmap/Roadmap.page';
import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';

export const RoadmapRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.roadmap.index,
  element: <RoadmapModule />,
  children: [{ index: true, element: <RoadmapPage /> }],
});
