import { ReactNode } from 'react';

export enum EBodyCellBG {
  YELLOW = 'YELLOW',
  GRAY = 'GRAY',
  WHITE = 'WHITE',
}

export interface TableColumn<T> {
  headerName: string;
  subHeaderName?: string;
  width?: number | string;
  minWidth?: number;
  field: string;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  headAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  render?: (entity: T, index: number) => string | number | JSX.Element | null;
  disableSort?: boolean;
  disableClick?: boolean;
  reversedSort?: boolean;
  bodyCellClassName?: string;
  headCellClassName?: string;
  sticky?: 'right' | 'left';
  headerJSX?: ReactNode;
  bold?: boolean;
  headerCellBg?: EBodyCellBG;
  bodyCellBg?: EBodyCellBG;
}
