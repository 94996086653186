import { Button } from '@app/components/buttons/button/Button';
import { LossRatiosBarChart } from '@app/domain/loss-ratios/widgets/LossRatiosBarChart';
import { LossRatioChartOutDto } from '@app/swagger-types';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { FC, useState } from 'react';

interface Props {}

enum EValue {
  WITH_DATA = 'WITH_DATA',
  ZERO_DATA = 'ZERO_DATA',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
}

const generateRandomData = (): LossRatioChartOutDto[] => [
  {
    rowName: 'test 1',
    rowValue: Math.floor(Math.random() * 100),
  },
  {
    rowName: 'test 2',
    rowValue: Math.floor(Math.random() * 100),
  },
  {
    rowName: 'test 3',
    rowValue: Math.floor(Math.random() * 100),
  },
  {
    rowName: 'test 4',
    rowValue: Math.floor(Math.random() * 100),
  },
  {
    rowName: 'test 5',
    rowValue: Math.floor(Math.random() * 100),
  },
];

const zeroData: LossRatioChartOutDto[] = [
  {
    rowName: 'test 1',
    rowValue: 0,
  },
  {
    rowName: 'test 2',
    rowValue: 0,
  },
];

export const LossRatiosBarChartExamples: FC<Props> = () => {
  const [value, setValue] = useState<EValue>(EValue.WITH_DATA);
  const [data, setData] = useState<LossRatioChartOutDto[]>(generateRandomData());

  const setRandomData = () => setData(generateRandomData());

  return (
    <div className="col-span-5 p-10">
      <div className="flex w-full items-center justify-between gap-2 py-4">
        <Typography variant="h4">LossRatiosBarChart:</Typography>
        <Button onClick={setRandomData} disabled={value !== EValue.WITH_DATA}>
          Regenerate data
        </Button>
      </div>
      <FormControl>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue={EValue.WITH_DATA}
          value={value}
          name="radio-buttons-group"
          onChange={(_, val) => setValue(val as EValue)}
        >
          <FormControlLabel value={EValue.WITH_DATA} control={<Radio />} label="With Data" />
          <FormControlLabel value={EValue.ZERO_DATA} control={<Radio />} label="Zero Data" />
          <FormControlLabel value={EValue.EMPTY} control={<Radio />} label="Empty" />
          <FormControlLabel value={EValue.ERROR} control={<Radio />} label="With Error" />
        </RadioGroup>
      </FormControl>
      {value === EValue.WITH_DATA && <LossRatiosBarChart data={data} />}
      {value === EValue.ZERO_DATA && <LossRatiosBarChart data={zeroData} />}
      {value === EValue.EMPTY && <LossRatiosBarChart data={[]} />}
      {value === EValue.ERROR && <LossRatiosBarChart data={[]} errMsg="Some err message" />}
    </div>
  );
};
