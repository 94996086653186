import { FC, useMemo } from 'react';

import { Table } from '../../../components/table/Table';
import { TableColumn } from '../../../components/table/TableTypes';
import { ESubmissionRiskFactor, SubmissionRiskFactorOutDto, SubmissionRiskFactorsOutDto } from '../../../swagger-types';
import { isNumber } from 'lodash';
import { SubmissionUtils } from '@app/domain/submission/utils/submission.utils';

interface Props {
  riskFactors: SubmissionRiskFactorsOutDto;
  pdf?: boolean;
}

export const RiskFactorsTable: FC<Props> = ({ riskFactors, pdf }) => {
  const { risks, totalRiskFactor } = riskFactors;

  const cols: TableColumn<SubmissionRiskFactorOutDto>[] = [
    {
      headerName: 'Risk Factor',
      field: 'riskFactorName',
      align: 'left',
    },
    {
      headerName: 'Description',
      field: 'riskFactorDescription',
      align: 'left',
    },
    {
      headerName: 'Type',
      field: 'riskFactorStatus',
      align: 'left',
      render: ({ riskFactorStatus }) => SubmissionUtils.getFriendlyESubmissionRiskFactorLabel(riskFactorStatus),
    },
    {
      headerName: 'Severity',
      field: 'riskImpactLevelName',
      align: 'left',
    },
    {
      headerName: 'Adjustment',
      field: 'riskValue',
      align: 'right',
      render: ({ riskValue }) => (isNumber(riskValue) ? <>{riskValue}%</> : ''),
    },
  ];

  const tableData = useMemo(
    () => risks.filter(({ riskFactorStatus }) => riskFactorStatus !== ESubmissionRiskFactor.NOT_APPLICABLE),
    [risks]
  );

  return (
    <Table
      title={`RISK FACTORS ADJUSTMENT: ${totalRiskFactor}%`}
      cols={cols}
      tableData={tableData}
      pdf={pdf}
      hidePageSize
    />
  );
};
