import React from 'react';

import { Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableTypes';
import { AwsCalcOutDto, ClaimsOutDto } from '../../types/calc.types';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';

type RowType = ClaimsOutDto & {
  header: string;
};

type Props = Pick<AwsCalcOutDto, 'claimsSummary'> & {
  pdf?: boolean;
};

export const AwsClaimsSummaryTable: React.FC<Props> = ({ pdf, claimsSummary }) => {
  const rows: RowType[] = [
    {
      header: 'Medical',
      actualClaims: claimsSummary.medical.actualClaims,
      expectedClaims: claimsSummary.medical.expectedClaims,
      actualLossess: claimsSummary.medical.actualLossess,
      expectedLosses: claimsSummary.medical.expectedLosses,
    },
    {
      header: 'Indemnity',
      actualClaims: claimsSummary.indemnity.actualClaims,
      expectedClaims: claimsSummary.indemnity.expectedClaims,
      actualLossess: claimsSummary.indemnity.actualLossess,
      expectedLosses: claimsSummary.indemnity.expectedLosses,
    },
    {
      header: 'Total',
      actualClaims: claimsSummary.totals.actualClaims,
      expectedClaims: claimsSummary.totals.expectedClaims,
      actualLossess: claimsSummary.totals.actualLossess,
      expectedLosses: claimsSummary.totals.expectedLosses,
    },
  ];

  const cols: TableColumn<RowType>[] = [
    {
      headerName: '',
      field: 'header',
      headAlign: 'left',
      align: 'left',
      bold: true,
      width: 150,
    },
    {
      headerName: 'Actual Claims',
      field: 'actualClaims',
      headAlign: 'center',
      align: 'center',
      width: 150,
      render: ({ actualClaims }) => actualClaims,
    },
    {
      headerName: 'Benchmark Claims',
      field: 'expectedClaims',
      headAlign: 'center',
      align: 'center',
      bold: true,
      width: 150,
      render: ({ expectedClaims }) => expectedClaims,
    },
    {
      headerName: 'Actual Losses',
      field: 'actualLossess',
      headAlign: 'center',
      align: 'center',
      width: 150,
      render: ({ actualLossess }) => renderDollarAmount(actualLossess),
    },
    {
      headerName: 'Benchmark Losses',
      field: 'expectedLosses',
      headAlign: 'center',
      align: 'center',
      bold: true,
      width: 150,
      render: ({ expectedLosses }) => renderDollarAmount(expectedLosses),
    },
  ];

  return <Table cols={cols} tableData={rows} pdf={pdf} hidePageSize />;
};
