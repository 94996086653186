import { FC, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { Table, TableColumn } from '@app/components/table';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';
import { SubmissionLossClaimOutDto, SubmissionLossSummaryYearsOutDto } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';
import { SubmissionUtils } from '../utils/submission.utils';
import { format } from 'date-fns';
import { convertUTCToLocalDate } from '@app/utils/date.utils';
import { FORMAT } from '@app/constants/formats';
import { buildSortParam, parseSortParam } from '@app/utils/api.util';
import { orderBy } from 'lodash';
import { TruncatedText } from '@app/components/truncated-text/TruncatedText';

type SubmissionLossReviewCarrierRowType = Omit<SubmissionLossClaimOutDto, 'status' | 'type'> & {
  status: SubmissionLossClaimOutDto['status'] | string;
  type: SubmissionLossClaimOutDto['type'] | string;
  isTotal?: boolean;
};

const DEFAULT_SORT = buildSortParam<SubmissionLossReviewCarrierRowType>('dateOfLoss', 'asc');

interface Props {
  year: SubmissionLossSummaryYearsOutDto;
}

export const SubmissionLossSummaryYearsTable: FC<Props> = ({ year }) => {
  const [sort, setSort] = useState(DEFAULT_SORT);

  const {
    effectiveDateStart,
    effectiveDateEnd,
    claims,
    totalMedical,
    totalIndemnity,
    totalExpense,
    totalReserves,
    totalIncurred,
  } = year;

  const cols: TableColumn<SubmissionLossReviewCarrierRowType>[] = useMemo(
    () => [
      {
        headerName: 'Date of Loss',
        field: 'dateOfLoss',
        align: 'left',
        width: 120,
        render: ({ dateOfLoss, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>
            {isTotal ? dateOfLoss : format(convertUTCToLocalDate(dateOfLoss), FORMAT.DATE_YEAR)}
          </span>
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        align: 'center',
        width: 80,
        render: ({ status }) => SubmissionUtils.getFriendlySubmissionClaimStatusLabel(status),
      },
      {
        headerName: 'Type',
        field: 'type',
        align: 'center',
        width: 80,
        render: ({ type, isTotal }) => <span className={clsxm(isTotal && 'font-semibold')}>{type}</span>,
      },
      {
        headerName: 'Medical',
        field: 'medical',
        align: 'right',
        width: 120,
        render: ({ medical, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(medical, 2)}</span>
        ),
      },
      {
        headerName: 'Indemnity',
        field: 'indemnity',
        align: 'right',
        width: 120,
        render: ({ indemnity, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(indemnity, 2)}</span>
        ),
      },
      {
        headerName: 'Expense',
        field: 'expense',
        align: 'right',
        width: 120,
        render: ({ expense, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(expense, 2)}</span>
        ),
      },
      {
        headerName: 'Incurred',
        field: 'incurred',
        align: 'right',
        width: 120,
        render: ({ incurred, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(incurred, 2)}</span>
        ),
      },
      {
        headerName: 'Reserves',
        field: 'reserves',
        align: 'right',
        width: 120,
        render: ({ reserves, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{renderDollarAmount(reserves, 2)}</span>
        ),
      },
      {
        headerName: 'Details',
        field: 'details',
        align: 'left',
        minWidth: 140,
        disableSort: true,
        render: ({ details }) => (details ? <TruncatedText text={details} /> : null),
      },
    ],
    []
  );

  const tableData: SubmissionLossReviewCarrierRowType[] = useMemo(() => {
    let data = claims || [];

    const sortParam = sort.length ? parseSortParam(sort) : null;

    if (sortParam?.field && sortParam.order) {
      data = orderBy(data, [sortParam?.field], [sortParam.order]);
    }

    if (data.length) {
      return [
        ...data,
        // TOTAL Row
        {
          isTotal: true,
          dateOfLoss: `${format(convertUTCToLocalDate(effectiveDateStart), FORMAT.YEAR)}/${format(
            convertUTCToLocalDate(effectiveDateEnd),
            FORMAT.YEAR
          )} Totals`,
          status: '',
          type: `Claims: ${data.length}`,
          medical: totalMedical ?? 0,
          indemnity: totalIndemnity ?? 0,
          expense: totalExpense ?? 0,
          reserves: totalReserves ?? 0,
          incurred: totalIncurred ?? 0,
          details: '',
        },
      ];
    }

    return data;
  }, [
    sort,
    claims,
    totalMedical,
    totalIndemnity,
    totalExpense,
    totalReserves,
    totalIncurred,
    effectiveDateStart,
    effectiveDateEnd,
  ]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-fit flex-col gap-3 border-b border-b-primary py-1.5">
        <Typography className="text-xl">
          {format(convertUTCToLocalDate(effectiveDateStart), FORMAT.DATE_YEAR)} -{' '}
          {format(convertUTCToLocalDate(effectiveDateEnd), FORMAT.DATE_YEAR)}
        </Typography>
      </div>
      <Table
        tableLayout="fixed"
        notFoundMessage="No claims in this period"
        cols={cols}
        tableData={tableData}
        hidePageSize
        currentSort={sort}
        onSortChanged={setSort}
        tableCellClassName="overflow-x-visible"
      />
    </div>
  );
};
