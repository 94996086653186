import { UseFormReset } from 'react-hook-form';
import { useEffect } from 'react';

import { formatStiAwsPrefilledValues, formatStiFullPrefilledValues } from '@app/domain/sti/utils/form.utils';
import { awsCalcDefaultValues, fullCalcDefaultValues } from '@app/domain/sti/forms/calc-default-values';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '@app/domain/sti/forms/calc.form';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { AwsCalcOutDtoResponse, FullCalcOutDtoResponse } from '@app/domain/sti/types/calc.types';
import { EOutcomeType, SubmissionGiCalcOutDto } from '@app/swagger-types';
import { ESubmissionSafeTierRatingTab } from '../widgets/SubmissionTabs';
import {
  useGetSubmissionStiFullPrefill,
  useGetSubmissionStiAwsPrefill,
  useGetSubmissionStiOutcome,
} from '../api/submission.api.hooks';

const LAST_MILE_NCCI_VALUES = ['7230', '7231'];

export const useSubmissionCalcPrefill = (
  submissionId: string,
  outcomeType: EOutcomeType,
  reset: { full: UseFormReset<FullCalcValidationSchemaType>; aws: UseFormReset<AwsCalcValidationSchema> },
  values: { full: FullCalcValidationSchemaType; aws: AwsCalcValidationSchema }
) => {
  const { data: fullPrefillData, isLoading: isFullPrefillLoading } = useGetSubmissionStiFullPrefill(submissionId, {
    refetchOnMount: 'always',
    cacheTime: 0,
  });

  const { data: awsPrefillData } = useGetSubmissionStiAwsPrefill(submissionId, {
    refetchOnMount: 'always',
    cacheTime: 0,
    enabled: !isFullPrefillLoading,
  });

  const { data: outcomeData, isFetching: isOutcomeFetching } = useGetSubmissionStiOutcome(submissionId, outcomeType, {
    enabled: Boolean(submissionId),
    refetchOnMount: 'always',
    cacheTime: 0,
  });

  const { data: outcomeGiData, isFetching: isOutcomeGiDataFetching } = useGetSubmissionStiOutcome(
    submissionId,
    EOutcomeType.GI_CALC,
    {
      refetchOnMount: 'always',
      cacheTime: 0,
      enabled: Boolean(submissionId) && outcomeData?.responseType === EOutcomeType.AWS_CALC,
    }
  );

  const { onChangeFullCalcResult, onChangeAwsCalcResult, naics, ncci, setCalcTab, onChangeAwsGiData } =
    useSubmissionCalculatorFormsContext();

  useEffect(() => {
    if (outcomeData) {
      if (outcomeData.responseType === EOutcomeType.FULL_CALC) {
        onChangeFullCalcResult(outcomeData as FullCalcOutDtoResponse);
      }

      if (outcomeData.responseType === EOutcomeType.AWS_CALC) {
        onChangeAwsCalcResult(outcomeData as AwsCalcOutDtoResponse);
        onChangeAwsGiData(outcomeGiData as SubmissionGiCalcOutDto);
      }
    }
  }, [onChangeAwsCalcResult, onChangeAwsGiData, onChangeFullCalcResult, outcomeData, outcomeGiData]);

  useEffect(() => {
    const isLastMileTab = fullPrefillData?.payrollEntries?.some((entry) =>
      entry.ncci ? LAST_MILE_NCCI_VALUES.includes(entry.ncci) : false
    );

    if (isLastMileTab) {
      setCalcTab(ESubmissionSafeTierRatingTab.LAST_MILE);
    }
  }, [fullPrefillData?.payrollEntries, setCalcTab]);

  useEffect(() => {
    if (awsPrefillData && JSON.stringify(values.aws) === JSON.stringify(awsCalcDefaultValues)) {
      reset.aws(formatStiAwsPrefilledValues(awsPrefillData, ncci?.result || []));
    }
  }, [awsPrefillData, ncci?.result, reset, values.aws]);

  useEffect(() => {
    if (fullPrefillData && JSON.stringify(values.full) === JSON.stringify(fullCalcDefaultValues)) {
      reset.full(formatStiFullPrefilledValues(fullPrefillData, naics?.result || [], ncci?.result || []));
    }
  }, [fullPrefillData, naics?.result, ncci?.result, reset, values.full]);

  return {
    isOutcomeFetching,
    isOutcomeGiDataFetching,
  };
};
