import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { useUserPoolsQuery } from '../api/user.api.hooks';

const UserPoolContext = createContext({
  userPoolName: '',
  setUserPoolName: (_: string) => {},
});

interface Props {
  children: ReactNode;
}

export const UserPoolContextProvider: FC<Props> = ({ children }) => {
  const [userPoolName = '', setUserPoolName] = useLocalStorage('USER_POOL_NAME', '');

  useUserPoolsQuery({
    onSuccess: (data) => {
      if (data?.userPoolNameList[0] && (!userPoolName || !data.userPoolNameList.includes(userPoolName))) {
        setUserPoolName(data.userPoolNameList[0]);
      }
    },
  });

  const value = useMemo(
    () => ({
      userPoolName,
      setUserPoolName,
    }),
    [userPoolName, setUserPoolName]
  );

  return <UserPoolContext.Provider value={value}>{children}</UserPoolContext.Provider>;
};

export const useUserPoolContext = () => useContext(UserPoolContext);
