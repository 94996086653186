import { FC } from 'react';
import { Card, Skeleton, Typography } from '@mui/material';
import { useGetSubmissionLossSummaryQuery } from '../api/submission.api.hooks';
import { LossSummaryTable } from '../components/LossSummaryTable';
import { SubmissionLossSummaryYearsTable } from '../components/SubmissionLossSummaryYearsTable';

interface Props {
  submissionId: string;
}

export const SubmissionLossSummaryTab: FC<Props> = ({ submissionId }) => {
  const { data: lossSummaryData, isFetching: isFetchingLossSummary } = useGetSubmissionLossSummaryQuery(submissionId, {
    refetchOnMount: 'always',
  });

  if (!lossSummaryData && isFetchingLossSummary) {
    return (
      <div className="flex flex-col gap-6">
        <Skeleton height={360} variant="rectangular" className="rounded" />
        <Skeleton height={500} variant="rectangular" className="rounded" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <LossSummaryTable lossSummaryData={lossSummaryData} isFetching={isFetchingLossSummary} />
      <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
        <Typography className="py-4 text-xxxl">All Claims</Typography>
        {lossSummaryData?.years.length ? (
          lossSummaryData.years.map((year, index) => <SubmissionLossSummaryYearsTable key={index} year={year} />)
        ) : (
          <div className="flex h-36 items-center justify-center p-10">
            <Typography className="font-medium italic text-gray-600">No data to show</Typography>
          </div>
        )}
      </Card>
    </div>
  );
};
