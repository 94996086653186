import { PaletteMode } from '@mui/material';

export enum EDeviceBreakpointType {
  PHONE = 'phone',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  UNKNOWN = 'unknown',
}

export type MainUISlice = {
  navMobileOpen: boolean;
  setNavMobile: (navMobileOpen: boolean) => void;

  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  isGlobalLoading: boolean;
  setIsGlobalLoading: (isLoading: boolean) => void;
  theme: PaletteMode;
  setTheme: (theme: PaletteMode) => void;
  currentBreakpointDevice: {
    deviceType: EDeviceBreakpointType;
    isMobile: boolean;
    isDesktop: boolean;
    isTablet: boolean;
  };
  updateBreakpoint: (deviceType: EDeviceBreakpointType) => void;
};
