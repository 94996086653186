import { AuthUser } from '@app/auth/api/auth.dto';
import { typedEnv } from '@app/environment/typed-env';
import { ERole } from '@app/auth-types';
import { UserOutSchema } from '@app/auth/api/auth.schema';

export const isAdmin = (user: AuthUser): user is UserOutSchema => {
  return user.groups.some((role) =>
    [ERole.ALL_ADMINISTRATOR, ERole.INSURATE_USER_ADMIN, ERole.USISA_USER_ADMIN].includes(role)
  );
};

export const renderFriendlyRole = (role: ERole) => {
  switch (role) {
    case ERole.ALL_ADMINISTRATOR:
      return 'All Administrator';
    case ERole.INSURATE_USER_ADMIN:
      return 'Insurate Admin';
    case ERole.USISA_USER_ADMIN:
      return 'Usisa Admin';
    default:
      return role;
  }
};

export const renderRedirectToAuthServiceUrl = (srcUrl?: string) => {
  const url = new URL(`${typedEnv.REACT_APP_AUTH_LINK}/auth/sign-in`);

  url.searchParams.set('srcUrl', srcUrl || window.location.href);

  return url.href;
};

export const redirectToAuthService = (srcUrl?: string) => {
  window.location.replace(renderRedirectToAuthServiceUrl(srcUrl));
};
