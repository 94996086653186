import { Alert, Box, Paper, Skeleton, Typography, CircularProgress } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { FC, useCallback, useState } from 'react';
import queryString from 'query-string';
import { format } from 'date-fns';

import { RoleRequired } from '@app/hoc';
import { Button } from '@app/components/buttons/button/Button';
import { Routes } from '@app/constants/routes';
import { GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS } from '../const/sti-access.const';
import { AwsAssessmentSummaryTable } from '../components/aws-calc-tables/AwsAssessmentSummaryTable';
import { AwsClaimsSummaryTable } from '../components/aws-calc-tables/AwsClaimsSummaryTable';
import { ReactComponent as CSVIconSvg } from '../../../assets/icons/csv-icon.svg';
import { ReactComponent as PDFIconSvg } from '../../../assets/icons/pdf-icon.svg';
import { AwsStatesTable } from '../components/aws-calc-tables/AwsStatesTable';
import { AwsEmrTable } from '../components/aws-calc-tables/AwsEmrTable';
import { AwsAlternativeResultsTable } from '../components/aws-calc-tables/AwsAlternativeResultsTable';
import { AwsStiTable } from '../components/aws-calc-tables/AwsStiTable';
import { createCsvFileFromAwsCalcOutDto } from '../utils/csv.utils';
import { convertISOToLocalTimeZone } from '@app/utils/date.utils';
import { createDownloadAsPDFLink } from '@app/pdf/pdf-api.utils';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import { StiResponseDetailErrors, SubmissionGiCalcOutDto } from '@app/swagger-types';
import { ErrorsTable } from '../components/ErrorsTable';
import { AwsCalcOutDto } from '../types/calc.types';
import { FORMAT } from '@app/constants/formats';

interface Props {
  setIsEditing: (isEditing: boolean) => void;
  warnings?: StiResponseDetailErrors[];
  errors?: StiResponseDetailErrors[];
  result: AwsCalcOutDto;
  giData?: SubmissionGiCalcOutDto;
  modelVersion?: string;
  responseId?: number;
  isLoading: boolean;
  isGiDataLoading?: boolean;
  errorMsg?: string;
  createdDate?: string;
}

export const SubmissionAwsResult: FC<Props> = ({
  isGiDataLoading,
  modelVersion,
  setIsEditing,
  createdDate,
  responseId,
  isLoading,
  errorMsg,
  warnings,
  giData,
  errors,
  result,
}) => {
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const handleDownloadCsv = useCallback(() => {
    createCsvFileFromAwsCalcOutDto(result, giData?.data);
  }, [result, giData?.data]);

  const PDF_LINK = `${window.location.origin}${Routes.sti.awsPdf}?${queryString.stringify({
    responseId,
    giResponseId: giData?.responseId,
  })}`;

  const handleDownloadPdf = useCallback(() => {
    window.location.href = createDownloadAsPDFLink(PDF_LINK);
    setIsDownloadingPDF(true);
    setTimeout(() => setIsDownloadingPDF(false), 6000);
  }, [PDF_LINK]);

  const { sti, projectedLosses, expectedEmr, assessmentSummary, coEmr, stateOutputs, claimsSummary } = result;

  return (
    <div className="flex w-full flex-col gap-8">
      <Paper className="flex items-center gap-4 px-6 py-[0.4rem] shadow-card">
        <Typography className="text-xxxl">SafeTier Rating</Typography>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            sx={{ '.MuiCircularProgress-circle': { strokeLinecap: 'round' } }}
            variant="determinate"
            thickness={5}
            value={sti}
            size={120}
          />
          <Box
            justifyContent="center"
            position="absolute"
            alignItems="center"
            display="flex"
            bottom={0}
            right={0}
            left={0}
            top={0}
          >
            <div className="flex flex-col items-center justify-center">
              <Typography className="mt-3 text-5xl font-medium leading-10 text-secondary">{Math.round(sti)}</Typography>
              <Typography className="text-[8px] text-[#00000099]">STI</Typography>
            </div>
          </Box>
        </Box>
        <div className="ml-auto flex items-center justify-between gap-4">
          {createdDate && (
            <Typography className="text-m text-[#00000099]">
              {format(convertISOToLocalTimeZone(createdDate), FORMAT.FULL_DATE_TIME_WITH_AMPM)}
            </Typography>
          )}
          <Button
            className="flex h-full max-h-[40px] items-center gap-2 rounded-md border-[1.5px] border-secondary bg-transparent px-4 py-3 font-medium text-[#000000DE]"
            onClick={() => setIsEditing(true)}
            size="small"
          >
            <EditNoteOutlinedIcon className="text-[#0000008A]" />
            Edit Rating Inputs
          </Button>
        </div>
      </Paper>
      <Paper className="p-8 shadow-card">
        <h2 className="text-xxl text-[#000000DE]">Details</h2>
        <div className="w-full rounded bg-white px-8">
          <div className="mt-10" style={{ width: 300 }}>
            <AwsStiTable sti={sti} projectedLosses={projectedLosses} />
          </div>
          <RoleRequired oneOf={GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS}>
            {giData ? (
              <div className="mt-10 w-[450px]">
                <AwsAlternativeResultsTable data={giData.data} />
              </div>
            ) : isGiDataLoading ? (
              <Skeleton className="mt-10" variant="rounded" height={130} width={450} />
            ) : (
              <Alert severity="error" className="mt-10">
                Something went wrong with alternative results data
              </Alert>
            )}
          </RoleRequired>
          <div className="mt-10" style={{ width: 450 }}>
            <AwsEmrTable expectedEmr={expectedEmr} coEmr={coEmr} />
          </div>
          <div className="mt-10" style={{ width: 750 }}>
            <AwsClaimsSummaryTable claimsSummary={claimsSummary} />
          </div>
          <div className="mt-10">
            <AwsStatesTable states={stateOutputs} />
          </div>
          <div className="mt-10" style={{ width: 700 }}>
            <AwsAssessmentSummaryTable assessmentSummary={assessmentSummary} />
          </div>
          {errorMsg ? (
            <div className="mt-8 flex w-full justify-center">
              <Typography variant="body1" color="error" align="center">
                {errorMsg}
              </Typography>
            </div>
          ) : null}
          <div className="flex justify-center gap-2 pt-10">
            {IS_NOT_PROD_ENV ? (
              <a href={PDF_LINK} target="_blank" rel="noreferrer">
                <Button variant="outlined" className="w-60 rounded-sm border border-solid border-gray-700 py-3.5">
                  Preview PDF file
                </Button>
              </a>
            ) : null}
            <Button
              variant="outlined"
              className="w-60 rounded-sm border border-solid border-gray-700 py-3.5"
              onClick={handleDownloadPdf}
              loading={isDownloadingPDF}
            >
              Download PDF <PDFIconSvg className="ml-2" />
            </Button>
            <Button
              variant="outlined"
              onClick={handleDownloadCsv}
              className="w-60 rounded-sm border border-solid border-gray-700 py-3.5"
            >
              Download CSV <CSVIconSvg className="ml-2" />
            </Button>
          </div>
          {modelVersion ? (
            <div className="flex justify-center pt-6">
              <Typography className="text-xs">Model version: {modelVersion}</Typography>
            </div>
          ) : null}
        </div>
      </Paper>
      {warnings && !isLoading ? (
        <Paper className="flex flex-col p-8 shadow-card">
          <div className="mb-8 flex items-center gap-2">
            <WarningRoundedIcon className="text-yellow-dark" />
            <h2 className="text-xxl text-[#000000DE]">Warnings</h2>
          </div>
          <ErrorsTable
            messageColumnLabel="Warning Details"
            containerClassName="p-0"
            codeColumnLabel="Code"
            errors={warnings}
            open
          />
        </Paper>
      ) : null}
      {errors && !isLoading ? (
        <Paper className="flex flex-col p-8 shadow-card">
          <div className="mb-8 flex items-center gap-2">
            <WarningRoundedIcon className="text-red-500" />
            <h2 className="text-xxl text-[#000000DE]">Errors</h2>
          </div>
          <ErrorsTable containerClassName="p-0" open errors={errors} onClose={undefined} />
        </Paper>
      ) : null}
    </div>
  );
};
