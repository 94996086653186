import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import pluralize from 'pluralize';
import { FC } from 'react';

import { StiResponseDetailErrors } from '@app/swagger-types';
import { Tooltip } from '@app/components/tooltip/Tooltip';
import { Button } from '@app/components/buttons/button/Button';

interface Props {
  warnings: StiResponseDetailErrors[] | undefined;
  handleShow: () => void;
  isLoading: boolean;
  show: boolean;
}

export const WarningButton: FC<Props> = ({ warnings, isLoading, show, handleShow }) => {
  return warnings?.length && !show && !isLoading ? (
    <Tooltip title="Please click to see warning details" text="">
      <Button type="button" variant="text" onClick={handleShow} disabled={isLoading}>
        <div className="flex items-center gap-2">
          <WarningRoundedIcon className="text-yellow-dark" />
          <span>
            {warnings.length} {pluralize('Warning', warnings.length)}
          </span>
        </div>
      </Button>
    </Tooltip>
  ) : null;
};
