import React from 'react';
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom';

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps & { activeClassName: string }>(
  ({ activeClassName, ...props }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')}
      />
    );
  }
);
