export enum EAuthType {
  TOKEN = 'TOKEN',
  COOKIE = 'COOKIE',
}

export enum EVerificationType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum EAuthStatus {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_IN_WELCOME = 'SIGNED_IN_WELCOME',
  USER_BANNED = 'USER_BANNED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN = 'UNKNOWN',
}
