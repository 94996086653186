import React from 'react';

import { Typography } from '@mui/material';

import { EImpact } from '../../../swagger-types';
import { IncidentStatTag } from './IncidentStatTag';
import { clsxm } from '@app/styles/clsxm';
import { IncidentStatOutDto } from '../types/calc.types';

interface Props {
  incidentStats: IncidentStatOutDto[];
  pdf?: boolean;
  wrapperClassName?: string;
}
export const IncidentStatsBlock: React.FC<Props> = ({ incidentStats, pdf, wrapperClassName }) => {
  return (
    <div className={clsxm('grid gap-[18px]', wrapperClassName)}>
      {incidentStats
        .filter(({ impact }) => impact === EImpact.SAFE_TIER_SCORE)
        .map(({ impact, text }, index) => (
          <div key={index} className="grid grid-cols-[200px_1fr] gap-5">
            <IncidentStatTag impact={impact} />
            <div className="flex items-center">
              <Typography
                variant="body2"
                className={clsxm(pdf && 'text-xs', impact === EImpact.SAFE_TIER_SCORE && 'font-bold')}
              >
                {text}
              </Typography>
            </div>
          </div>
        ))}
    </div>
  );
};
