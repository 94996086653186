import { FC } from 'react';
import { IconButton, Skeleton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { FileDropZoneWrapper } from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import { SubmissionFileRow } from './SubmissionFileRow';
import { SubmissionFileCompactOutDto } from '@app/swagger-types';

interface Props {
  title: string;
  dropZoneText: string;
  noDataText: string;
  submissionId: string;
  onUploadFile: (file: File) => void;
  onNotAllowed: () => void;
  file?: SubmissionFileCompactOutDto;
  allowedTypes?: string[];
  isUploading?: boolean;
  isFetching?: boolean;
  disabled?: boolean;
}

export const SubmissionDocumentSection: FC<Props> = ({
  title,
  dropZoneText,
  noDataText,
  submissionId,
  onUploadFile,
  onNotAllowed,
  file,
  isUploading,
  isFetching,
  allowedTypes,
  disabled,
}) => {
  return (
    <div className="border-b border-b-black/10 pb-2">
      {isFetching ? (
        <div>
          <Skeleton height={180} variant="rectangular" className="rounded" />
        </div>
      ) : (
        <div className="grid min-h-[180px] grid-cols-3 gap-2.5">
          <FileDropZoneWrapper
            onChange={onUploadFile}
            accept="application/pdf"
            className="col-span-1"
            loading={isUploading}
            text={dropZoneText}
            allowedTypes={allowedTypes}
            onNotAllowed={onNotAllowed}
          />
          <div className="col-span-2 flex flex-col justify-center gap-4 p-4">
            <Typography className="text-xl font-bold">{title}</Typography>
            {file ? (
              <SubmissionFileRow file={file} submissionId={submissionId} disabled={disabled} />
            ) : (
              <div className="flex items-center justify-between gap-2">
                <Typography className="text-s text-black/40">{noDataText}</Typography>
                <IconButton size="large" disabled>
                  <InfoOutlinedIcon />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
