import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { VersionContextProvider } from '@app/domain/sti/contexts/VersionContext';
import { CalculatorFormsProvider } from '@app/domain/sti/contexts/CalculatorFormsContext';

export const StiModule: FC = () => {
  return (
    <VersionContextProvider>
      <CalculatorFormsProvider>
        <Outlet />
      </CalculatorFormsProvider>
    </VersionContextProvider>
  );
};
