import { Dispatch, SetStateAction, useMemo } from 'react';
import { useInterval, useLocalStorage, useMount } from 'react-use';

const ONE_HOUR_MILLISECONDS = 3.6e6;
// Current date + 6 hours
const DEFAULT_EXPIRY_DATE = new Date().getTime() + ONE_HOUR_MILLISECONDS * 6;

export const useLocalStorageWithExpiry = <T>(
  key: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const defaultValue = useMemo(() => ({ value: initialValue, expiry: DEFAULT_EXPIRY_DATE }), [initialValue]);

  const [valueWithExpiry = defaultValue, setValueWithExpiry, remove] = useLocalStorage<{
    value: T;
    expiry: number;
  }>(key, defaultValue);

  useMount(() => {
    if (valueWithExpiry.expiry < new Date().getTime()) {
      setValueWithExpiry(defaultValue);
    }
  });

  useInterval(() => {
    if (valueWithExpiry.expiry < new Date().getTime()) {
      setValueWithExpiry(defaultValue);
    }
  }, ONE_HOUR_MILLISECONDS);

  return useMemo(
    () => [
      valueWithExpiry.value,
      (action: SetStateAction<T>) => {
        if (action === undefined) {
          setValueWithExpiry(undefined);
        } else if (typeof action === 'function') {
          const typedAction = action as (prevState: T) => T;
          const newValue = typedAction(valueWithExpiry.value);
          setValueWithExpiry(newValue ? { value: newValue, expiry: DEFAULT_EXPIRY_DATE } : undefined);
        } else {
          setValueWithExpiry({ value: action, expiry: DEFAULT_EXPIRY_DATE });
        }
      },
      remove,
    ],
    [valueWithExpiry, setValueWithExpiry, remove]
  );
};
