import { Modal, Typography } from '@mui/material';
import React from 'react';

import { Button } from '@app/components/buttons/button/Button';
import { clsxm } from '@app/styles/clsxm';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ConfirmDialog.module.scss';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';

export interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  headerTitle?: string;
  title?: string;
  text?: string;
  confirmText?: string;
  confirmIcon?: React.ReactNode;
  cancelText?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  headerTitle,
  title = 'Are you sure?',
  text,
  confirmText = 'Confirm',
  confirmIcon,
  cancelText = 'Cancel',
  open,
  onClose,
}) => {
  return (
    <>
      <Modal open={open} onClose={onClose} onClick={(e) => e.stopPropagation()}>
        <div className={clsxm(styles.Root, 'rounded-lg bg-white p-7')}>
          <div className="flex justify-between pb-5">
            <Typography className="text-xs">{headerTitle}</Typography>
            <IconButton onClick={onClose} className="absolute right-4 top-4">
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.Content}>
            <Typography className="mb-3 text-center text-xxl font-semibold">{title}</Typography>
            <div className={styles.InfoSection}>
              <Typography className="text-center text-m text-gray-500">{text}</Typography>
            </div>
            <div className="mt-10 flex w-full justify-evenly gap-4">
              <Button onClick={onCancel || onClose} variant="outlined" color="primary" fullWidth>
                {cancelText}
              </Button>
              <Button onClick={onConfirm} variant="contained" color="primary" startIcon={confirmIcon} fullWidth>
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
