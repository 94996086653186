import { FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

import { useMousePosition } from '@app/hooks/useMousePosition.hook';
import { DataExplorationPlotOutDto } from '@app/swagger-types';

interface Props {
  data: DataExplorationPlotOutDto[];
}

export const DataExplorationPieChart: FC<Props> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const [tooltipContent, setTooltipContent] = useState<string | null>(null);

  const { x, y } = useMousePosition();

  useEffect(() => {
    const parsedData = data.map((d) => {
      const isAnyString =
        typeof d.dataValue === 'string' ||
        typeof d.dataName === 'string' ||
        typeof d.keyValue === 'string' ||
        typeof d.keyName === 'string';

      return {
        keyValue: d.keyValue ?? '',
        dataValue: isAnyString ? 1 : parseFloat(d.dataValue ?? '0'),
      };
    });

    const svgElement = d3.select(svgRef.current);
    svgElement.selectAll('*').remove();

    const containerWidth = svgElement.node()?.parentElement?.getBoundingClientRect().width || 800;
    const width = containerWidth;
    const height = 600;
    const radius = Math.min(width, height) / 2;

    const midAngle = (d: d3.PieArcDatum<{ keyValue: string; dataValue: number }>) => {
      return d.startAngle + (d.endAngle - d.startAngle) / 2;
    };

    const svg = svgElement
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMinYMid meet')
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const pie = d3
      .pie<{ keyValue: string; dataValue: number }>()
      .value((d) => d.dataValue)
      .sort(null);

    const arc = d3
      .arc<d3.PieArcDatum<{ keyValue: string; dataValue: number }>>()
      .innerRadius(0)
      .outerRadius(radius * 0.8);
    const outerArc = d3
      .arc<d3.PieArcDatum<{ keyValue: string; dataValue: number }>>()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    const color = d3
      .scaleOrdinal<string>()
      .domain(parsedData.map((d) => d.keyValue))
      .range(d3.schemeTableau10);

    svg
      .append('g')
      .selectAll('path')
      .data(pie(parsedData))
      .enter()
      .append('path')
      .attr('d', arc as any)
      .attr('fill', (d) => color(d.data.keyValue) ?? '#ccc')
      .on('mouseover', (event, d) => setTooltipContent(`${d.data.keyValue}: ${d.data.dataValue}`))
      .on('mouseout', () => setTooltipContent(null));

    const retainedPercentage = 0.2; // Keep 20% of the labels

    const retainedIndices = new Set(
      Array.from({ length: Math.ceil(parsedData.length * retainedPercentage) }, (_, i) =>
        Math.floor((i * parsedData.length) / (parsedData.length * retainedPercentage))
      )
    );

    // Add text labels (keeping 20% of the labels)
    svg
      .append('g')
      .attr('class', 'labels')
      .selectAll('text')
      .data(pie(parsedData).filter((_, i) => retainedIndices.has(i)))
      .enter()
      .append('text')
      .attr('dy', '.35em')
      .attr('font-size', '10px')
      .text((d) => d.data.keyValue)
      .attr('transform', (d) => {
        const pos = outerArc.centroid(d);

        pos[0] = radius * (midAngle(d) < Math.PI ? 1 : -1); // Adjust x position for side alignment

        return `translate(${pos})`;
      })
      .attr('text-anchor', (d) => (midAngle(d) < Math.PI ? 'start' : 'end'));

    // Draw leader lines
    svg
      .append('g')
      .attr('class', 'lines')
      .selectAll('polyline')
      .data(pie(parsedData).filter((_, i) => retainedIndices.has(i)))
      .enter()
      .append('polyline')
      .attr('points', (d) => {
        const pos = outerArc.centroid(d);

        pos[0] = radius * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
        return [arc.centroid(d), outerArc.centroid(d), pos].map((point) => point.join(',')).join(' ');
      })
      .style('fill', 'none')
      .style('stroke', 'black')
      .style('stroke-width', '1px');
  }, [data]);

  return (
    <>
      <svg ref={svgRef} style={{ width: '100%', height: 'auto' }} />
      {tooltipContent && (
        <div
          className="pointer-events-none fixed z-50 rounded bg-secondary px-1 py-2 text-white"
          style={{ top: y + 10, left: x + 10 }}
        >
          {tooltipContent}
        </div>
      )}
    </>
  );
};
