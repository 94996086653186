import { ClaimFilter, ClaimOutDto, EFieldType } from '@app/swagger-types';
import { ArrayOfStringKeys, NumberKeys, StringKeys } from '@app/utils/type.utils';

export enum EClaimFilterType {
  STRING_ARR = 'STRING_ARR',
  NUMBER = 'NUMBER',
  DECIMAL_NUMBER = 'DECIMAL_NUMBER',
  COIN = 'COIN',
  DATE = 'DATE',
}

export type ClaimFilterConfig =
  | {
      type: EClaimFilterType.COIN;
      fromField: KeysWithFromAndOptionalNumber<ClaimFilter>;
      toField: KeysWithToAndOptionalNumber<Required<ClaimFilter>>;
      outDtoField: KeysWithCoin<ClaimOutDto>;
      label: string;
    }
  | {
      type: EClaimFilterType.DATE;
      fromField: KeysWithFromAndOptionalString<ClaimFilter> | 'firstDayOfDisabilityFrom';
      toField: KeysWithToAndOptionalString<ClaimFilter> | 'firstDayOfDisabilityTo';
      outDtoField:
        | KeysWithDate<ClaimOutDto>
        | 'firstDayOfDisability'
        | Array<KeysWithDate<ClaimOutDto> | 'policyPeriod'>;
      label: string;
    }
  | {
      type: EClaimFilterType.STRING_ARR;
      field: ArrayOfStringKeys<ClaimFilter>;
      fieldType: EFieldType;
      outDtoField: StringKeys<ClaimOutDto> | ArrayOfStringKeys<ClaimFilter>;
      // to override default
      label?: string;
      placeholder?: string;
    }
  | {
      type: EClaimFilterType.NUMBER | EClaimFilterType.DECIMAL_NUMBER;
      fromField: KeysWithFromAndOptionalNumber<ClaimFilter>;
      toField: KeysWithToAndOptionalNumber<Required<ClaimFilter>>;
      outDtoField: NumberKeys<ClaimOutDto>;
      label: string;
    };

type KeysWithFromAndOptionalNumber<T> = {
  [K in keyof T]-?: K extends `${string}From${string}` ? (T[K] extends number | undefined ? K : never) : never;
}[keyof T];

type KeysWithToAndOptionalNumber<T> = {
  [K in keyof T]-?: K extends `${string}To${string}` ? (T[K] extends number | undefined ? K : never) : never;
}[keyof T];

type KeysWithFromAndOptionalString<T> = {
  [K in keyof T]-?: K extends `${string}From${string}` ? (T[K] extends string | undefined ? K : never) : never;
}[keyof T];

type KeysWithToAndOptionalString<T> = {
  [K in keyof T]-?: K extends `${string}To${string}` ? (T[K] extends string | undefined ? K : never) : never;
}[keyof T];

type KeysWithCoin<T> = {
  [K in keyof T]: K extends `${string}Coin${string}` ? K : never;
}[keyof T];

type KeysWithDate<T> = {
  [K in keyof T]: K extends `${string}Date${string}` | `date${string}` ? K : never;
}[keyof T];
