import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FC } from 'react';

export const ErrorPage: FC = () => {
  return (
    <div className="grid h-screen place-items-center px-8 text-center text-secondary">
      <div>
        <ErrorOutlineIcon className="mx-auto size-20" />
        <h1 className="text-3xl text-blue-gray-800 mt-10 leading-snug md:text-4xl">
          Error <br /> It looks like something went wrong.
        </h1>
        <p className="text-lg mx-auto mb-14 mt-8 font-normal text-gray-500 md:max-w-sm">
          Don&apos;t worry, our team is already on it. Please try refreshing the page or come back later.
        </p>
        <a href={window.location.href}>
          <button className="w-full rounded bg-secondary px-4 py-2 text-white hover:brightness-125 md:w-32">
            Refresh
          </button>
        </a>
      </div>
    </div>
  );
};
