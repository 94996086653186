import { useMemo } from 'react';

import { EAuthStatus } from '@app/auth/api/auth.type';

import { useStore } from '@app/store/useStore.hook';
import { ERole } from '@app/auth-types';

export type RoleRequiredProps = {
  oneOf?: ERole[];
  any?: boolean;
  exactRole?: ERole;
  exactAuthStatus?: EAuthStatus;
};

export const useRoleRequired = ({ oneOf, any, exactRole, exactAuthStatus }: RoleRequiredProps) => {
  const { currentUser, authStatus } = useStore(({ currentUser, authStatus }) => ({
    currentUser,
    authStatus,
  }));

  const isAllowed = useMemo(() => {
    if (any) {
      return true;
    }

    // e.g exactRole='ROLE_ADMIN', exactAuthStatus='USER_BANNED'
    if ((exactRole || oneOf) && exactAuthStatus && currentUser) {
      if (
        exactAuthStatus === authStatus &&
        (oneOf?.some((role) => currentUser.groups.includes(role)) ||
          (exactRole && currentUser.groups.includes(exactRole)))
      ) {
        return true;
      }
    }

    // e.g exactAuthStatus='UNAUTHORIZED'
    if (exactAuthStatus) {
      return exactAuthStatus === authStatus;
    }

    if (currentUser) {
      // e.g exactRole='ROLE_SUPER_ADMIN'
      if (exactRole) {
        return currentUser.groups.includes(exactRole);
      }

      // e.g oneOf=['ROLE_SUPER_ADMIN']
      if (oneOf) {
        return oneOf.some((role) => currentUser.groups.includes(role));
      }
    }

    return false;
  }, [any, authStatus, currentUser, exactAuthStatus, exactRole, oneOf]);

  return isAllowed;
};
