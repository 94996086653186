import { PDF_DOM_READY_CLASS_NAME } from '@app/constants/pdf.const';
import { typedEnv } from '@app/environment/typed-env';

export const createDownloadAsPDFLink = (link: string, fileName?: string): string => {
  const url = new URL(`${typedEnv.REACT_APP_API_BASE_URL}/private/load-as-pdf`);

  url.searchParams.set('url', link);
  url.searchParams.set('waitForSelector', `.${PDF_DOM_READY_CLASS_NAME}`);
  url.searchParams.set('pdfFormat', 'a4');

  if (fileName) {
    url.searchParams.set('fileName', fileName);
  }

  return url.toString();
};
