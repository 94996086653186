import { ReactDVFC, useDialog } from '@app/services/dialog-manager';

import { Dialog } from '@app/hoc';
import { Button } from '@app/components/buttons/button/Button';
import { Typography } from '@mui/material';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useReuploadSubmissionFilesMutation } from '../api/submission.api.hooks';

interface Props {
  fileId: number;
  submissionId: string;
  formData: FormData;
}

export const ConfirmUploadSubmissionFileDialog: ReactDVFC<Props> = ({
  isOpen,
  onClose,
  fileId,
  submissionId,
  formData,
}) => {
  const { mutate: reupload, isLoading } = useReuploadSubmissionFilesMutation({
    onSuccess: () => {
      onClose();
    },
  });

  const handleSubmit = useHandler(() => {
    reupload({ fileId, submissionId, formData });
  });

  return (
    <Dialog open={isOpen} onClose={onClose} hideHeader paperClassName="w-[450px]">
      <div className="flex flex-col gap-10 p-10">
        <Typography className="font-semibold">Replace File</Typography>
        <div className="flex flex-col gap-1 text-center text-gray-600">
          <Typography className="text-s">You can upload only one file.</Typography>
          <Typography className="text-s">Upload of this file will replace current file.</Typography>
          <Typography className="text-s">Are you sure?</Typography>
        </div>
        <div className="flex justify-between">
          <Button variant="outlined" color="primary" className="w-44" onClick={onClose} disabled={isLoading}>
            Close
          </Button>
          <Button color="primary" onClick={handleSubmit} className="w-44" loading={isLoading}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export const useConfirmUploadSubmissionFileDialog = () => {
  return useDialog(ConfirmUploadSubmissionFileDialog);
};
