import { Outlet } from 'react-router-dom';

import { GROUPS_WITH_ACCESS_TO_DATA_EXPLORATION } from '@app/domain/data-exploration/const/data-exploration.const';
import { HAS_ACCESS_TO_DATA_EXPLORATION } from '@app/environment/typed-env';
import { DataExplorationPage } from './DataExplorationPage';
import { inferIdentity } from '@app/utils/type.utils';
import { MyRouteObject } from '@app/models/router';
import { Routes } from '@app/constants/routes';
import { TypedNavigate } from '@app/router';

export const DataExplorationRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.dataExploration,
  element: <Outlet />,
  disabled: !HAS_ACCESS_TO_DATA_EXPLORATION,
  role: GROUPS_WITH_ACCESS_TO_DATA_EXPLORATION,
  children: [
    { index: true, element: <DataExplorationPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.dataExploration} /> },
  ],
});
