import { PropsWithChildren, FC } from 'react';

interface Props {
  title: string;
}

export const DevKitSection: FC<PropsWithChildren<Props>> = ({ title, children }) => {
  return (
    <div className="my-4 rounded p-4 shadow">
      <h3 className="mb-2 text-center font-bold">{title}</h3>
      {children}
    </div>
  );
};
