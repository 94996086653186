import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { CheckParamReq, ExtractParams } from '../types';

interface TypedOptions<TPath extends string> {
  to: TPath;
  params: ExtractParams<TPath, string>;
  preserveQueryParams?: boolean;
  queryParams?: string | Record<string, string>;
  replace?: boolean;
  state?: unknown;
}

export interface TypedNavigateFunction {
  <TPath extends string>(options: CheckParamReq<TypedOptions<TPath>>): void;
}

export const useTypedNavigate = (): TypedNavigateFunction => {
  const navigate = useNavigate();
  const { search } = useLocation();
  return (options) => {
    return navigate(
      {
        pathname: generatePath(options.to, options.params),
        search: options.preserveQueryParams
          ? search
          : typeof options.queryParams === 'string'
          ? options.queryParams
          : `?${new URLSearchParams(options.queryParams).toString()}`,
      },
      { replace: options.replace, state: options.state }
    );
  };
};
