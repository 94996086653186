import { ClaimOutDto } from '@app/swagger-types';

export const createMockClaimOutDto = (override?: Partial<ClaimOutDto>): ClaimOutDto => ({
  id: 19,
  claimNumber: '23J50K564526',
  claimant: 'Farmer, Ryleigh',
  policyNumber: 'INS-WCP0001018',
  location: 'STRIPLINGS GENERAL STORE, INC.',
  locationState: 'GA',
  accidentState: 'GA',
  stateOfJurisdiction: 'GA',
  claimCreatedDate: '2023-02-06T13:46:54Z',
  claimStatus: 'Closed',
  dateClosed: '2023-08-24T10:37:12Z',
  totalDaysLost: 0,
  lostTimeIndicator: 'N',
  medicalPaidCoin: 260580,
  indemnityTotalPaidCoin: 0,
  indemnityTotalIncurredCoin: 0,
  expensePaidCoin: 8582,
  expenseTotalIncurredCoin: 8582,
  totalPaidCoin: 269162,
  totalIncurredCoin: 269162,
  claimType: 'Med',
  causeCode: 'LIFTING',
  causeCodeGroup: 'STRAIN OR INJURY BY',
  lossType: 'STRAIN OR TEAR',
  lossTypeGroup: 'SPECIFIC INJURY',
  lossPaidCoin: 260580,
  lossReservesCoin: 0,
  dateOfBirth: '2006-09-30T00:00:00Z',
  sex: 'Female',
  agency: '(No Agency)',
  policyPeriod: '01/01/2023 - 12/31/2023',
  locationNumber: 'J500017',
  locationAddressLine1: '2289 S HWY 300',
  locationCity: 'CORDELE',
  locationCounty: 'CRISP',
  locationZip: 31015,
  location2: 'STRIPLINGS GENERAL STORE, INC.',
  location2Number: 2,
  location2AddressLine1: '2470 HIGHWAY 280 W',
  location2City: 'CORDELE',
  location2State: 'GA',
  location2Zip: 31015,
  dateOfHire: '2022-05-25T00:00:00Z',
  claimSubmittedLag: 1,
  dateOfLoss: '2023-02-05T00:00:00Z',
  claimSubmittedName: 'Condunent',
  ncciInjuryType: '6',
  jobClass: 'STORE:MEAT/FISH/POULTRY RETAIL',
  jobCode: 8031,
  industryCode: 445210,
  accidentDescription: "EE's right knee popped",
  bodyPartGroup: 'LOWER EXTREMITIES',
  medicalReservesCoin: 0,
  indemnityTotalReservesCoin: 0,
  expenseReservesCoin: 0,
  outstandingReservesCoin: 0,
  medicalTotalIncurredCoin: 260580,
  bodyPartDetail: 'KNEE(S)',
  litigation: 'No',
  subrogationStatus: '',
  highCostPrediction: 'Low',
  obesityFlag: 'No',
  diabetesFlag: 'No',
  adjuster: 'Scoggins, Chastity',
  adjusterEmail: 'cscoggins@ccmsi.com',
  adjusterPhone: '615-340-6715',
  returnedToWork: 'Y',
  riskLoweringFactor1: 'Claimant Weekly Wage',
  riskLoweringFactor2: 'County',
  riskLoweringFactor4: 'Claimant Age',
  riskLoweringFactor5: 'Text',
  riskRaisingFactor1: 'Employment Job Code',
  riskRaisingFactor2: 'Injury Cause Code',
  riskRaisingFactor3: 'NCCI Class Code',
  totalIncurredPredictionCoin: 269994,
  netIncurredCoin: 269162,
  outstandingReservesLastClosureCoin: 11338,
  suitFiled: 'N',
  dayOfWeek: 'Sun',
  copdFlag: 'No',
  coronaryArteryDiseaseFlag: 'No',
  osteoarthritisFlag: 'No',
  otherCardiacDisordersFlag: 'No',
  carrier: 'SiriusPoint Ltd.',
  substanceDependenceFlag: 'No',
  claimantPhone: '(229) 401-8721',
  claimantWorkPhone: '(999) 999-9999',
  claimantState: 'GA',
  claimantCity: 'Rochelle',
  mobilePhone: '(229) 401-8721',
  handlingOffice: 'NASHVILLE',
  claimDenied: 'No',
  socialSecurityNumber: '***-**-7560',
  jobTitleCarrier: 'Cashier',
  supervisor: 'Roberts, Ashley',
  employmentType: 'Part Time',
  claimantAttorneyRepresented: 'No',
  groupCode1: '',
  claimSubmittedUserId: 'SPATTISON',
  claimantDied: 'N',
  initialTreatment: 'Emergency Room',
  physicianHospitalName: 'Crisp Regional Hospital',
  totalReimbursementCoin: 0,
  ttdRate: 0,
  weeklyWageCoin: 0,
  ppdRate: 0,
  electroDiagnosticPrediction: 0.0203,
  imagingPrediction: 0.0977,
  opioidPrediction: 0.0223,
  painManagementPrediction: 0.0513,
  physTherapyChiroPrediction: 0.0576,
  surgeryPrediction: 0.031,
  claimantZip: 31079,
  sirDeductibleCoin: 2500,
  settlementAmountCoin: 0,
  subrogationEstimatedAmountCoin: 0,
  claimantAge: 16,
  predictionDate: '2023-08-24T00:00:00Z',
  policyPeriodEndDate: '2023-12-31T23:59:00Z',
  policyPeriodStartDate: '2023-01-01T00:00:00Z',
  claimantReportDate: '2023-02-05T00:00:00Z',
  claimSubmittedDate: '2023-02-06T14:33:47Z',
  dateLastWorked: '2023-02-05T00:00:00Z',
  returnToWorkDate: '2023-02-06T00:00:00Z',
  lastPaymentDate: '2023-09-26T12:40:08Z',
  ...override,
});
