import { useEffect, useState } from 'react';

import { memoizedImageBackgroundDownloader } from '@app/utils/image.utils';

export const useBackgroundImageDownload = (imageId?: string | null) => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!imageId) {
      return setImageUrl('');
    }

    let isMount = true;

    setLoading(true);
    setError(false);

    memoizedImageBackgroundDownloader(imageId)
      .then((res) => {
        isMount && setImageUrl(res);
      })
      .catch(() => {
        isMount && setError(true);
      })
      .finally(() => {
        isMount && setLoading(false);
      });

    return () => {
      isMount = false;
    };
  }, [imageId]);

  return [imageUrl, loading, error] as const;
};
