import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { z } from 'zod';
import { showErrorToast } from '@app/utils/toast.utils';

export const validateAndAssert = async <S extends z.Schema<any>>(
  res: Promise<AxiosResponse<unknown>>,
  schema: S
): Promise<z.infer<S>> => {
  const { data } = await res;

  try {
    return schema.parse(data);
  } catch (e) {
    console.log(data);
    console.log(
      `%cDTO ERROR: ${get(e, 'errors')}`,
      'display: inline-block ; border: 3px solid red ; border-radius: 7px ; font-size: 20px ; ' +
        'padding: 10px ; margin: 20px ;'
    );
    showErrorToast('Server DTO Validation Error (check console for details)');
    throw e;
  }
};

export const validate = async <S extends z.Schema<any>, R>(res: Promise<AxiosResponse<R>>, schema: S): Promise<R> => {
  const { data } = await res;

  try {
    schema.parse(data);
    return data;
  } catch (e) {
    console.log(
      `%cDTO ERROR: ${get(e, 'errors')}`,
      'display: inline-block ; border: 3px solid red ; border-radius: 7px ; font-size: 20px ; ' +
        'padding: 10px ; margin: 20px ;'
    );
    showErrorToast('Server DTO Validation Error (check console for details)');
    throw e;
  }
};
