import { FC, memo } from 'react';
import { clsxm } from '@app/styles/clsxm';

import './dots-loader.scss';

interface Props {
  className?: string;
}

export const DotsLoader: FC<Props> = memo(({ className }) => {
  return (
    <div className={clsxm('spinner-box', className)}>
      <div className="pulse-container">
        <div className="pulse-bubble pulse-bubble-1"></div>
        <div className="pulse-bubble pulse-bubble-2"></div>
        <div className="pulse-bubble pulse-bubble-3"></div>
      </div>
    </div>
  );
});
