import { toast } from 'react-toastify';
import { ToastContent, ToastOptions } from 'react-toastify/dist/types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const successToastStyle = {
  background: '#ECFDF5',
  color: '#1F2937',
};
const errorToastStyle = {
  background: '#fee2e2',
  color: '#1F2937',
};

export const showSuccessToast = (content: ToastContent, options?: ToastOptions) => {
  toast.success(content, {
    style: successToastStyle,
    // the icon should be wrapped to prevent compatibility issues with react-toastify - https://github.com/fkhadra/react-toastify/issues/1151#issuecomment-2411456619
    icon: () => (
      <>
        <CheckIcon className="text-green-400" />
      </>
    ),
    closeOnClick: true,
    ...options,
  });
};

export const showErrorToast = (content: ToastContent, options?: ToastOptions) => {
  toast.error(content, {
    style: errorToastStyle,
    // the icon should be wrapped to prevent compatibility issues with react-toastify - https://github.com/fkhadra/react-toastify/issues/1151#issuecomment-2411456619
    icon: () => (
      <>
        <CloseIcon className="text-red-400" />
      </>
    ),
    closeOnClick: true,
    ...options,
  });
};
