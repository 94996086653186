import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { FC } from 'react';

import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionCalculateSwitcher } from './SubmissionCalculateSwitcher';
import { Button } from '@app/components/buttons/button/Button';

interface Props {
  isLoading: boolean;
}

export const SubmissionCalculatorHeading: FC<Props> = ({ isLoading }) => {
  const { isCalcEditing, setCalcEditing } = useSubmissionCalculatorFormsContext();
  const { reset } = useFormContext();

  const onCancelEdits = () => {
    setCalcEditing(false);
    reset();
  };

  return (
    <div className="flex items-center gap-16">
      <Typography className="py-4 text-xxxl">SafeTier Rating</Typography>
      <SubmissionCalculateSwitcher />
      {isCalcEditing && !isLoading && (
        <Button
          className="ml-auto size-full max-h-10 max-w-[212px] rounded-md border-error text-error shadow-md"
          startIcon={<CloseIcon color="error" />}
          onClick={onCancelEdits}
          variant="outlined"
          size="small"
        >
          Cancel Edits
        </Button>
      )}
    </div>
  );
};
