import { AppLogo } from '@app/assets/app-logo/AppLogo';
import { UIStyledSection } from '../components/UIStyledSection';

export const UISectionBranding: React.FC = () => {
  return (
    <UIStyledSection title="Branding">
      <AppLogo noText />
      <AppLogo />
      <AppLogo dark />
    </UIStyledSection>
  );
};
