import { manager } from './dialog-manager';
import { useMemo } from 'react';
import { ReactDVFC } from './types';

export function useDialog<T>(component: ReactDVFC<T>) {
  const dialog = useMemo(() => {
    return manager.createDialog(component);
  }, [component]);
  return dialog;
}
