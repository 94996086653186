import { Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';

import { DataExplorationPromptOutDto, DataExplorationTypeOutDto } from '@app/swagger-types';
import { DEFAULT_DATA_EXPLORATION_PROMPTS_PARAMS } from './DataExplorationSearch';
import { useGetDataExplorationPrompts } from '../api/data-exploration.api.hooks';
import { DEFAULT_DATA_EXPLORATION_TYPE } from '../const/data-exploration.const';

interface FilteredDataExplorationPromptOutDto extends DataExplorationPromptOutDto {
  prompt: string;
}

interface Props {
  question: string;
  setQuestion: (newQuestion: string) => void;
  dataExplorationType: DataExplorationTypeOutDto;
  onRequestDataExploration: (requestQuestion: string) => Promise<void>;
}

export const DataExplorationAutocomplete: FC<Props> = ({
  question,
  setQuestion,
  dataExplorationType,
  onRequestDataExploration,
}) => {
  const [open, setOpen] = useState(false);

  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const { data: promptsData, isLoading: isPromptsLoading } = useGetDataExplorationPrompts(
    DEFAULT_DATA_EXPLORATION_PROMPTS_PARAMS
  );

  const prompts =
    (promptsData?.total ?? 0) > 0 && promptsData?.result && !isPromptsLoading
      ? [
          { prompt: 'Describe the fields in this data set.', createdDate: new Date().toISOString() },
          ...promptsData?.result,
        ]
      : [{ prompt: 'Describe the fields in this data set.', createdDate: new Date().toISOString() }];

  const filteredSuggestions = Array.from(
    new Set(prompts.filter((item) => item.prompt?.trim()).map((item) => item.prompt))
  )
    .filter((prompt) => prompt?.toLowerCase().includes(question.toLowerCase()))
    .map((uniquePrompt) =>
      prompts.find(({ prompt }) => prompt?.toLowerCase() === uniquePrompt?.toLowerCase())
    ) as FilteredDataExplorationPromptOutDto[];

  return (
    <Autocomplete
      onInputChange={(_, newInputValue) => setQuestion(newInputValue)}
      options={filteredSuggestions.map((item) => item.prompt)}
      onChange={(_, newValue) => setQuestion(newValue ?? '')}
      className="size-full"
      inputValue={question}
      onClose={closePopper}
      onOpen={openPopper}
      disableClearable
      value={question}
      open={open}
      freeSolo
      onKeyDown={(e) => {
        if (e.key === 'Enter' && question.trim()) {
          onRequestDataExploration(question.trim());
          closePopper();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiInput-underline': {
              height: '100%',
            },
            '& .MuiInput-underline:before': {
              border: 'none',
            },
            '& .MuiInput-underline:hover:before': {
              border: 'none !important',
            },
            '& .MuiInput-underline:after': {
              border: 'none',
            },
          }}
          variant="standard"
          className="size-full h-full bg-transparent px-2 text-xs"
          placeholder={
            dataExplorationType.type === DEFAULT_DATA_EXPLORATION_TYPE.type ? 'Question' : dataExplorationType.example
          }
        />
      )}
    />
  );
};
