import { useRef, useState, useEffect, FC } from 'react';

import { Tooltip } from '@app/components/tooltip/Tooltip';
import { clsxm } from '@app/styles/clsxm';

interface Props {
  text: string;
  className?: string;
}

export const TruncatedText: FC<Props> = ({ text, className }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;

    const checkTruncation = () => {
      if (textElement) {
        setIsTruncated(textElement.scrollWidth > textElement.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      checkTruncation();
    });

    if (textElement) {
      resizeObserver.observe(textElement);
    }

    return () => {
      if (textElement) {
        resizeObserver.unobserve(textElement);
      }
    };
  }, [text]);

  return (
    <Tooltip title={isTruncated ? text : ''}>
      <div ref={textRef} className={clsxm('truncate', className)}>
        {text}
      </div>
    </Tooltip>
  );
};
