import { z } from 'zod';
import { EAuthMethod } from '@app/auth/constant';

const EnvOverrideSchema = z.object({
  REACT_APP_AUTH_METHOD: z.nativeEnum(EAuthMethod).optional(),
});
type EnvOverrideSchema = z.infer<typeof EnvOverrideSchema>;

const ENV_OVERRIDE_KEY = 'env_override';

// Purpose of this class: to partially override environment variables,
// currently only for the auth method for development purposes.
class EnvOverride {
  state: EnvOverrideSchema = {};

  constructor() {
    const maybeOverride = this.load();
    if (!maybeOverride) {
      return;
    }
    try {
      const parsedOverride = JSON.parse(maybeOverride);
      this.state = EnvOverrideSchema.parse(parsedOverride);
    } catch {
      this.cleanup();
    }
  }

  load() {
    return window.localStorage.getItem(ENV_OVERRIDE_KEY);
  }

  persist(newState: EnvOverrideSchema) {
    window.localStorage.setItem(ENV_OVERRIDE_KEY, JSON.stringify(newState));
  }

  cleanup() {
    window.localStorage.removeItem(ENV_OVERRIDE_KEY);
  }
}

export const envOverride = new EnvOverride();
