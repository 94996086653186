import { Card, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { useGetAllSubmissionRiskFactorsQuery, useGetRiskFactorsBySubmissionIdQuery } from '../api/submission.api.hooks';
import { SubmissionRiskFactorField } from '../components/SubmissionRiskFactorField';
import { SubmissionRiskFactorsTotalAdjustmentBlock } from '../components/SubmissionRiskFactorsTotalAdjustmentBlock';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';

interface Props {
  submissionId: string;
}

export const SubmissionRiskFactorsTab: FC<Props> = ({ submissionId }) => {
  const { data: allData, isFetching: isAllDataFetching } = useGetAllSubmissionRiskFactorsQuery({
    keepPreviousData: true,
  });

  const { data, isFetching } = useGetRiskFactorsBySubmissionIdQuery(submissionId, { keepPreviousData: true });

  const { fullCalcResult } = useSubmissionCalculatorFormsContext();

  return (
    <Card className="flex h-[calc(100vh-270px)] w-full flex-col gap-1 overflow-hidden rounded-lg bg-white px-12 py-6">
      <div className="grid grid-cols-[1fr,320px] gap-16">
        <div className="flex items-center gap-5 border-b py-5">
          <Typography className="text-xxxl text-black/90">Risk Factors</Typography>
        </div>
        <div>
          <SubmissionRiskFactorsTotalAdjustmentBlock submissionId={submissionId} />
        </div>
      </div>
      <div className="grid h-full grid-cols-1 gap-16 overflow-auto  px-3 lg:grid-cols-[1fr,320px] lg:px-0">
        {(isAllDataFetching && !allData) || (isFetching && !data) ? (
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-4 border-b py-10">
              <Skeleton variant="rounded" height={80} />
              <div className="flex flex-col gap-4">
                <Skeleton variant="rounded" height={56} />
                <Skeleton variant="rounded" height={104} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 py-10">
              <Skeleton variant="rounded" height={80} />
              <div className="flex flex-col gap-4">
                <Skeleton variant="rounded" height={56} />
                <Skeleton variant="rounded" height={104} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-5">
            {allData?.length ? (
              <>
                {allData.map((riskFactor) => (
                  <SubmissionRiskFactorField
                    key={riskFactor.id}
                    submissionId={submissionId}
                    riskFactor={riskFactor}
                    disabled={isAllDataFetching || isFetching}
                    hasFullCalcData={Boolean(fullCalcResult)}
                  />
                ))}
              </>
            ) : (
              <div className="flex h-full items-center justify-center">
                <Typography className="text-m italic">No available risk factors</Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
