import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Popover, MenuItem, Button, Box } from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import React, { FC, useState } from 'react';

import { EChartType } from './DataExplorationResults';

interface Props {
  chartType: EChartType;
  setChartType: (type: EChartType) => void;
}

export const DataExplorationChartTypePopover: FC<Props> = ({ chartType, setChartType }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (type: EChartType) => {
    setChartType(type);
    handleClose();
  };

  const isOpen = Boolean(anchorEl);

  return (
    <div>
      <Button className="flex items-center gap-2 text-gray-500 hover:bg-slate-100" variant="text" onClick={handleOpen}>
        {chartType === EChartType.BAR && <BarChartIcon />}
        {chartType === EChartType.PIE && <PieChartIcon />}
        <ArrowDropDownIcon />
      </Button>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={isOpen}
      >
        <Box>
          <MenuItem onClick={() => handleSelect(EChartType.BAR)}>Bar Chart</MenuItem>
          <MenuItem onClick={() => handleSelect(EChartType.PIE)}>Pie Chart</MenuItem>
        </Box>
      </Popover>
    </div>
  );
};
