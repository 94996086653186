import { Suspense, useCallback, FC, memo, useEffect } from 'react';
import { Outlet, useLocation, useMatch } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocalStorage } from 'react-use';

import { Routes } from '@app/constants/routes';
import { TypedNavigate } from '@app/router';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { clsxm } from '@app/styles/clsxm';
import { useStore } from '@app/store/useStore.hook';
import { HAS_ACCESS_TO_STI } from '@app/environment/typed-env';
import { EAuthStatus } from '@app/auth/api/auth.type';
import { AdminSidebarMenu } from '@app/layout/admin-sidebar-menu/AdminSidebarMenu';
import { CircularProgress } from '@app/components/CircularProgress';
import { ErrorPage } from '@app/components/error-page';

export const RootModule: FC = memo(() => {
  const authStatus = useStore.useAuthStatus();
  const currentUser = useStore.useCurrentUser();
  const isRoot = Boolean(useMatch(Routes.index));
  const { useSignOut } = useStore;
  const location = useLocation();

  const isClaimsInfoRoute = location.pathname.includes(Routes.claims.info);
  const isPdfRoute = location.pathname.includes('/pdf');

  const { setIsSidebarOpen } = useStore(({ setIsSidebarOpen }) => ({ setIsSidebarOpen }));

  const [open = true, setOpen] = useLocalStorage('isOpenSidebar', true);

  const toggleDrawerView = useCallback(() => {
    setIsSidebarOpen(!open);
    setOpen(!open);
  }, [setIsSidebarOpen, open, setOpen]);

  const signOut = useSignOut();

  useEffect(() => {
    if (authStatus === EAuthStatus.UNAUTHORIZED && isRoot) {
      signOut();
    }
  }, [authStatus, isRoot, signOut]);

  if (authStatus === EAuthStatus.UNKNOWN || (authStatus === EAuthStatus.UNAUTHORIZED && isRoot)) {
    return null;
  }

  if (authStatus === EAuthStatus.SIGNED_IN && currentUser) {
    if (isRoot) {
      return <TypedNavigate to={HAS_ACCESS_TO_STI ? Routes.sti.index : Routes.dashboard} />;
    }
  }

  if (isPdfRoute || isClaimsInfoRoute) {
    return (
      <Suspense
        fallback={
          <div className="flex h-screen w-full items-center justify-center">
            <CircularProgress />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    );
  }

  const SIDEBAR_FULL_WIDTH = 238;
  const SIDEBAR_SMALL_WIDTH = 80;

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <div className="relative h-screen w-screen overflow-hidden">
        <div
          className="absolute left-0 z-[100] h-screen transition-all duration-500"
          style={{ width: open ? SIDEBAR_FULL_WIDTH : SIDEBAR_SMALL_WIDTH }}
        >
          <div className="group relative size-full">
            <AdminSidebarMenu open={open} />
            <IconButton
              onClick={toggleDrawerView}
              className={clsxm(
                'duration-400 invisible absolute -right-[13px] top-[38px] z-[100] size-[26px] rounded-full bg-white  text-gray-600 shadow-elevation-10 transition-all',
                'group-hover:visible'
              )}
            >
              <ChevronLeftIcon width={24} height={24} className={clsxm('size-[26px]', open ? '' : 'rotate-180')} />
            </IconButton>
          </div>
        </div>
        <div
          className="absolute right-0 h-screen overflow-auto transition-all duration-500"
          style={{ width: `calc(100vw - ${open ? SIDEBAR_FULL_WIDTH : SIDEBAR_SMALL_WIDTH}px)` }}
        >
          <Suspense
            fallback={
              <div className="flex size-full items-center justify-center">
                <CircularProgress />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </div>
      </div>
    </ErrorBoundary>
  );
});
