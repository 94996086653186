import { Input } from '@app/components/fields/input/Input';
import { OptionItem } from '@app/components/select';
import { ESubmissionRiskFactor, RiskFactorOutDto, SubmissionRiskFactorInDto } from '@app/swagger-types';
import { FormControl, FormControlLabel, Radio, RadioGroup, Select, TextareaAutosize, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useConfirmSubmissionRiskFactorChangeDialog } from '../modals/ConfirmSubmissionRiskFactorChangeDialog';
import {
  useGetRiskFactorsBySubmissionIdQuery,
  useUpdateSubmissionRiskFactorsMutation,
} from '../api/submission.api.hooks';
import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { SubmissionUtils } from '../utils/submission.utils';

interface Props {
  submissionId: string;
  riskFactor: RiskFactorOutDto;
  disabled: boolean;
  hasFullCalcData: boolean;
}

export const SubmissionRiskFactorField: FC<Props> = ({ riskFactor, disabled, hasFullCalcData, submissionId }) => {
  const confirmDialog = useConfirmSubmissionRiskFactorChangeDialog();
  const { onChangeFullCalcResult } = useSubmissionCalculatorFormsContext();

  const [riskFactorStatus, setRiskFactorStatus] = useState(ESubmissionRiskFactor.NOT_APPLICABLE);
  const [notes, setNotes] = useState('');
  const [riskImpactLevelName, setRiskImpactLevelName] = useState('');
  const [riskValue, setRiskValue] = useState<number | undefined>();

  const { data: submissionRiskFactors, isFetching } = useGetRiskFactorsBySubmissionIdQuery(submissionId, {
    keepPreviousData: true,
  });

  const { mutate: updateRiskFactors, isLoading: isUpdating } = useUpdateSubmissionRiskFactorsMutation(submissionId);

  const submissionRiskFactor = submissionRiskFactors?.risks.find(({ riskFactorId }) => riskFactorId === riskFactor.id);

  const handleReset = useCallback(() => {
    setRiskFactorStatus(submissionRiskFactor?.riskFactorStatus || ESubmissionRiskFactor.NOT_APPLICABLE);
    setNotes(submissionRiskFactor?.notes ?? '');
    setRiskImpactLevelName(submissionRiskFactor?.riskImpactLevelName ?? '');
    setRiskValue(submissionRiskFactor?.riskValue ?? undefined);
  }, [submissionRiskFactor]);

  const handleUpdate = (override: Partial<SubmissionRiskFactorInDto>) => {
    if (submissionRiskFactor) {
      const newData: SubmissionRiskFactorInDto = {
        id: submissionRiskFactor.id,
        uiOrder: submissionRiskFactor.uiOrder,
        riskFactorValue: riskValue,
        riskImpactLevelName,
        riskFactorStatus,
        notes,
        ...override,
      };

      if (hasFullCalcData) {
        confirmDialog.open({
          onCancel: handleReset,
          onConfirm: () =>
            updateRiskFactors([newData], {
              // reset calc result
              onSuccess: () => onChangeFullCalcResult(undefined),
            }),
        });
      } else {
        updateRiskFactors([newData]);
      }
    }
  };

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const isDisabled = disabled || isFetching || isUpdating;

  return (
    <div className="grid grid-cols-2 gap-4 border-b py-10">
      <div className="flex flex-col">
        <Typography className="text-m font-semibold text-black/90">{riskFactor.riskFactorName}</Typography>
        <Typography className="text-xs text-black/90">{riskFactor.riskFactorDescription}</Typography>
      </div>
      <div className="flex flex-col gap-4">
        <Select
          value={riskFactorStatus}
          onChange={(e) => {
            const typedVal = e.target.value as ESubmissionRiskFactor;

            setRiskFactorStatus(typedVal);

            handleUpdate({
              riskFactorStatus: typedVal,
              // reset fields on riskFactorStatus change
              notes: undefined,
              riskFactorValue: undefined,
              riskImpactLevelName: undefined,
            });
          }}
          renderValue={(value) =>
            value === ESubmissionRiskFactor.NOT_APPLICABLE ? (
              <span className="text-zinc-300">{SubmissionUtils.getFriendlyESubmissionRiskFactorLabel(value)}</span>
            ) : (
              SubmissionUtils.getFriendlyESubmissionRiskFactorLabel(value)
            )
          }
          disabled={isDisabled}
        >
          <OptionItem value={ESubmissionRiskFactor.NOT_APPLICABLE}>
            {SubmissionUtils.getFriendlyESubmissionRiskFactorLabel(ESubmissionRiskFactor.NOT_APPLICABLE)}
          </OptionItem>
          <OptionItem value={ESubmissionRiskFactor.CONTEMPLATED_IN_CLASS_CODE}>
            {SubmissionUtils.getFriendlyESubmissionRiskFactorLabel(ESubmissionRiskFactor.CONTEMPLATED_IN_CLASS_CODE)}
          </OptionItem>
          <OptionItem value={ESubmissionRiskFactor.ABNORMAL_HAZARD_PRESENT}>
            {SubmissionUtils.getFriendlyESubmissionRiskFactorLabel(ESubmissionRiskFactor.ABNORMAL_HAZARD_PRESENT)}
          </OptionItem>
        </Select>
        {riskFactorStatus === ESubmissionRiskFactor.CONTEMPLATED_IN_CLASS_CODE && (
          <TextareaAutosize
            placeholder="Notes..."
            minRows={4}
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
            className="rounded border px-[15px] py-[10px] text-xs outline-none focus:border-primary"
            onBlur={(e) => {
              if (e.target.value !== (submissionRiskFactor?.notes ?? '')) {
                handleUpdate({ notes: e.target.value });
              }
            }}
            disabled={isDisabled}
          />
        )}
        {riskFactorStatus === ESubmissionRiskFactor.ABNORMAL_HAZARD_PRESENT && (
          <div className="flex flex-col gap-2">
            <FormControl>
              <RadioGroup
                aria-labelledby="staff-value-label"
                value={riskImpactLevelName}
                name="staff-value"
                onChange={(_, val) => {
                  setRiskImpactLevelName(val);

                  const level = riskFactor.riskSeverityImpactLevels.find(
                    ({ impactLevelName }) => impactLevelName === val
                  );

                  if (level) {
                    setRiskValue(level.defaultImpactLevelValue);
                    handleUpdate({ riskImpactLevelName: val, riskFactorValue: level.defaultImpactLevelValue });
                  }
                }}
              >
                {riskFactor.riskSeverityImpactLevels.map((level) => (
                  <FormControlLabel
                    key={level.impactLevelName}
                    value={level.impactLevelName}
                    control={<Radio />}
                    label={`${level.impactLevelName} (${level.defaultImpactLevelValue}%)`}
                    classes={{ label: 'text-black' }}
                    disabled={isDisabled}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <NumericFormat
              customInput={Input}
              value={riskValue ?? ''}
              onValueChange={(values) => {
                setRiskValue(values.value.length ? Number(values.value) : undefined);
              }}
              onBlur={() => {
                if (riskValue !== (submissionRiskFactor?.riskValue ?? '')) {
                  handleUpdate({ riskFactorValue: riskValue });
                }
              }}
              thousandSeparator
              suffix="%"
              label="Impact"
              placeholder="0%"
              className="max-w-24"
              disabled={isDisabled}
            />
            <TextareaAutosize
              placeholder="Notes..."
              minRows={4}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              className="rounded border px-[15px] py-[10px] text-xs outline-none focus:border-primary"
              disabled={isDisabled}
              onBlur={(e) => {
                if (e.target.value !== (submissionRiskFactor?.notes ?? '')) {
                  handleUpdate({ notes: e.target.value });
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
