import { z } from 'zod';

export const DataExplorationInDtoSchema = z.object({
  dataExplorationType: z.array(z.string()),
  questions: z.array(z.string()),
});

export const DataExplorationPlotOutDtoSchema = z.object({
  keyName: z.string().nullable().optional(),
  keyValue: z.string().nullable().optional(),
  dataValue: z.string().nullable().optional(),
  dataName: z.string().nullable().optional(),
});

export const DataExplorationGridOutDtoSchema = z.object({
  id: z.number().nullable().optional(),
  keyName: z.string().nullable().optional(),
  keyValue: z.string().nullable().optional(),
  dataName: z.string().nullable().optional(),
  dataValue: z.string().nullable().optional(),
});

export const DataExplorationQuestionOutDtoSchema = z.object({
  question: z.string().nullable().optional(),
  answer: z.string().nullable().optional(),
  isSuccessful: z.boolean(),
  plotData: z.array(DataExplorationPlotOutDtoSchema).nullable().optional(),
  gridData: z.array(DataExplorationGridOutDtoSchema).nullable().optional(),
});

export const DataExplorationOutDtoSchema = z.object({
  isSuccessful: z.boolean(),
  questionResults: z.array(DataExplorationQuestionOutDtoSchema),
  logId: z.string(),
});

export const DataExplorationTypeOutDtoSchema = z.object({
  type: z.string(),
  example: z.string(),
});

export const DataExplorationTypesOutDtoSchema = z.object({
  isSuccessful: z.boolean(),
  dataExplorationTypes: z.array(DataExplorationTypeOutDtoSchema),
});

export const DataExplorationPromptOutDtoSchema = z.object({
  prompt: z.string().nullable().optional(),
  createdDate: z.string(),
});

export const PageResponseDataExplorationPromptOutDtoSchema = z.object({
  result: z.array(DataExplorationPromptOutDtoSchema),
  total: z.number(),
});
