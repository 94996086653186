import { ApiService } from './api.service';
import { typedEnv } from '@app/environment/typed-env';
import queryString from 'query-string';

export * from './api-schema-validations';

export const apiService = new ApiService();
export const authApiService = new ApiService({
  baseURL: typedEnv.REACT_APP_AUTH_API_URL,
  paramsSerializer: (params) => queryString.stringify(params),
  withCredentials: true,
});
