import { FC } from 'react';

import { TableColumn } from '../../../components/table/TableTypes';
import { renderDollarAmount } from '@app/utils/currency/currency.utils';
import { StateNcciComparison } from '../../../swagger-types';
import { Table } from '../../../components/table/Table';

interface Props {
  states: StateNcciComparison[];
  pdf?: boolean;
}

export const ComparisonNcciStatesTable: FC<Props> = ({ states, pdf }) => {
  const cols: TableColumn<StateNcciComparison>[] = [
    {
      headerName: 'State Class Code',
      headerJSX: (
        <>
          State
          <br />
          Class
          <br />
          Code
        </>
      ),
      field: 'stateClassCode',
      align: 'center',
    },
    {
      headerName: 'Impact Weight',
      headerJSX: (
        <>
          Impact
          <br />
          Weight
        </>
      ),
      field: 'impactWeightPercent',
      align: 'center',
      render: ({ impactWeightPercent }) => `${impactWeightPercent || 0}%`,
    },
    {
      headerName: 'NCCI Reported Policies',
      headerJSX: (
        <>
          NCCI
          <br />
          Reported
          <br />
          Policies
        </>
      ),
      field: 'ncciReportedPolicies',
      align: 'center',
    },
    {
      headerName: 'Phraseology',
      field: 'phraseology',
      align: 'left',
      render: ({ phraseology = '' }) => {
        return <span className="whitespace-normal">{phraseology}</span>;
      },
    },
    {
      headerName: 'Company Payroll',
      headerJSX: (
        <>
          Company
          <br />
          Payroll
        </>
      ),
      field: 'companyPayroll',
      headAlign: 'center',
      align: 'right',
      render: ({ companyPayroll }) => renderDollarAmount(Number(companyPayroll), 0),
    },
    {
      headerName: 'Avg NCCI Payroll',
      headerJSX: (
        <>
          Avg
          <br />
          NCCI Payroll
        </>
      ),
      field: 'avgNcciPayroll',
      headAlign: 'center',
      align: 'right',
      render: ({ avgNcciPayroll }) => renderDollarAmount(Number(avgNcciPayroll), 0),
    },
    {
      headerName: 'NCCI Avg. Med. Incurred',
      headerJSX: (
        <>
          NCCI Avg.
          <br />
          Med. Incurred
        </>
      ),
      field: 'ncciAvgMedIncurred',
      headAlign: 'center',
      align: 'right',
      render: ({ ncciAvgMedIncurred }) => renderDollarAmount(Number(ncciAvgMedIncurred), 0),
    },
    {
      headerName: 'Avg NCCI Indem. Incurred',
      headerJSX: (
        <>
          Avg NCCI
          <br />
          Indem. Incurred
        </>
      ),
      field: 'avgNcciIndemIncurred',
      headAlign: 'center',
      align: 'right',
      render: ({ avgNcciIndemIncurred }) => renderDollarAmount(Number(avgNcciIndemIncurred), 0),
    },
    {
      headerName: 'NCCI Avg. Total Incurred',
      headerJSX: (
        <>
          NCCI Avg.
          <br />
          Total Incurred
        </>
      ),
      field: 'ncciAvgTotalIncurred',
      headAlign: 'center',
      align: 'right',
      render: ({ ncciAvgTotalIncurred }) => renderDollarAmount(Number(ncciAvgTotalIncurred), 0),
    },
  ];

  return <Table title="NCCI DATA AVAILABILITY & IMPACT:" cols={cols} tableData={states} pdf={pdf} hidePageSize />;
};
