import { typedEnv } from '@app/environment/typed-env';
import { Outlet } from 'react-router-dom';

export const RoadmapModule: React.FC = () => {
  return (
    <div>
      <h3 className="mt-2 text-center font-bold">{typedEnv.REACT_APP_TITLE} Roadmap</h3>
      <Outlet />
    </div>
  );
};
