import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WarningOutlined } from '@mui/icons-material';

import {
  SubmissionVerificationOutDto,
  SubmissionNamedInsuredOutDto,
  ESubmissionClaimStatus,
  ESubmissionFileStatus,
  ESubmissionStatus,
  ESubmissionRiskFactor,
} from '@app/swagger-types';
import { SubmissionLossReviewVerifiedColumns } from '../components/VerifyLossRunDataTable';
import { CircularProgress } from '@app/components/CircularProgress';

export const SubmissionUtils = {
  getStatusColor: (status: ESubmissionStatus) => {
    switch (status) {
      case ESubmissionStatus.DRAFT:
        return '#737373';
      case ESubmissionStatus.EXTRACTING:
      case ESubmissionStatus.EXTRACTING_PARTIAL:
        return '#0288D1';
      case ESubmissionStatus.EXTRACTING_COMPLETED:
        return '#2E7D32';
      case ESubmissionStatus.EXTRACTING_ERROR:
        return '#D32F2F';
      default:
        return '#737373';
    }
  },
  getFriendlyStatusLabel: (status: ESubmissionStatus) => {
    switch (status) {
      case ESubmissionStatus.DRAFT:
        return 'Draft';
      case ESubmissionStatus.EXTRACTING:
        return 'Extracting';
      case ESubmissionStatus.EXTRACTING_PARTIAL:
        return 'Partial Extraction';
      case ESubmissionStatus.EXTRACTING_ERROR:
        return 'Extraction Error';
      case ESubmissionStatus.EXTRACTING_COMPLETED:
        return 'Extraction Complete';
      default:
        return 'Unknown';
    }
  },
  getFriendlyFileStatusLabel: (status: ESubmissionFileStatus) => {
    switch (status) {
      case ESubmissionFileStatus.INITIAL:
        return 'Uploading';
      case ESubmissionFileStatus.PROCESSING:
        return 'Preparing Document';
      case ESubmissionFileStatus.POLLING_FOR_RESULT:
        return 'Extracting Data';
      case ESubmissionFileStatus.DOWNLOADING_RESPONSE:
      case ESubmissionFileStatus.CHECKING_RESPONSE:
        return 'Processing Results';
      case ESubmissionFileStatus.UPDATING_RESULTS:
        return 'Updating Workbook';
      case ESubmissionFileStatus.PROCESSING_FAILED:
        return 'File Not Supported';
      case ESubmissionFileStatus.ERROR:
        return 'System Error';
      case ESubmissionFileStatus.DONE:
        return 'Done';
      default:
        return 'Unknown';
    }
  },
  getFriendlySubmissionClaimStatusLabel: (status: ESubmissionClaimStatus | string) => {
    switch (status) {
      case ESubmissionClaimStatus.OPEN:
        return 'Open';
      case ESubmissionClaimStatus.CLOSED:
        return 'Closed';
      default:
        return status;
    }
  },
  // getFriendlySubmissionClaimTypeLabel: (type: ESubmissionClaimType | string) => {
  //   switch (type) {
  //     case ESubmissionClaimType.INDEMNITY:
  //       return 'Indemnity';
  //     case ESubmissionClaimType.MEDICAL:
  //       return 'Medical';
  //     case ESubmissionClaimType.REPORT_ONLY:
  //       return 'Report Only';
  //     default:
  //       return type;
  //   }
  // },
  getFileStatusIcon: (status: ESubmissionFileStatus) => {
    switch (status) {
      case ESubmissionFileStatus.DONE:
        return <CheckCircleIcon className="fill-green-600" />;
      case ESubmissionFileStatus.ERROR:
        return <WarningOutlined className="fill-red-500" />;
      case ESubmissionFileStatus.PROCESSING:
      case ESubmissionFileStatus.POLLING_FOR_RESULT:
      case ESubmissionFileStatus.DOWNLOADING_RESPONSE:
      case ESubmissionFileStatus.CHECKING_RESPONSE:
      case ESubmissionFileStatus.UPDATING_RESULTS:
        return <CircularProgress size={24} />;
      case ESubmissionFileStatus.PROCESSING_FAILED:
        return <WarningAmberIcon className="fill-red-500" />;
      default:
        return null;
    }
  },
  getIsAtLeastOneVerified: (dto?: SubmissionVerificationOutDto) => {
    if (!dto) {
      return false;
    }

    return dto.carriers.some((carrier) => {
      return (
        carrier.totalClaimCountVerified ||
        carrier.totalExpensesVerified ||
        carrier.totalIncurredVerified ||
        carrier.totalIndemnityVerified ||
        carrier.totalMedicalVerified ||
        carrier.totalReservesVerified
      );
    });
  },
  getIfCarrierVerified: (field: SubmissionLossReviewVerifiedColumns, dto?: SubmissionVerificationOutDto) => {
    if (dto?.carriers?.every((carrier) => carrier?.totalClaimCountVerified && field === 'numberOfClaims')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalExpensesVerified && field === 'totalExpense')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalIncurredVerified && field === 'totalIncurred')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalIndemnityVerified && field === 'totalIndemnity')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalMedicalVerified && field === 'totalMedical')) {
      return true;
    } else if (dto?.carriers?.every((carrier) => carrier?.totalReservesVerified && field === 'totalReserves')) {
      return true;
    }

    return false;
  },
  renderNamedInsuredLabel: (option: SubmissionNamedInsuredOutDto) =>
    `${option.name}${option.fein ? ` (${option.fein})` : ''}`,
  getFriendlyESubmissionRiskFactorLabel: (value: ESubmissionRiskFactor) => {
    switch (value) {
      case ESubmissionRiskFactor.NOT_APPLICABLE:
        return 'Not Applicable';
      case ESubmissionRiskFactor.CONTEMPLATED_IN_CLASS_CODE:
        return 'Contemplated in Class Code';
      case ESubmissionRiskFactor.ABNORMAL_HAZARD_PRESENT:
        return 'Abnormal Hazards';
    }
  },
};
