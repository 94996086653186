import { createContext, FC, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

import { useHandler } from '@app/hooks/useHandler.hook';

import { DEFAULT_LOSS_RATIO_FILTER_VALUES } from '../const/loss-ratio.filter.const';
import { useLossRatioFiltersLocalStorage } from '../hooks/useLossRatioFiltersLocalStorage';
import { useSharedLossRatioFiltersLocalStorage } from '../hooks/useSharedLossRatioFiltersLocalStorage';
import { useFilterIdQueryParam } from '@app/domain/claim/hooks/useFilterIdQueryParam';
import { OverrideLossRatioFilter } from '@app/swagger-override-types';

const LossRatioFiltersContext = createContext({
  countChanges: 0,
  hasChanges: false,
  filters: DEFAULT_LOSS_RATIO_FILTER_VALUES,
  setFilters: (_: OverrideLossRatioFilter) => {},
  sharedFilters: DEFAULT_LOSS_RATIO_FILTER_VALUES,
  setSharedFilters: (_: OverrideLossRatioFilter) => {},
  filtersDto: {} as OverrideLossRatioFilter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeFilterParam: (key: keyof OverrideLossRatioFilter, _: OverrideLossRatioFilter[typeof key]) => {},
  onChangeFilterParams: (_: Partial<OverrideLossRatioFilter>) => {},
  onResetFilters: () => {},
  onSubmitFilterParams: (_: OverrideLossRatioFilter) => {},
  expanded: false,
  setExpanded: (_: SetStateAction<boolean>) => {},
});

interface Props {
  children: ReactNode;
}

export const LossRatioFiltersContextProvider: FC<Props> = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const [filterIdParam, setFilterIdParam] = useFilterIdQueryParam();
  const [sharedFilters, setSharedFilters] = useSharedLossRatioFiltersLocalStorage();
  const [filters, setFilters] = useLossRatioFiltersLocalStorage();

  const visibleFilters: OverrideLossRatioFilter = useMemo(
    () => (filterIdParam ? sharedFilters : filters) || DEFAULT_LOSS_RATIO_FILTER_VALUES,
    [filterIdParam, sharedFilters, filters]
  );

  const filtersDto: OverrideLossRatioFilter = useMemo(() => visibleFilters, [visibleFilters]);

  const removeFilterIdParam = useHandler(() => {
    setFilterIdParam(undefined, 'pushIn');
  });

  const onChangeFilterParam = useHandler(
    (key: keyof OverrideLossRatioFilter, value: OverrideLossRatioFilter[typeof key]) => {
      setFilters({ ...visibleFilters, [key]: value });
      removeFilterIdParam();
    }
  );

  const onChangeFilterParams = useHandler((override: Partial<OverrideLossRatioFilter>) => {
    setFilters({ ...visibleFilters, ...override });
    removeFilterIdParam();
  });

  const onResetFilters = useHandler(() => {
    setFilters(DEFAULT_LOSS_RATIO_FILTER_VALUES);
    removeFilterIdParam();
  });

  const onSubmitFilterParams = useHandler((values: OverrideLossRatioFilter) => {
    setFilters(values);
    removeFilterIdParam();
  });

  const countChanges = useMemo(() => {
    return [
      Boolean(JSON.stringify(filtersDto.agencies) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.agencies)),
      Boolean(JSON.stringify(filtersDto.ncci) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.ncci)),
      Boolean(filtersDto.effectiveDateStart !== DEFAULT_LOSS_RATIO_FILTER_VALUES.effectiveDateStart),
      Boolean(filtersDto.effectiveDateEnd !== DEFAULT_LOSS_RATIO_FILTER_VALUES.effectiveDateEnd),
      Boolean(filtersDto.bindingMonthStart !== DEFAULT_LOSS_RATIO_FILTER_VALUES.bindingMonthStart),
      Boolean(filtersDto.bindingMonthEnd !== DEFAULT_LOSS_RATIO_FILTER_VALUES.bindingMonthEnd),
      Boolean(JSON.stringify(filtersDto.states) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.states)),
      Boolean(
        JSON.stringify(filtersDto.policyNumbers) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.policyNumbers)
      ),
      Boolean(
        JSON.stringify(filtersDto.insuredNames) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.insuredNames)
      ),
      Boolean(
        JSON.stringify(filtersDto.hazardGrades) !== JSON.stringify(DEFAULT_LOSS_RATIO_FILTER_VALUES.hazardGrades)
      ),
    ].filter(Boolean).length;
  }, [filtersDto]);

  const value = useMemo(
    () => ({
      countChanges,
      hasChanges: Boolean(countChanges),
      filters: visibleFilters,
      sharedFilters,
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
      onChangeFilterParams,
      expanded,
      setExpanded,
    }),
    [
      countChanges,
      setFilters,
      filtersDto,
      onChangeFilterParam,
      onResetFilters,
      onSubmitFilterParams,
      setSharedFilters,
      visibleFilters,
      sharedFilters,
      onChangeFilterParams,
      expanded,
      setExpanded,
    ]
  );

  return <LossRatioFiltersContext.Provider value={value}>{children}</LossRatioFiltersContext.Provider>;
};

export const useLossRatioFiltersContext = () => useContext(LossRatioFiltersContext);
