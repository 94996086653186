import { Routes } from '@app/constants/routes';

import { DevKitModule } from './DevKitModule';
import { DevKitPage } from './pages/DevKit.page';
import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';

export const DevKitRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.devKit.index,
  element: <DevKitModule />,
  children: [{ index: true, element: <DevKitPage /> }],
});
