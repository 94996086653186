import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import {
  GetCompactUsersQueryParams,
  GetUserQueryParams,
  GetUsersQueryParams,
  UserApi,
} from '@app/domain/user/api/user.api';
import {
  SignUpStepOneInDto,
  UpdateUserInDto,
  UserConfirmationStatusOutDto,
  UserOutDto,
  UserPoolNameOutDto,
  UserPoolGroupOutDto,
  UsersOutDto,
  UserStatusInDto,
  UserStatusOutDto,
  UserOutCompactDto,
} from '@app/swagger-types';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { ServerError } from '@app/swagger-override-types';
import { useUserPoolContext } from '@app/domain/user/context/UserPoolContext';

export const useUsersQuery = (
  params: Omit<GetUsersQueryParams, 'userPoolName'>,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    UsersOutDto,
    (typeof EQueryConfigName.GET_USERS | GetUsersQueryParams)[]
  >
) => {
  const { userPoolName } = useUserPoolContext();
  return useQuery({
    queryKey: [EQueryConfigName.GET_USERS, { userPoolName, ...params }],
    queryFn: async () => {
      if (userPoolName) {
        return await UserApi.getUsers({ userPoolName, ...params });
      }

      const mockRes: UsersOutDto = { users: [], last: true };

      return mockRes;
    },
    ...options,
  });
};

export const useUsersCompactQuery = (
  params: GetCompactUsersQueryParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    UserOutCompactDto[],
    (typeof EQueryConfigName.GET_USERS_COMPACT | GetCompactUsersQueryParams)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_USERS_COMPACT, params],
    queryFn: async () => {
      return await UserApi.getCompactUsers(params);
    },
    ...options,
  });
};

export const useUserQuery = (
  params: Omit<GetUserQueryParams, 'userPoolName'>,
  options?: UseQueryOptions<unknown, ServerError, UserOutDto, (typeof EQueryConfigName.GET_USER | GetUserQueryParams)[]>
) => {
  const { userPoolName } = useUserPoolContext();

  return useQuery({
    queryKey: [EQueryConfigName.GET_USER, { userPoolName, ...params }],
    queryFn: async () => {
      return await UserApi.getUser({ userPoolName, ...params });
    },
    ...options,
  });
};

export const useUserPoolsQuery = (
  options?: UseQueryOptions<unknown, ServerError, UserPoolNameOutDto, (typeof EQueryConfigName.GET_USER_POOLS)[]>
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_USER_POOLS],
    queryFn: async () => {
      return await UserApi.getPools();
    },
    ...options,
  });
};

export const useUserStatusesQuery = (
  options?: UseQueryOptions<unknown, ServerError, UserStatusOutDto, (typeof EQueryConfigName.GET_USER_STATUSES)[]>
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_USER_STATUSES],
    queryFn: async () => {
      return await UserApi.getUserStatuses();
    },
    ...options,
  });
};
export const useUserConfirmStatusesQuery = (
  options?: UseQueryOptions<
    unknown,
    ServerError,
    UserConfirmationStatusOutDto,
    (typeof EQueryConfigName.GET_USER_CONFIRM_STATUSES)[]
  >
) => {
  return useQuery({
    queryKey: [EQueryConfigName.GET_USER_CONFIRM_STATUSES],
    queryFn: async () => {
      return await UserApi.getUserConfirmStatuses();
    },
    ...options,
  });
};

export const useUserGroupsQuery = (
  options?: UseQueryOptions<
    unknown,
    ServerError,
    UserPoolGroupOutDto,
    (typeof EQueryConfigName.GET_USER_ROLES | string)[]
  >
) => {
  const { userPoolName } = useUserPoolContext();

  return useQuery({
    queryKey: [EQueryConfigName.GET_USER_ROLES, userPoolName],
    queryFn: async () => {
      if (userPoolName) {
        return await UserApi.getUserGroups(userPoolName);
      } else {
        const emptyResult: UserPoolGroupOutDto = {
          groupList: [],
        };
        return emptyResult;
      }
    },
    ...options,
  });
};

// MUTATIONS

export const useCreateUser = (options?: UseMutationOptions<unknown, ServerError, SignUpStepOneInDto>) => {
  const queryClient = useQueryClient();
  const { userPoolName } = useUserPoolContext();

  return useMutation({
    ...options,
    mutationFn: async (Dto) => {
      return await UserApi.createUser({ userPoolName, ...Dto, silent: true });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_USERS, { userPoolName }]);
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useEditUser = (
  options?: UseMutationOptions<unknown, ServerError, UpdateUserInDto & { email: string }>
) => {
  const queryClient = useQueryClient();
  const { userPoolName } = useUserPoolContext();

  return useMutation({
    ...options,
    mutationFn: async (Dto) => {
      return await UserApi.editUser({ userPoolName, ...Dto, silent: true });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_USER, { email: variables.email, userPoolName }]);
      await queryClient.invalidateQueries([EQueryConfigName.GET_USERS, { userPoolName }]);
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteUser = (params: { email: string }, options?: UseMutationOptions<unknown, ServerError>) => {
  const queryClient = useQueryClient();
  const { userPoolName } = useUserPoolContext();

  return useMutation({
    ...options,
    mutationFn: async () => {
      return await UserApi.deleteUser({ ...params, userPoolName });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_USERS, { userPoolName }]);
      queryClient.removeQueries([EQueryConfigName.GET_USER, { ...params, userPoolName }]);
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useResendInvitation = (params: { email: string }, options?: UseMutationOptions<unknown, ServerError>) => {
  const { userPoolName } = useUserPoolContext();

  return useMutation({
    ...options,
    mutationFn: async () => {
      return await UserApi.resendInvitation({ userPoolName, ...params });
    },
  });
};

export const useResetPassword = (params: { email: string }, options?: UseMutationOptions<unknown, ServerError>) => {
  const queryClient = useQueryClient();
  const { userPoolName } = useUserPoolContext();

  return useMutation({
    ...options,
    mutationFn: async () => {
      return await UserApi.resetPassword({ userPoolName, ...params });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_USER, { userPoolName, ...params }]);
      await queryClient.invalidateQueries([EQueryConfigName.GET_USERS, { userPoolName }]);
      await options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useChangeUserStatus = (
  params: { email: string },
  options: UseMutationOptions<unknown, ServerError, UserStatusInDto> = {}
) => {
  const queryClient = useQueryClient();
  const { userPoolName } = useUserPoolContext();

  return useMutation({
    ...options,
    mutationFn: async (Dto) => {
      return await UserApi.changeUserStatus({ userPoolName, ...params, ...Dto });
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([EQueryConfigName.GET_USER, { userPoolName, ...params }]);
      await queryClient.invalidateQueries([EQueryConfigName.GET_USERS, { userPoolName }]);
      await options?.onSuccess?.(data, variables, context);
    },
  });
};
