import { MonthPickerRange } from '@app/components/month-picker-range/MonthPickerRange';
import { createMockClaimOutDto } from '@app/domain/claim/mock/createMockClaimOutDto';
import { AdjusterInfoBlock } from '@app/domain/claim/widgets/AdjusterInfoBlock';
import { AutocompleteExamples } from '@app/modules/ui-kit/components/AutocompleteExamples';
import { ButtonExamples } from '@app/modules/ui-kit/components/ButtonExamples';
import { InputExamples } from '@app/modules/ui-kit/components/InputExamples';
import { LossRatiosBarChartExamples } from '@app/modules/ui-kit/components/LossRatiosBarChartExamples';
import { Typography } from '@mui/material';
import React from 'react';
import { UIStyledSection } from '../components/UIStyledSection';
import { DatePickerRange } from '@app/components/date-picker-range/DatePickerRange';
import { DatePicker } from '@app/components/date-picker/DatePicker';
import { StiSliderExamples } from '../components/StiSliderExamples';
import { DotsLoader } from '@app/components/loaders/dots-loader/DotsLoader';

export const UISectionFeatured: React.FC = () => {
  return (
    <UIStyledSection title="Featured">
      <DotsLoader />
      <StiSliderExamples />
      <div className="grid grid-cols-[320px,320px,280px] gap-2">
        <div className="flex flex-col gap-2">
          <Typography>Month Picker Range:</Typography>
          <MonthPickerRange />
        </div>
        <div className="flex flex-col gap-2">
          <Typography>Date Picker Range:</Typography>
          <DatePickerRange />
        </div>
        <div className="flex flex-col gap-2">
          <Typography>Date Picker:</Typography>
          <DatePicker />
        </div>
      </div>
      <div className="grid grid-cols-5 gap-10">
        <LossRatiosBarChartExamples />
        <div className="col-span-5 p-10">
          <Typography variant="h4" className="py-4">
            ClaimInfoBlock:
          </Typography>
          <AdjusterInfoBlock claim={createMockClaimOutDto()} />
        </div>
        <div className="col-span-2">
          <InputExamples />
        </div>
        <div className="col-span-3">
          <ButtonExamples />
        </div>
        <div className="col-span-2">
          <AutocompleteExamples />
        </div>
      </div>
    </UIStyledSection>
  );
};
