import React from 'react';

import { Typography } from '@mui/material';

import { EImpact } from '../../../swagger-types';
import { renderImpactBgColor, renderImpactLabel, renderImpactColor } from '../utils/impact.utils';

interface Props {
  impact: EImpact;
}
export const IncidentStatTag: React.FC<Props> = ({ impact }) => {
  return (
    <div
      className="flex items-center justify-center rounded"
      style={{
        width: 202,
        height: 30,
        backgroundColor: renderImpactBgColor(impact),
      }}
    >
      <Typography
        className="font-medium uppercase"
        style={{
          color: renderImpactColor(impact),
        }}
      >
        {renderImpactLabel(impact)}
      </Typography>
    </div>
  );
};
