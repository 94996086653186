import { StiSlider } from '@app/domain/sti/components/StiSlider';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { FC, useState } from 'react';

enum EValue {
  DEFAULT = 'DEFAULT',
  DISABLED = 'DISABLED',
  IS_RESETTING = 'IS_RESETTING',
}

export const StiSliderExamples: FC = () => {
  const [tab, setTab] = useState<EValue>(EValue.DEFAULT);
  const [value, setValue] = useState(0);

  return (
    <div className="col-span-5 p-10">
      <div className="flex w-full items-center justify-between gap-2 py-4">
        <Typography variant="h4">StiSlider:</Typography>
      </div>
      <FormControl>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue={EValue.DEFAULT}
          value={tab}
          name="radio-buttons-group"
          onChange={(_, val) => setTab(val as EValue)}
        >
          <FormControlLabel value={EValue.DEFAULT} control={<Radio />} label="DEFAULT" />
          <FormControlLabel value={EValue.DISABLED} control={<Radio />} label="DISABLED" />
          <FormControlLabel value={EValue.IS_RESETTING} control={<Radio />} label="IS_RESETTING" />
        </RadioGroup>
      </FormControl>
      {tab === EValue.DEFAULT && <StiSlider value={value} onChange={setValue} onReset={() => setValue(0)} />}
      {tab === EValue.DISABLED && <StiSlider value={value} onChange={setValue} disabled onReset={() => setValue(0)} />}
      {tab === EValue.IS_RESETTING && (
        <StiSlider value={value} onChange={setValue} isResetting onReset={() => setValue(0)} />
      )}
    </div>
  );
};
