export const Routes = {
  index: '/',
  dashboard: '/dashboard',
  admin: {
    index: '/admin',
    users: '/admin/users',
    createUser: '/admin/users/new',
    editUser: '/admin/users/:email/edit',
  },
  claims: {
    index: '/claims',
    file: '/claims/file',
    search: '/claims/search',
    detailedClaim: '/claims/search/:id',
    detailedClaimPdf: '/claims/search/pdf/:id',
    info: '/claims/info',
    state: '/claims/info/:stateCode',
    first_fill: '/claims/info/first-fill',
  },
  lossRatio: {
    index: '/loss-ratios',
    chartPdf: '/loss-ratios/pdf/chart',
  },
  dataExploration: '/data-exploration',
  submissions: {
    index: '/submissions',
    createSubmission: '/submissions/add',
    submissionData: '/submissions/:id/data',
    createNamedInsured: '/submissions/named-insured/add',
  },
  sti: {
    index: '/sti',
    fullCalcPdf: '/sti/pdf/full-calc',
    awsPdf: '/sti/pdf/aws',
  },
  roadmap: {
    index: '/roadmap',
  },
  uiKit: {
    index: '/ui-kit',
  },
  devKit: {
    index: '/dev-kit',
  },
  sample: {
    index: '/sample',
  },
} as const;
