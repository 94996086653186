import { PropsWithChildren, FC } from 'react';

import { RequireAtLeastOne } from '@app/utils/type.utils';
import { useRoleRequired, RoleRequiredProps } from '@app/auth/useRoleRequired.hook';

export const RoleRequired: FC<PropsWithChildren<RequireAtLeastOne<RoleRequiredProps>>> = ({
  children,
  ...roleProps
}) => {
  const isAllowed = useRoleRequired(roleProps);

  if (isAllowed) {
    return <>{children}</>;
  }

  return null;
};
