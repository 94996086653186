import { UseMutateAsyncFunction, useQueryClient } from '@tanstack/react-query';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FC, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@mui/material';

import { DataExplorationTypeOutDto, DataExplorationOutDto, DataExplorationInDto } from '@app/swagger-types';
import { DEFAULT_DATA_EXPLORATION_TYPE } from '../const/data-exploration.const';
import { useGetDataExplorationTypes } from '../api/data-exploration.api.hooks';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { DataExplorationAutocomplete } from './DataExplorationAutocomplete';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { DataTypeSelector } from './DataExplorationTypeSelector';
import { ServerError } from '@app/swagger-override-types';
import { useHandler } from '@app/hooks/useHandler.hook';

interface Props {
  setData: (data: DataExplorationOutDto | null) => void;
  setSubmittedQuestion: (submittedQuestion: string) => void;
  isRequestingDataExploration: boolean;
  requestDataExploration: UseMutateAsyncFunction<DataExplorationOutDto, ServerError, DataExplorationInDto>;
}

export const DEFAULT_DATA_EXPLORATION_PROMPTS_PARAMS = {
  page: 0,
  size: 25,
  sort: 'createdDate,desc',
};

export const DataExplorationSearch: FC<Props> = ({
  setData,
  setSubmittedQuestion,
  isRequestingDataExploration,
  requestDataExploration,
}) => {
  const { data, isLoading: isTypesLoading } = useGetDataExplorationTypes();

  const [dataExplorationType, setDataExplorationType] =
    useState<DataExplorationTypeOutDto>(DEFAULT_DATA_EXPLORATION_TYPE);

  const [question, setQuestion] = useState('');

  const queryClient = useQueryClient();

  useEffect(() => {
    if (data?.isSuccessful && data?.dataExplorationTypes?.length) {
      setDataExplorationType(data.dataExplorationTypes[0]);
    }
  }, [data?.dataExplorationTypes, data?.isSuccessful]);

  const handleTypeSelect = useHandler((value: DataExplorationTypeOutDto) => {
    setDataExplorationType(value);
  });

  const onRequestDataExploration = async (requestQuestion: string) => {
    setData(null);
    setQuestion('');
    setSubmittedQuestion(requestQuestion);

    await requestDataExploration({
      dataExplorationType: dataExplorationType.type,
      questions: [requestQuestion],
    });

    await queryClient.refetchQueries({
      queryKey: [EQueryConfigName.GET_DATA_EXPLORATION_PROMPTS, DEFAULT_DATA_EXPLORATION_PROMPTS_PARAMS],
    });
  };

  const isRequestDisabled = useMemo(() => {
    return (
      dataExplorationType === DEFAULT_DATA_EXPLORATION_TYPE ||
      !question.trim() ||
      isRequestingDataExploration ||
      isTypesLoading
    );
  }, [dataExplorationType, question, isRequestingDataExploration, isTypesLoading]);

  return isTypesLoading ? (
    <Skeleton variant="rounded" className="size-full h-14 rounded-[32px]" />
  ) : (
    <div className="flex w-full items-center rounded-[32px] border border-gray-300 bg-white">
      <DataTypeSelector
        dataTypes={data?.dataExplorationTypes || []}
        dataExplorationType={dataExplorationType}
        onTypeSelect={handleTypeSelect}
        isLoading={isTypesLoading}
      />
      <DataExplorationAutocomplete
        onRequestDataExploration={onRequestDataExploration}
        dataExplorationType={dataExplorationType}
        setQuestion={setQuestion}
        question={question}
      />
      <IconButton
        onClick={() => onRequestDataExploration(question)}
        disabled={isRequestDisabled}
        variant="transparent"
        className="mr-4"
        size="large"
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};
