import { FC, useMemo } from 'react';

import { Table } from '../../../components/table/Table';
import { TableColumn } from '../../../components/table/TableTypes';
import { Comparison } from '../../../swagger-types';

interface Props {
  company: Comparison;
  expected: Comparison;
  pdf?: boolean;
  aws?: boolean;
}

export const ComparisonsTable: FC<Props> = ({ company, expected, pdf }) => {
  const tableData = useMemo(
    () => [
      {
        label: 'Company',
        ...company,
      },
      {
        label: 'Expected',
        ...expected,
      },
    ],
    [company, expected]
  );

  const cols: TableColumn<Comparison & { label: string }>[] = [
    {
      headerName: '',
      field: 'label',
      align: 'center',
      render: ({ label }) => <b>{label}</b>,
    },
    {
      headerName: 'EMR',
      field: 'emr',
      align: 'center',
    },
    {
      headerName: 'TRIR',
      field: 'trir',
      align: 'center',
    },
    {
      headerName: 'DART',
      field: 'dart',
      align: 'center',
    },
  ];

  return <Table title="EXPECTED VS. ACTUALS:" cols={cols} tableData={tableData} pdf={pdf} hidePageSize />;
};
