import { lazy } from 'react';
import { Routes } from '@app/constants/routes';

import { StiModule } from './StiModule';
import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';
import { TypedNavigate } from '@app/router';
import { HAS_ACCESS_TO_STI } from '@app/environment/typed-env';

const StiPage = lazy(() => import('./pages/StiPage'));
const FullAssessmentResultPDFPage = lazy(() => import('./pdf/FullAssessmentResultPDFPage'));
const AwsResultPDFPage = lazy(() => import('./pdf/AwsResultPDFPage'));

export const StiRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.sti.index,
  element: <StiModule />,
  disabled: !HAS_ACCESS_TO_STI,
  children: [
    { index: true, element: <StiPage /> },
    { path: Routes.sti.fullCalcPdf, element: <FullAssessmentResultPDFPage /> },
    { path: Routes.sti.awsPdf, element: <AwsResultPDFPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.sti.index} /> },
  ],
});
