import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';
import { Outlet } from 'react-router-dom';
import { DashboardPage } from './DashboardPage';
import { TypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

export const DashboardRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.dashboard,
  element: <Outlet />,
  children: [
    { index: true, element: <DashboardPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.dashboard} /> },
  ],
});
