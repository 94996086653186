import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Routes } from '@app/modules/Routes';
import { DEFAULT_QUERY_STALE_TIME } from './constants/query-config.const';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  },
});

const App: FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
      <ToastContainer hideProgressBar closeButton={false} />
    </>
  );
};

export default App;
