import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { FC, ReactNode } from 'react';

import { TypedLink } from '@app/router';

export type Crumb = {
  to?: string;
  icon?: ReactNode;
  label: string;
  queryParams?: string | Record<string, string>;
};

interface Props {
  crumbs: Crumb[];
}

export const Breadcrumbs: FC<Props> = ({ crumbs }) => {
  return (
    <MuiBreadcrumbs className="text-xs text-black/60">
      {crumbs.map((c, i) => {
        if (c.to) {
          return (
            <TypedLink
              key={i}
              to={c.to}
              queryParams={c.queryParams}
              className="flex items-center gap-2 hover:text-secondary"
            >
              {c.icon}
              <span>{c.label}</span>
            </TypedLink>
          );
        }

        return (
          <div key={i} className="flex items-center gap-2 text-secondary">
            {c.icon}
            <span>{c.label}</span>
          </div>
        );
      })}
    </MuiBreadcrumbs>
  );
};
