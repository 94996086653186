import { ETrendExpected } from '../../../swagger-types';

export const renderTrendDetectedBgColor = (trend: ETrendExpected) => {
  switch (trend) {
    case ETrendExpected.STEADILY_DECREASING:
      return '#385723';
    case ETrendExpected.MIXED_BUT_INCREASING:
    case ETrendExpected.STEADILY_INCREASING:
      return '#C00000';
    case ETrendExpected.FLAT:
      return '#D9D9D9';
    case ETrendExpected.MIXED:
      return '#E6E600';
    case ETrendExpected.OTHER:
    default:
      return '#e2dfdf';
  }
};

export const renderTrendDetectedTextColor = (trend: ETrendExpected) => {
  switch (trend) {
    case ETrendExpected.STEADILY_DECREASING:
    case ETrendExpected.MIXED_BUT_INCREASING:
    case ETrendExpected.STEADILY_INCREASING:
      return '#ffffff';
    case ETrendExpected.FLAT:
    case ETrendExpected.MIXED:
    case ETrendExpected.OTHER:
    default:
      return '#1F2937';
  }
};

export const renderTrendDetectedLabel = (trend: ETrendExpected) => {
  switch (trend) {
    case ETrendExpected.STEADILY_DECREASING:
      return 'STEADILY DECREASING';
    case ETrendExpected.MIXED_BUT_INCREASING:
      return 'MIXED BUT INCREASING';
    case ETrendExpected.STEADILY_INCREASING:
      return 'STEADILY INCREASING';
    case ETrendExpected.FLAT:
      return 'FLAT';
    case ETrendExpected.MIXED:
      return 'MIXED';
    case ETrendExpected.OTHER:
      return 'OTHER';
    default:
      return 'UNKNOWN';
  }
};
