import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { Outlet } from 'react-router-dom';

export const UIKitModule: React.FC = () => {
  return (
    <div>
      <div className="flex gap-4 p-4">
        <h3>UIKit</h3>
        {/* TODO fix navigation - cannot go back to UI Kit after navigating to homepage */}
        <TypedLink
          to={Routes.index}
          className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          Go Home Page
        </TypedLink>
      </div>
      <div className="main-container">
        <Outlet />
      </div>
    </div>
  );
};
