import { EUserConfirmationStatus, EUserStatus } from '@app/swagger-types';
import { z } from 'zod';

export const UserOutDtoSchema = z.object({
  loginName: z.string(),
  username: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  company: z.string(),
  email: z.string(),
  emailVerified: z.boolean(),
  enabled: z.boolean(),
  userCreateDate: z.string(),
  userLastModifiedDate: z.string(),
  poolName: z.string(),
  groupList: z.array(z.string()),
  userConfirmationStatus: z.nativeEnum(EUserConfirmationStatus),
  userStatus: z.nativeEnum(EUserStatus),
});

export const UserCompactOutDtoSchema = z.object({
  username: z.string(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  company: z.string().nullable().optional(),
});

export const UsersOutDtoSchema = z.object({
  users: z.array(UserOutDtoSchema),
  last: z.boolean(),
});

export const UsersCompactOutDtoSchema = z.array(UserCompactOutDtoSchema);

export const UserPoolNameOutDtoSchema = z.object({
  userPoolNameList: z.array(z.string()),
});

export const UserPoolGroupOutDtoSchema = z.object({
  groupList: z.array(z.string()),
});

export const UserStatusOutDtoSchema = z.object({
  statuses: z.array(z.nativeEnum(EUserStatus)),
});

export const UserConfirmationStatusOutDtoSchema = z.object({
  statuses: z.array(z.nativeEnum(EUserConfirmationStatus)),
});
