import { FC, memo } from 'react';
import { Typography } from '@mui/material';
import { ClaimOutDto } from '@app/swagger-types';

interface Props {
  claim: ClaimOutDto;
}

export const AdjusterInfoBlock: FC<Props> = memo(({ claim: { adjuster, adjusterEmail, adjusterPhone } }) => {
  return (
    <div className="flex flex-col gap-6 rounded bg-white p-8 shadow-card">
      <div className="flex items-center gap-3">
        <Typography className="text-l font-semibold text-gray-800">{adjuster}</Typography>
        <Typography className="rounded-2xl bg-gray-800 px-4 py-0.5 text-xs font-semibold text-white">
          Adjuster
        </Typography>
      </div>
      <div className="grid grid-cols-5 gap-6">
        <div className="col-span-2 flex flex-col gap-2">
          <Typography className="text-xs font-semibold text-gray-800">Phone Number</Typography>
          <Typography className="text-s text-gray-800">{adjusterPhone}</Typography>
        </div>
        <div className="col-span-3 flex flex-col gap-2">
          <Typography className="text-xs font-semibold text-gray-800">Email</Typography>
          <Typography className="text-s text-gray-800 underline">{adjusterEmail}</Typography>
        </div>
      </div>
    </div>
  );
});
