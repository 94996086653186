import { useMemo, useCallback } from 'react';

import { EAuthStatus } from '@app/auth/api/auth.type';
import { AuthUser } from '@app/auth/api/auth.dto';

import { useStore } from '@app/store/useStore.hook';

import { PUBLIC_ROUTES } from '@app/constants/public-routes.const';
import { TypedRouteObject } from '../types';
import { ERole } from '@app/auth-types';

export const useFilteredRoutes = <TAuthStatus extends EAuthStatus, TRole extends ERole[]>(
  routes: TypedRouteObject<TAuthStatus, TRole>[]
): TypedRouteObject<TAuthStatus, TRole>[] => {
  const { currentUser, authStatus } = useStore(({ currentUser, authStatus }) => ({
    currentUser,
    authStatus,
  }));

  const guardCheck = useCallback(
    <R extends TypedRouteObject<TAuthStatus, TRole>>({
      route,
      authStatus,
      currentUser,
    }: {
      route: R;
      currentUser: AuthUser | null;
      authStatus: EAuthStatus;
    }) => {
      // DISABLED
      if (route.disabled) {
        return false;
      }

      // ROLE GUARD
      if (route.role) {
        if (!currentUser) {
          return false;
        }

        return route.role.some((role) => currentUser.groups.includes(role));
      }

      // Public routes
      if (route.path && PUBLIC_ROUTES.includes(route.path)) {
        return true;
      }

      // AUTH STATUS GUARD
      if (route.authStatus && route.authStatus !== authStatus) {
        return false;
      }

      return true;
    },
    []
  );

  const filteredRoutes = useMemo(() => {
    const deepFilter = (routeObjects: TypedRouteObject<TAuthStatus, TRole>[]) => {
      const filteredRoutes: TypedRouteObject<TAuthStatus, TRole>[] = routeObjects.reduce<
        TypedRouteObject<TAuthStatus, TRole>[]
      >((filteredRoutes, currentRoute) => {
        if (!guardCheck({ route: currentRoute, currentUser, authStatus })) {
          return filteredRoutes;
        }

        if (currentRoute.children) {
          return [...filteredRoutes, { ...currentRoute, children: deepFilter(currentRoute.children) }];
        }

        return [...filteredRoutes, currentRoute];
      }, []);

      return filteredRoutes;
    };

    return deepFilter(routes);
  }, [authStatus, currentUser, guardCheck, routes]);

  return filteredRoutes;
};
