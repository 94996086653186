import {
  AnyRawTask,
  ETaskStatus,
  IBaseCalculatedTask,
  ILeafTask,
  INodeTask,
  IRawLeafTask,
  IRawNodeTask,
  IRawRoadmapData,
  IRoadmapData,
  TimeTuple,
} from './roadmap.type';

export const addNumbers = (a: number, b: number) => a + b;
export const monoTime = (tuple: number[]): number => tuple.reduce(addNumbers, 0);

const typeKeyForRawLeaf: IRawLeafTask['type'] = 'rawLeaf';
export const guardForRawLeafTask = (task?: any): task is IRawLeafTask => task && task.type === typeKeyForRawLeaf;

const typeKeyForLeaf: ILeafTask['type'] = 'leaf';
export const guardForLeafTask = (task?: any): task is ILeafTask => task && task.type === typeKeyForLeaf;

export const addTuples = (first: TimeTuple, second: TimeTuple) => {
  const [a, b, c] = first;
  const [d, e, f] = second;
  return [a + d, b + e, c + f] as TimeTuple;
};

type TimeKeys = 'readyHours' | 'todoHours';
const calcSubtasksTotoal = (task: AnyRawTask, key: TimeKeys): TimeTuple => {
  if (guardForRawLeafTask(task)) {
    return task[key];
  }

  const subTotal = task.subtasks.reduce<TimeTuple>(
    (acc, task) => {
      const leaf = guardForRawLeafTask(task) && task;
      if (!leaf) {
        const subtotal = task.subtasks
          .map((sub) => calcSubtasksTotoal(sub, key))
          .reduce<TimeTuple>(addTuples, [0, 0, 0]);
        return addTuples(acc, subtotal);
      }
      return addTuples(acc, leaf[key]);
    },
    [0, 0, 0]
  );
  return subTotal;
};

const calcRawLeafTask = (rawTask: IRawLeafTask): ILeafTask => {
  const monoReadyHours = monoTime(rawTask.readyHours);
  const monoTodoHours = monoTime(rawTask.todoHours);
  const res: ILeafTask = {
    ...rawTask,
    type: 'leaf',
    monoReadyHours,
    monoTodoHours,
    status: calcTaskStatus({ monoReadyHours, monoTodoHours }),
  };
  return res;
};

const calcTaskStatus = (task: Pick<IBaseCalculatedTask, 'monoTodoHours' | 'monoReadyHours'>): ETaskStatus => {
  const { monoTodoHours, monoReadyHours } = task;
  if (monoTodoHours && monoReadyHours) {
    return ETaskStatus.IN_PROGRESS;
  }
  if (monoReadyHours) {
    return ETaskStatus.DONE;
  }
  if (monoTodoHours) {
    return ETaskStatus.NOT_STARTED;
  }
  return ETaskStatus.UNKNOWN_ESTIMATE;
};

const calcCountOfLeafTasks = (task: AnyRawTask): number => {
  if (task.type === 'rawLeaf') {
    return 1;
  }
  return task.subtasks.map(calcCountOfLeafTasks).reduce(addNumbers);
};

const checkSomeLeafHasUknownEstimate = (task: AnyRawTask): boolean => {
  if (task.type === 'rawLeaf') {
    return !monoTime(task.readyHours) && !monoTime(task.todoHours);
  }
  return task.subtasks.some(checkSomeLeafHasUknownEstimate);
};

const calcRawNodeTaskRecursively = (rawTask: IRawNodeTask, isRoot?: boolean): INodeTask => {
  const readyHours = calcSubtasksTotoal(rawTask, 'readyHours');
  const todoHours = calcSubtasksTotoal(rawTask, 'todoHours');
  const monoReadyHours = monoTime(readyHours);
  const monoTodoHours = monoTime(todoHours);

  const someLeafHasUnknownEstimate = checkSomeLeafHasUknownEstimate(rawTask);

  const task: INodeTask = {
    ...rawTask,
    type: 'node',
    isRootTask: Boolean(isRoot),
    readyHours,
    todoHours,
    monoReadyHours,
    monoTodoHours,
    countOfLeafTasks: calcCountOfLeafTasks(rawTask),
    someLeafHasUnknownEstimate,
    status: calcTaskStatus({ monoReadyHours, monoTodoHours }),
    subtasks: rawTask.subtasks.map((t) => {
      if (guardForRawLeafTask(t)) {
        return calcRawLeafTask(t);
      }
      return calcRawNodeTaskRecursively(t);
    }),
  };
  return task;
};

export const calcRoadmap = (rawRoadmap: IRawRoadmapData): IRoadmapData => {
  const checklist = rawRoadmap.checklist.map((t) => calcRawNodeTaskRecursively(t, true));
  const readyHours = checklist.reduce<TimeTuple>((acc, t) => addTuples(acc, t.readyHours), [0, 0, 0]);
  const todoHours = checklist.reduce<TimeTuple>((acc, t) => addTuples(acc, t.todoHours), [0, 0, 0]);
  const monoReadyHours = monoTime(readyHours);
  const monoTodoHours = monoTime(todoHours);
  const countOfLeafTasks = checklist.map((t) => t.countOfLeafTasks).reduce(addNumbers, 0);

  const res: IRoadmapData = {
    checklist,
    readyHours,
    todoHours,
    monoReadyHours,
    monoTodoHours,
    countOfLeafTasks,
  };
  return res;
};
