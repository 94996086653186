import { FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

import { useMousePosition } from '@app/hooks/useMousePosition.hook';
import { DataExplorationPlotOutDto } from '@app/swagger-types';

interface Props {
  data: DataExplorationPlotOutDto[];
}

export const DataExplorationBarChart: FC<Props> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [tooltipContent, setTooltipContent] = useState<string | null>(null);

  const { x, y } = useMousePosition();

  useEffect(() => {
    const parsedData = data.map((d) => ({
      keyValue: d.keyValue ?? '',
      dataValue: parseFloat(d.dataValue ?? '0') || 0,
    }));

    // Remove any existing SVG content
    const svgElement = d3.select(svgRef.current);
    svgElement.selectAll('*').remove();

    // Get the container's width
    const containerWidth = svgElement.node()?.parentElement?.getBoundingClientRect().width || 800;

    // Set up the SVG canvas dimensions
    const width = containerWidth;
    const height = 600;
    const margin = { top: 20, right: 120, bottom: 180, left: 120 };

    // Create the SVG element
    const svg = svgElement
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMinYMid meet')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Define the scales
    const xScale = d3
      .scaleBand()
      .domain(parsedData.map((d) => d.keyValue))
      .range([0, width - margin.left - margin.right])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(parsedData, (d) => d.dataValue) ?? 0])
      .nice()
      .range([height - margin.top - margin.bottom, 0]);

    // Add the X axis with reduced labels
    svg
      .append('g')
      .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
      .call(
        d3.axisBottom(xScale).tickValues(
          parsedData.length > 30
            ? xScale.domain().filter((_, i) => i % 2 === 0) // Filter every second label if data length > 30
            : xScale.domain() // Otherwise, keep all labels
        )
      )
      .selectAll('text')
      .attr('font-size', '10px')
      .attr('transform', parsedData.length > 10 ? 'rotate(-55)' : 'rotate(0)')
      .style('text-anchor', 'end');

    // Add the Y axis
    svg.append('g').call(d3.axisLeft(yScale));

    // Add the bars with tooltip interactions
    svg
      .selectAll('.bar')
      .data(parsedData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => xScale(d.keyValue) ?? 0)
      .attr('y', (d) => yScale(d.dataValue ?? 0))
      .attr('width', xScale.bandwidth())
      .attr('height', (d) => height - margin.top - margin.bottom - yScale(d.dataValue))
      .attr('fill', 'steelblue')
      .on('mouseover', (event, d) => setTooltipContent(`${d.keyValue}: ${d.dataValue}`))
      .on('mouseout', () => setTooltipContent(null));
  }, [data]);

  if (!data.length) {
    return null;
  }

  return (
    <>
      <svg ref={svgRef} style={{ width: '100%', height: 'auto' }} />
      {tooltipContent && (
        <div
          className="pointer-events-none fixed z-50 rounded bg-secondary px-1 py-2 text-white"
          style={{ top: y + 10, left: x + 10 }}
        >
          {tooltipContent}
        </div>
      )}
    </>
  );
};
