import { Ref, MutableRefObject } from 'react';

// Helper function to merge refs
export const mergeRefs =
  <T>(...refs: Ref<T>[]) =>
  (element: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        (ref as MutableRefObject<T>).current = element;
      }
    });
  };
