import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  PageResponseDataExplorationPromptOutDto,
  DataExplorationTypesOutDto,
  DataExplorationOutDto,
  DataExplorationInDto,
} from '@app/swagger-types';
import { EQueryConfigName } from '@app/constants/query-config.const';
import { DataExplorationApi } from './data-exploration.api';
import { ServerError } from '@app/swagger-override-types';

interface GetDataExplorationPromptsParams {
  page: number;
  size: number;
  sort: string;
}

export const useRequestDataExplorationMutation = (
  options?: UseMutationOptions<DataExplorationOutDto, ServerError, DataExplorationInDto>
) => {
  return useMutation({
    ...options,
    mutationFn: async (Dto) => await DataExplorationApi.requestDataExploration(Dto),
  });
};

export const useGetDataExplorationTypes = (
  options?: UseQueryOptions<
    unknown,
    ServerError,
    DataExplorationTypesOutDto,
    (typeof EQueryConfigName.GET_DATA_EXPLORATION_TYPES)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_DATA_EXPLORATION_TYPES],
    queryFn: async () => await DataExplorationApi.getDataExplorationTypes(),
  });
};

export const useGetDataExplorationPrompts = (
  { page, size, sort }: GetDataExplorationPromptsParams,
  options?: UseQueryOptions<
    unknown,
    ServerError,
    PageResponseDataExplorationPromptOutDto,
    (typeof EQueryConfigName.GET_DATA_EXPLORATION_PROMPTS | GetDataExplorationPromptsParams)[]
  >
) => {
  return useQuery({
    ...options,
    queryKey: [EQueryConfigName.GET_DATA_EXPLORATION_PROMPTS, { page, size, sort }],
    queryFn: async () => await DataExplorationApi.getDataExplorationPrompts(page, size, sort),
  });
};

export const useSendBadResponseMutation = (options?: UseMutationOptions<AxiosResponse, ServerError, string>) => {
  return useMutation({
    ...options,
    mutationFn: async (logId) => await DataExplorationApi.sendBadResponse(logId),
  });
};
