import { apiService, validateAndAssert } from '@app/services/api-service';
import {
  AwsCalcOutDtoResSchema,
  FullCalcOutDtoResponseSchema,
  NaicsOutDtoSchema,
  NCCIOutDtoSchema,
  StatesOutDtoSchema,
  VersionsOutDtoSchema,
} from '../schemes/sti.schemes';
import { NaicsOutDto, NcciOutDto, StateOutDto, SubmissionGiCalcOutDto, VersionOutDto } from '@app/swagger-types';
import { pageableData } from '@app/utils/zod.utils';
import { PaginatedResponse, RequestParams } from '@app/api/api.type';
import {
  AwsCalcInDto,
  AwsCalcOutDtoResponse,
  CalcResponseState,
  FullCalcInDto,
  FullCalcOutDtoResponse,
} from '../types/calc.types';
import { SubmissionGiCalcOutDtoSchema } from '@app/domain/submission/schemas/submission.schema';

export enum ENcciType {
  FULL = 'FULL',
  AWS = 'AWS',
}

export interface NcciRequestParams extends RequestParams {
  type?: ENcciType;
}

export interface GetVersionsParams {
  isV3?: boolean;
}

export interface StiParams {
  version?: string;
  originalCrDb?: number;
  overrideCrDb?: number;
  originalStates?: CalcResponseState[];
}

export class StiApi {
  static getVersionsList(params: GetVersionsParams) {
    return validateAndAssert(apiService.get<VersionOutDto>('/private/list-versions', { params }), VersionsOutDtoSchema);
  }
  static getStates() {
    return validateAndAssert(apiService.get<StateOutDto[]>('/private/states'), StatesOutDtoSchema);
  }
  static getNCCIs(params: RequestParams) {
    return validateAndAssert(
      apiService.get<PaginatedResponse<NcciOutDto>>(`/private/nccis`, { params }),
      pageableData(NCCIOutDtoSchema)
    );
  }
  static getNaics() {
    return validateAndAssert(
      apiService.get<PaginatedResponse<NaicsOutDto>>(`/private/naics`),
      pageableData(NaicsOutDtoSchema)
    );
  }
  static calculateByAws(Dto: AwsCalcInDto, version?: string) {
    return validateAndAssert(
      apiService.post<AwsCalcOutDtoResponse, AwsCalcInDto>(`/private/aws_calc`, Dto, { params: { version } }),
      AwsCalcOutDtoResSchema
    );
  }
  static calculateBySti(Dto: FullCalcInDto, { originalStates, ...params }: StiParams = {}) {
    return validateAndAssert(
      apiService.post<FullCalcOutDtoResponse, FullCalcInDto>(
        '/private/full_calc',
        { ...Dto, originalStates },
        { params }
      ),
      FullCalcOutDtoResponseSchema
    );
  }

  static getCalcBySTIResponseOutcome(responseId: number) {
    return validateAndAssert(
      apiService.get<FullCalcOutDtoResponse>(`/private/outcome/${responseId}`),
      FullCalcOutDtoResponseSchema
    );
  }
  static getCalcByAwsResOutcome(responseId: number) {
    return validateAndAssert(
      apiService.get<AwsCalcOutDtoResponse>(`/private/outcome/${responseId}`),
      AwsCalcOutDtoResSchema
    );
  }
  static getCalcByGiResOutcome(responseId: string) {
    return validateAndAssert(
      apiService.get<SubmissionGiCalcOutDto>(`/private/outcome/${responseId}`),
      SubmissionGiCalcOutDtoSchema
    );
  }

  static exportFullCalcCSV(calcDto: FullCalcInDto, version?: string) {
    return apiService.post<string, FullCalcInDto>(`/private/calc-result/export_full`, calcDto, { params: { version } });
  }
}
