import { OverrideLossRatioFilter } from '@app/swagger-override-types';
import { DEFAULT_LOSS_RATIO_FILTER_VALUES } from '../const/loss-ratio.filter.const';
import { useLocalStorageWithExpiry } from '@app/hooks/useLocalStorageWithExpiry';

const LOSS_RATIO_FILTERS_LOCAL_STORAGE_KEY = 'loss-ratio-filters';

export const useLossRatioFiltersLocalStorage = () =>
  useLocalStorageWithExpiry<OverrideLossRatioFilter>(
    LOSS_RATIO_FILTERS_LOCAL_STORAGE_KEY,
    DEFAULT_LOSS_RATIO_FILTER_VALUES
  );
