import { toNumber } from 'lodash';

export const parseNumbersFromString = (s: string) => toNumber(s.replace(/[^\d.-]/g, ''));

export const firstCharacterToLowerCase = (inputString: string): string => {
  if (inputString.length === 0) {
    return inputString; // Return the original string if it's empty
  }
  return inputString[0].toLowerCase() + inputString.slice(1);
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const capitalizeWords = (str: string) => {
  const exceptions = new Set(['to', 'of']);

  return str
    .split(' ')
    .map((word) => (exceptions.has(word.toLowerCase()) ? word : capitalizeFirstLetter(word)))
    .join(' ');
};

export const filterOptionsBySearchValue = (options: string[], inputValue: string): string[] =>
  options.filter((option) => {
    const pattern = inputValue.toLowerCase().split('').join('.*');
    const regex = new RegExp(pattern);

    return regex.test(String(option).toLowerCase());
  });
