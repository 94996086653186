import { DataGridPro, GridApiPro, GridColDef } from '@mui/x-data-grid-pro';
import { FC, MutableRefObject, useMemo } from 'react';
import { Skeleton } from '@mui/material';

import { DataExplorationGridOutDto } from '@app/swagger-types';

interface Props {
  gridData: DataExplorationGridOutDto[] | null;
  isLoading: boolean;
  apiRef: MutableRefObject<GridApiPro>;
}

export const DataExplorationTable: FC<Props> = ({ gridData, isLoading, apiRef }) => {
  const columns: GridColDef[] = useMemo(() => {
    if (!gridData || gridData.length === 0) {
      return [];
    }

    const uniqueColumns = [
      ...Array.from(new Set(gridData.map((item) => item.keyName))).filter(Boolean),
      ...Array.from(new Set(gridData.map((item) => item.dataName))).filter(Boolean),
    ] as string[];

    return uniqueColumns.map((columnName) => ({
      field: columnName,
      headerName: columnName,
      flex: 1,
    }));
  }, [gridData]);

  const rows = useMemo(() => {
    if (!gridData || gridData.length === 0) {
      return [];
    }

    const rowMap: Record<number, Record<string, string | null>> = {};

    gridData.forEach((item) => {
      const id = item.id ?? 0;
      if (!rowMap[id]) {
        rowMap[id] = { id: id.toString() };
      }
      if (item.keyName && item.keyValue) {
        rowMap[id][item.keyName] = item.keyValue;
      }

      if (item.dataName && item.dataValue) {
        rowMap[id][item.dataName] = item.dataValue;
      }
    });

    return Object.values(rowMap);
  }, [gridData]);

  const getApiRef = () => {
    // Warning workaround with initial null value.
    if (apiRef.current === null) {
      // @ts-expect-error {} is the initial value set by useGridApiRef
      apiRef.current = {};
    }

    return apiRef;
  };

  return (
    <div className="max-h-[500px] min-h-[372px] w-full">
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={500} />
      ) : (
        <DataGridPro
          initialState={{ pagination: { paginationModel: { pageSize: 5, page: 0 } } }}
          pageSizeOptions={[5, 10, 20, 50]}
          className="max-h-[500px]"
          apiRef={getApiRef()}
          disableColumnMenu
          columns={columns}
          rows={rows}
          pagination
        />
      )}
    </div>
  );
};
