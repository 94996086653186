import { FC } from 'react';

export const DashboardPage: FC = () => {
  return (
    <div
      style={{ backgroundImage: 'url(/dashboard-bg.jpg)' }}
      className="flex size-full flex-col items-center justify-center bg-cover bg-right"
    />
  );
};
