import { ERole } from '@app/auth-types';

export const GROUPS_WITH_ACCESS_TO_STI = [ERole.ALL_ADMINISTRATOR, ERole.STIAPP_FULL_ACCESS, ERole.STI_VERSIONS_MENU];

export const GROUPS_WITH_ACCESS_TO_STI_VERSIONS = [ERole.ALL_ADMINISTRATOR, ERole.STI_VERSIONS_MENU];

export const GROUPS_WITH_ACCESS_TO_SUBMISSIONS = [ERole.ALL_ADMINISTRATOR, ERole.SUBMISSIONS];

export const GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS = [ERole.ALL_ADMINISTRATOR, ERole.ALTERNATIVE_RESULTS];

export const GROUPS_WITH_ACCESS_TO_SUBMISSION_INSIGHTS = [ERole.ALL_ADMINISTRATOR, ERole.SUBMISSION_INSIGHTS];
