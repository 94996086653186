import { CircularProgress } from '@app/components/CircularProgress';
import styles from './TableLoader.module.scss';

export const TableLoader = () => {
  return (
    <div className={styles.OverlayLoader}>
      <CircularProgress size={35} />
    </div>
  );
};
