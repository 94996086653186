import { FC } from 'react';
import { useMount } from 'react-use';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useFilteredRoutes } from '@app/router';
import { EAuthStatus } from '@app/auth/api/auth.type';
import { useStore } from '@app/store/useStore.hook';

import { RootRoutes } from './RootRoutes';

const fallbackElement = (
  <div className="flex size-full items-center justify-center">
    <CircularProgress className="text-primary" />
  </div>
);

export const Routes: FC = () => {
  const { getMe, authStatus } = useStore(({ currentUser, getMe, authStatus }) => ({
    currentUser,
    getMe,
    authStatus,
  }));
  const routes = useFilteredRoutes(RootRoutes);

  const router = createBrowserRouter(routes);

  useMount(() => {
    getMe();
  });

  if (authStatus === EAuthStatus.UNKNOWN) {
    return fallbackElement;
  }

  return <RouterProvider router={router} fallbackElement={fallbackElement} />;
};
