import {
  QueryParamConfig,
  withDefault,
  ArrayParam as DefaultArrayParam,
  BooleanParam as DefaultBooleanParam,
  StringParam as DefaultStringParam,
  NumberParam as DefaultNumberParam,
  createEnumParam,
} from 'use-query-params';

import _flow from 'lodash/fp/flow';
import _isEqual from 'lodash/fp/isEqual';

export function withSquashedDefault<T, Default extends NonNullable<T>>(
  paramConfig: QueryParamConfig<T>,
  defaultValue: Default
): QueryParamConfig<T, Default | Exclude<T, null | undefined>> {
  const defaulted = withDefault(paramConfig, defaultValue);

  return {
    encode: _flow((value) => (_isEqual(value, defaultValue) ? undefined : value), defaulted.encode),
    decode: defaulted.decode,
  };
}

export const OrderParam = createEnumParam(['asc', 'desc']);

export const ArrayParam = withDefault(DefaultArrayParam, undefined) as QueryParamConfig<string[] | undefined>;
export const BooleanParam = withDefault(DefaultBooleanParam, undefined) as QueryParamConfig<boolean | undefined>;
export const StringParam = withDefault(DefaultStringParam, undefined) as QueryParamConfig<string | undefined>;
export const NumberParam = withDefault(DefaultNumberParam, undefined) as QueryParamConfig<number | undefined>;
