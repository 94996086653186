import { IRawRoadmapData } from './roadmap.type';
import { calcRoadmap } from './roadmap.util';

const rawRoadmapData: IRawRoadmapData = {
  checklist: [
    {
      name: 'Only non-authorized users',
      type: 'rawNode',
      subtasks: [
        {
          name: 'Sign up',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Via email and password',
              type: 'rawLeaf',
              readyHours: [6, 6, 8],
              todoHours: [0, 0, 0],
            },
            {
              name: 'Verify email with OTP code',
              type: 'rawLeaf',
              readyHours: [2, 2, 2],
              todoHours: [0, 2, 0],
            },
          ],
        },
        {
          name: 'Sign in',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Via email and password',
              type: 'rawLeaf',
              readyHours: [4, 4, 4],
              todoHours: [0, 0, 0],
            },
            {
              name: '2FA',
              type: 'rawNode',
              subtasks: [
                {
                  name: 'Via OTP',
                  type: 'rawLeaf',
                  readyHours: [4, 2, 4],
                  todoHours: [0, 2, 0],
                },
                {
                  name: 'Allow code "000000" - for DEV purposes',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [2, 0, 0],
                },
              ],
            },
          ],
        },
        {
          name: 'Sign out',
          type: 'rawLeaf',
          readyHours: [1, 2, 1],
          todoHours: [0, 0, 0],
        },
        {
          name: 'Forgot password',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Email with OTP code',
              type: 'rawLeaf',
              readyHours: [4, 4, 3],
              todoHours: [0, 2, 0],
            },
            {
              name: 'Reset password',
              type: 'rawLeaf',
              readyHours: [2, 2, 2],
              todoHours: [0, 2, 0],
            },
          ],
        },
        {
          name: 'Different ways for "Sign up, sign in and forgot password"',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Via dedicated page',
              type: 'rawLeaf',
              readyHours: [0, 6, 8],
              todoHours: [0, 0, 0],
            },
            {
              name: 'Via modal window',
              type: 'rawLeaf',
              readyHours: [0, 0, 4],
              todoHours: [0, 6, 4],
            },
          ],
        },
        {
          name: 'Auth approach',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Via Token',
              type: 'rawLeaf',
              readyHours: [6, 6, 0],
              todoHours: [0, 0, 0],
            },
            {
              name: 'Via Cookie',
              type: 'rawLeaf',
              readyHours: [4, 4, 0],
              todoHours: [0, 2, 0],
            },
          ],
        },
      ],
    },
    {
      name: 'Any authorized user',
      type: 'rawNode',
      subtasks: [
        {
          name: 'Profile',
          type: 'rawNode',
          subtasks: [
            {
              name: 'See and edit informational fields',
              type: 'rawNode',
              subtasks: [
                {
                  name: 'Avatar (thumbnail and full size in modal on click)',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 4],
                  todoHours: [3, 6, 0],
                },
                {
                  name: 'First name, Last name',
                  type: 'rawLeaf',
                  readyHours: [1, 0, 1],
                  todoHours: [0, 1, 0],
                },
                {
                  name: 'Phone (may be empty)',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 1],
                  todoHours: [0, 1, 0],
                },
                {
                  name: 'Address (may be empty)',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 1],
                  todoHours: [0, 1, 0],
                },
                {
                  name: 'Birth date (may be empty)',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 1],
                  todoHours: [0, 4, 0],
                },
              ],
            },
            {
              name: 'Read-only: email address',
              type: 'rawLeaf',
              readyHours: [0, 0, 1],
              todoHours: [0, 1, 0],
            },
          ],
        },
        {
          name: 'My Signature',
          type: 'rawLeaf',
          readyHours: [0, 0, 0],
          todoHours: [6, 8, 6],
        },
        {
          name: 'Settings',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Security',
              type: 'rawNode',
              subtasks: [
                {
                  name: '2FA via email OTP (toggle)',
                  type: 'rawLeaf',
                  readyHours: [2, 2, 3],
                  todoHours: [0, 3, 0],
                },
                {
                  name: 'Change email',
                  type: 'rawLeaf',
                  readyHours: [4, 0, 3],
                  todoHours: [0, 3, 0],
                },
                {
                  name: 'Change password',
                  type: 'rawLeaf',
                  readyHours: [2, 0, 3],
                  todoHours: [0, 2, 0],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Super Admin role',
      type: 'rawNode',
      subtasks: [
        {
          name: 'User Management',
          type: 'rawNode',
          subtasks: [
            {
              name: 'See all users (all roles) in a table',
              type: 'rawLeaf',
              readyHours: [4, 3, 4],
              todoHours: [0, 4, 0],
            },
            {
              name: 'Search user by name or email',
              type: 'rawLeaf',
              readyHours: [4, 0, 4],
              todoHours: [0, 4, 0],
            },
            {
              name: 'See and edit user profile',
              type: 'rawNode',
              subtasks: [
                {
                  name: 'See and edit same data as user itself',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 6, 2],
                },
                {
                  name: 'See additional info',
                  type: 'rawNode',
                  subtasks: [
                    {
                      name: 'Sign up date',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [1, 1, 1],
                    },
                    {
                      name: 'Last active time (most recent active date of all sessions)',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [2, 1, 1],
                    },
                    {
                      name: 'Stripe customer id',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [1, 1, 1],
                    },
                    {
                      name: 'Stripe subscription',
                      type: 'rawNode',
                      subtasks: [
                        {
                          name: 'Show subscription name, id',
                          type: 'rawLeaf',
                          readyHours: [0, 0, 0],
                          todoHours: [1, 1, 1],
                        },
                        {
                          name: 'Status of subscription',
                          type: 'rawNode',
                          subtasks: [
                            {
                              name: 'Active',
                              type: 'rawLeaf',
                              readyHours: [0, 0, 0],
                              todoHours: [1, 1, 1],
                            },
                            {
                              name: 'Or canceled but not expired yet',
                              type: 'rawLeaf',
                              readyHours: [0, 0, 0],
                              todoHours: [1, 1, 1],
                            },
                            {
                              name: 'Or expired',
                              type: 'rawLeaf',
                              readyHours: [0, 0, 0],
                              todoHours: [1, 1, 1],
                            },
                            {
                              name: 'Or none',
                              type: 'rawLeaf',
                              readyHours: [0, 0, 0],
                              todoHours: [1, 1, 1],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'Ban user (so all user requests get rejected with 401 error)',
          type: 'rawLeaf',
          readyHours: [2, 0, 2],
          todoHours: [0, 3, 0],
        },
        {
          name: 'Delete user (so user can sign up again with same email)',
          type: 'rawLeaf',
          readyHours: [2, 0, 2],
          todoHours: [0, 3, 0],
        },
        {
          name: 'Invite user by email',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Indicate user role for invitation',
              type: 'rawLeaf',
              readyHours: [4, 0, 3],
              todoHours: [0, 2, 0],
            },
          ],
        },
        {
          name: 'Invite user via creating their account',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Provide all required info for account during invite',
              type: 'rawLeaf',
              readyHours: [0, 0, 0],
              todoHours: [4, 4, 4],
            },
            {
              name: 'Provide optional info for account during invite',
              type: 'rawLeaf',
              readyHours: [0, 0, 0],
              todoHours: [2, 2, 2],
            },
          ],
        },
      ],
    },
    {
      name: 'Customer role',
      type: 'rawNode',
      subtasks: [
        {
          name: 'Settings',
          type: 'rawNode',
          subtasks: [
            {
              name: 'Payments',
              type: 'rawNode',
              subtasks: [
                {
                  name: 'See list of saved payment cards',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 4, 2],
                },
                {
                  name: 'Add card',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 4, 2],
                },
                {
                  name: 'When first card added, it is auto-marked as default',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [1, 1, 1],
                },
                {
                  name: 'Select any card as default for payments',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [2, 2, 2],
                },
              ],
            },
            {
              name: 'My Subscription',
              type: 'rawNode',
              subtasks: [
                {
                  name: 'Status and name of active subscription',
                  type: 'rawNode',
                  subtasks: [
                    {
                      name: 'Active',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [1, 1, 1],
                    },
                    {
                      name: 'Or canceled but not expired yet',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [1, 1, 1],
                    },
                    {
                      name: 'Or expired',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [1, 1, 1],
                    },
                    {
                      name: 'Or none',
                      type: 'rawLeaf',
                      readyHours: [0, 0, 0],
                      todoHours: [1, 1, 1],
                    },
                  ],
                },
                {
                  name: 'List of available subscriptions',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [1, 1, 1],
                },
                {
                  name: 'Prorated upgrade anytime',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 4, 2],
                },
                {
                  name: 'Prorated downgrade anytime',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 4, 2],
                },
                {
                  name: 'Cancel anytime',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 4, 2],
                },
                {
                  name: 'Renew canceled before expire',
                  type: 'rawLeaf',
                  readyHours: [0, 0, 0],
                  todoHours: [4, 4, 2],
                },
              ],
            },
          ],
        },
      ],
    },
    // {
    //   name: 'Sample',
    //   type: 'rawNode',
    //   subtasks: [
    //     {
    //       name: 'Sample',
    //       type: 'rawLeaf',
    //       readyHours: [0, 0, 0],
    //       todoHours: [0, 0, 0],
    //     },
    //   ],
    // },
  ],
};

export const roadmapData = calcRoadmap(rawRoadmapData);
