import { useEffect, useState, useMemo } from 'react';
import { manager } from './dialog-manager';
import { OpenDialogEntityType } from './types';

export const DialogRenderComponent: React.VFC = () => {
  const [_value, handleChange] = useState<OpenDialogEntityType[]>([]);

  useEffect(() => {
    manager.on(handleChange);
    return () => manager.off(handleChange);
  }, [handleChange]);

  const renderDialogs = useMemo(() => {
    return _value?.map(({ component, name, isOpen, props }) => {
      const DialogComponent = component;
      return (
        <DialogComponent
          {...props}
          isOpen={isOpen}
          key={name}
          onClose={() => manager.close(name)}
          onCloseAll={manager.closeAll}
          onCloseOthers={() => manager.closeOthers(name)}
        />
      );
    });
  }, [_value]);

  return <>{renderDialogs}</>;
};
