import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FC, MouseEvent, useState } from 'react';
import { Popover } from '@mui/material';

import { DEFAULT_DATA_EXPLORATION_TYPE } from '../const/data-exploration.const';
import { DataExplorationTypeOutDto } from '@app/swagger-types';
import { clsxm } from '@app/styles/clsxm';

interface DataTypeSelectorProps {
  dataExplorationType: DataExplorationTypeOutDto;
  dataTypes: DataExplorationTypeOutDto[];
  onTypeSelect: (type: DataExplorationTypeOutDto) => void;
  isLoading: boolean;
}

export const DataTypeSelector: FC<DataTypeSelectorProps> = ({
  dataExplorationType,
  onTypeSelect,
  dataTypes,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  if (isLoading) {
    return null;
  }

  return (
    <div className="relative size-full max-w-[240px] gap-2 border-r border-r-gray-300">
      <button className="ml-2 flex size-full items-center justify-between gap-2 p-3" onClick={handleOpen}>
        <p className="font-semibold">
          Explore: {dataExplorationType.type} {dataExplorationType !== DEFAULT_DATA_EXPLORATION_TYPE && 'Data'}
        </p>
        <ArrowDropDownIcon />
      </button>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <div className="flex flex-col gap-4 py-4">
          {dataTypes.map((item) => (
            <button
              key={item.type}
              onClick={() => {
                onTypeSelect(item);
                handleClose();
              }}
              className={clsxm(
                'h-7 w-full cursor-pointer px-4 text-left hover:bg-gray-100',
                dataExplorationType.type === item.type && 'bg-gray-200'
              )}
            >
              {item.type} Data
            </button>
          ))}
        </div>
      </Popover>
    </div>
  );
};
