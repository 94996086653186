import { forwardRef } from 'react';

export type FileUploadProps = {
  id?: string;
  accept?: string;
  multiple?: boolean;
  onChange?: (data: File) => void;
  onMultipleChange?: (data: File[]) => void;
  onNotAllowed?: (file?: File) => void;
  allowedTypes?: string[];
  disabled?: boolean;
};

export const FileUploadInput = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ id, accept, multiple, allowedTypes, onNotAllowed, onChange, onMultipleChange, disabled }, ref) => {
    const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (!files || !Boolean(files.length)) {
        return;
      }
      if (!multiple && onChange) {
        const file = files[0];
        if (file) {
          if (allowedTypes && allowedTypes.indexOf(file.type) === -1) {
            if (onNotAllowed) {
              onNotAllowed(file);
            }
            return;
          }
          return onChange(file);
        }
      }
      if (onMultipleChange) {
        const fileArray = Array.from(files);
        for (let i = 0; i < fileArray.length; i++) {
          if (allowedTypes && allowedTypes.indexOf(fileArray[i]?.type || '') === -1) {
            if (onNotAllowed) {
              onNotAllowed(fileArray[i]);
            }
            return;
          }
        }
        onMultipleChange(fileArray);
      }
    };

    return (
      <input
        multiple={multiple}
        disabled={disabled}
        id={id ?? 'upload-input'}
        ref={ref}
        onChange={onChangeHandler}
        type="file"
        alt="Upload file"
        style={{ display: 'none' }}
        accept={accept}
      />
    );
  }
);
