import { DEFAULT_LOSS_RATIO_FILTER_VALUES } from '../const/loss-ratio.filter.const';
import { OverrideLossRatioFilter } from '@app/swagger-override-types';
import { useLocalStorageWithExpiry } from '@app/hooks/useLocalStorageWithExpiry';

export const SHARED_LOSS_RATIO_FILTERS_LOCAL_STORAGE_KEY = 'shared-loss-ratio-filters';

export const useSharedLossRatioFiltersLocalStorage = () =>
  useLocalStorageWithExpiry<OverrideLossRatioFilter>(
    SHARED_LOSS_RATIO_FILTERS_LOCAL_STORAGE_KEY,
    DEFAULT_LOSS_RATIO_FILTER_VALUES
  );
