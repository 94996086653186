import { memo, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import CloseIcon from '@mui/icons-material/Close';

import { Input, InputProps } from '../input/Input';
import { useUncontrolled } from '@app/hooks/useUncontrolled.hook';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import { SearchIcon } from '@app/assets';

export interface SearchInputProps extends Omit<InputProps, 'onChange' | 'size'> {
  debounce?: number;
  className?: string;
  iconClassName?: string;
  onDebounce?: (text: string) => void;
  onChange?: (text: string) => void;
}

export const SearchInput: FC<SearchInputProps> = memo(
  ({ debounce = 300, className, iconClassName, value, defaultValue = '', onChange, onDebounce, ...props }) => {
    const [_value, handleChange] = useUncontrolled({
      value,
      defaultValue,
      rule: (val) => typeof val === 'string',
      onChange,
    });

    const debounced = useDebouncedCallback((value) => {
      onDebounce?.(value);
    }, debounce);

    const handleReset = useHandler(() => {
      handleChange('');
      onDebounce?.('');
    });

    return (
      <Input
        placeholder="Search"
        onChange={(e) => {
          handleChange(e.target.value);
          debounced(e.target.value);
        }}
        className={clsxm('group', className)}
        inputSize="small"
        startAdornment={
          <div className="flex max-h-[40px] max-w-[40px] items-center justify-start pl-1">
            <div
              className={clsxm('rounded-sm bg-white p-1 text-black', props.disabled && 'text-gray-500', iconClassName)}
            >
              <div>
                <SearchIcon className="size-6" />
              </div>
            </div>
          </div>
        }
        endAdornment={
          <div className="flex max-h-[40px] max-w-[40px] items-center justify-end">
            <div className={clsxm('rounded-sm p-1 text-gray-700', props.disabled && 'text-gray-500', iconClassName)}>
              {_value ? (
                <div onClick={handleReset} className="cursor-pointer" aria-hidden>
                  <CloseIcon className="invisible size-5 group-hover:visible" />
                </div>
              ) : null}
            </div>
          </div>
        }
        value={_value}
        {...props}
        classes={{ ...props.classes, input: clsxm('truncate pr-0', props.classes?.input) }}
      />
    );
  }
);
