import { DragIndicator } from '@mui/icons-material';
import { memo, FC } from 'react';

import { IconButtonProps, IconButton } from './icon-button/IconButton';
import { clsxm } from '@app/styles/clsxm';

type Props = Omit<IconButtonProps, 'children'> & {
  horizontal?: boolean;
};

export const DragButton: FC<Props> = memo<Props>(({ className, size = 'large', horizontal, ...props }) => {
  return (
    <IconButton size={size} {...props} className={clsxm('m-1.5', className)}>
      <DragIndicator className={clsxm(horizontal && 'rotate-90', className)} />
    </IconButton>
  );
});
