import { generatePath, Link, LinkProps } from 'react-router-dom';

import { CheckParamReq, ExtractParams } from '../types';

export interface TypedLinkProps<TPath extends string> extends Omit<LinkProps, 'to'> {
  to: TPath;
  params: ExtractParams<TPath>;
  queryParams?: string | Record<string, string>;
  stopPropagation?: boolean;
}

export function TypedLink<TPath extends string>({
  to,
  params,
  queryParams,
  stopPropagation,
  ...rest
}: CheckParamReq<TypedLinkProps<TPath>>) {
  return (
    <Link
      {...rest}
      to={{
        pathname: generatePath(to, params),
        search: typeof queryParams === 'string' ? queryParams : `?${new URLSearchParams(queryParams).toString()}`,
      }}
      {...(stopPropagation
        ? {
            onClick: (e) => {
              e.stopPropagation();
            },
          }
        : {})}
    />
  );
}
