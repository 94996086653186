import { ChangeEvent, FC, useMemo } from 'react';

import MUIPagination, { PaginationProps } from '@mui/material/Pagination';
import { DEFAULT_PAGE_SIZE } from '@app/constants/pagination.const';

export interface PaginationCustomProps
  extends Omit<PaginationProps, 'onChange' | 'page' | 'count' | 'size' | 'variant'> {
  currentPage: number;
  pageSize?: number;
  total?: number;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onPagination?: (newPage: number) => void;
}

export const Pagination: FC<PaginationCustomProps> = ({
  currentPage,
  pageSize = DEFAULT_PAGE_SIZE,
  total = 0,
  size = 'small',
  className,
  onPagination,
  ...rest
}) => {
  const pagesCount = useMemo(() => Math.ceil(total / pageSize), [pageSize, total]);

  return (
    <div className={className}>
      <MUIPagination
        page={currentPage}
        size={size}
        onChange={(event: ChangeEvent<unknown>, newPage: number) => {
          if (onPagination) {
            onPagination(newPage);
          }
        }}
        count={pagesCount}
        {...rest}
      />
    </div>
  );
};
