import AlignHorizontalLeft from '@mui/icons-material/AlignHorizontalLeft';
import { Card, Skeleton, Typography } from '@mui/material';
import EventNote from '@mui/icons-material/EventNote';
import { FC, useMemo, useState } from 'react';
import { isNumber, orderBy } from 'lodash';
import { format } from 'date-fns';

import { SubmissionLossSummaryOutDto, SubmissionLossSummaryTotalYearOutDto } from '@app/swagger-types';
import { buildSortParam, parseSortParam } from '@app/utils/api.util';
import { convertUTCToLocalDate } from '@app/utils/date.utils';
import { Table, TableColumn } from '@app/components/table';
import { formatDollars } from '@app/utils/currency/currency.utils';
import { FORMAT } from '@app/constants/formats';
import { clsxm } from '@app/styles/clsxm';

type VerifyLossRunDataRowType = SubmissionLossSummaryTotalYearOutDto & {
  isTotal?: boolean;
};

const DEFAULT_SORT = buildSortParam<VerifyLossRunDataRowType>('policyTermStart', 'desc');

interface Props {
  lossSummaryData: SubmissionLossSummaryOutDto | undefined;
  isFetching?: boolean;
}

export const LossSummaryTable: FC<Props> = ({ lossSummaryData, isFetching }) => {
  const [sort, setSort] = useState(DEFAULT_SORT);

  const cols: TableColumn<VerifyLossRunDataRowType>[] = useMemo(
    () => [
      {
        headerName: 'Policy Term',
        field: 'policyTermStart',
        align: 'left',
        bold: true,
        render: ({ policyTermStart, policyTermEnd, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>
            {isTotal
              ? 'Totals'
              : `${format(convertUTCToLocalDate(policyTermStart), FORMAT.DATE_YEAR)} - ${format(
                  convertUTCToLocalDate(policyTermEnd),
                  FORMAT.DATE_YEAR
                )}`}
          </span>
        ),
      },
      {
        headerName: 'Medical Claims',
        subHeaderName: '# Open',
        field: 'medicalClaimsOpenNum',
        align: 'center',
        render: ({ medicalClaimsOpenNum, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{medicalClaimsOpenNum}</span>
        ),
      },
      {
        headerName: 'Medical Claims',
        subHeaderName: '$ Open',
        field: 'medicalClaimsOpenValue',
        align: 'center',
        render: ({ medicalClaimsOpenValue, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(medicalClaimsOpenValue, 2)}</span>
        ),
      },
      {
        headerName: 'Medical Claims',
        subHeaderName: '# Closed',
        field: 'totalExpense',
        align: 'center',
        render: ({ medicalClaimsClosedNum, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{medicalClaimsClosedNum}</span>
        ),
      },
      {
        headerName: 'Medical Claims',
        subHeaderName: '$ Closed',
        field: 'totalMedical',
        align: 'center',
        render: ({ medicalClaimsClosedValue, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(medicalClaimsClosedValue, 2)}</span>
        ),
      },
      {
        headerName: 'Indemnity Claims',
        subHeaderName: '# Open',
        field: 'indemnityClaimsOpenNum',
        align: 'center',
        render: ({ indemnityClaimsOpenNum, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{indemnityClaimsOpenNum}</span>
        ),
      },
      {
        headerName: 'Indemnity Claims',
        subHeaderName: '$ Open',
        field: 'indemnityClaimsOpenValue',
        align: 'center',
        render: ({ indemnityClaimsOpenValue, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(indemnityClaimsOpenValue, 2)}</span>
        ),
      },
      {
        headerName: 'Indemnity Claims',
        subHeaderName: '# Closed',
        field: 'indemnityClaimsClosedNum',
        align: 'center',
        render: ({ indemnityClaimsClosedNum, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{indemnityClaimsClosedNum}</span>
        ),
      },
      {
        headerName: 'Indemnity Claims',
        subHeaderName: '$ Closed',
        field: 'indemnity',
        align: 'center',
        render: ({ indemnityClaimsClosedValue, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(indemnityClaimsClosedValue, 2)}</span>
        ),
      },
      {
        headerName: 'Total Reserves',
        field: 'totalReserves',
        align: 'right',
        render: ({ totalReserves, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalReserves, 2)}</span>
        ),
      },
      {
        headerName: 'Total Incurred',
        field: 'totalIncurred',
        align: 'right',
        bold: true,
        render: ({ totalIncurred, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{formatDollars(totalIncurred, 2)}</span>
        ),
      },
      {
        headerName: 'Number of Claims',
        field: 'numberOfClaims',
        align: 'right',
        render: ({ numberOfClaims, isTotal }) => (
          <span className={clsxm(isTotal && 'font-semibold')}>{numberOfClaims}</span>
        ),
      },
    ],
    []
  );

  const tableData: VerifyLossRunDataRowType[] = useMemo(() => {
    let data = lossSummaryData?.total.totalYears || [];

    const sortParam = sort.length ? parseSortParam(sort) : null;

    if (sortParam?.field && sortParam.order) {
      data = orderBy(data, [sortParam?.field], [sortParam.order]);
    }

    if (data.length) {
      return [
        ...data,
        // TOTAL Row
        {
          isTotal: true,
          policyTermStart: '',
          policyTermEnd: '',
          medicalClaimsOpenNum: lossSummaryData?.total.totalMedicalClaimsOpenNum ?? 0,
          medicalClaimsOpenValue: lossSummaryData?.total.totalMedicalClaimsOpenValue ?? 0,
          medicalClaimsClosedNum: lossSummaryData?.total.totalMedicalClaimsClosedNum ?? 0,
          medicalClaimsClosedValue: lossSummaryData?.total.totalMedicalClaimsClosedValue ?? 0,
          numberOfClaims: lossSummaryData?.total.numberOfClaims ?? 0,
          totalIncurred: lossSummaryData?.total.totalIncurred ?? 0,
          totalReserves: lossSummaryData?.total.totalReserves ?? 0,
          indemnityClaimsOpenNum: lossSummaryData?.total.totalIndemnityClaimsOpenNum ?? 0,
          indemnityClaimsOpenValue: lossSummaryData?.total.totalIndemnityClaimsOpenValue ?? 0,
          indemnityClaimsClosedNum: lossSummaryData?.total.totalIndemnityClaimsClosedNum ?? 0,
          indemnityClaimsClosedValue: lossSummaryData?.total.totalIndemnityClaimsClosedValue ?? 0,
          expensesIncurred: lossSummaryData?.total.totalExpensesIncurred ?? 0,
          reportOnlyClaimsOpenNum: lossSummaryData?.total.totalReportOnlyClaimsOpenNum ?? 0,
          reportOnlyClaimsClosedNum: lossSummaryData?.total.totalReportOnlyClaimsClosedNum ?? 0,
        },
      ];
    }

    return data;
  }, [lossSummaryData, sort]);

  return (
    <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
      <div className="flex items-center justify-between gap-3">
        <Typography className="py-4 text-xxxl">Loss Summary</Typography>
        <div className="flex items-center gap-8">
          {/* {!submissionData && isFetchingSubmission ? (
            <Skeleton height={38} width={250} variant="rectangular" className="rounded" />
          ) : (
            <SubmissionWarningBadge submissionData={submissionData} isFetchingSubmission={isFetchingSubmission} />
          )} */}
          {!lossSummaryData && isFetching ? (
            <Skeleton height={44} width={150} variant="rectangular" className="rounded" />
          ) : isNumber(lossSummaryData?.trir) ? (
            <div className="flex items-center gap-2.5">
              <div className="flex items-center gap-2 rounded-3xl bg-black/10 px-1.5 py-0.5">
                <AlignHorizontalLeft className="rounded-full bg-black fill-white p-1" />
                <Typography className="text-4xl">{lossSummaryData?.trir}</Typography>
              </div>
              <Typography className="text-xxs uppercase text-black/90">TRIR</Typography>
            </div>
          ) : null}
          {!lossSummaryData && isFetching ? (
            <Skeleton height={44} width={150} variant="rectangular" className="rounded" />
          ) : isNumber(lossSummaryData?.dart) ? (
            <div className="flex items-center gap-2.5">
              <div className="flex items-center gap-2 rounded-3xl bg-black/10 px-1.5 py-0.5">
                <EventNote className="rounded-full bg-black fill-white p-1" />
                <Typography className="text-4xl">{lossSummaryData?.dart}</Typography>
              </div>
              <Typography className="text-xxs uppercase text-black/90">DART</Typography>
            </div>
          ) : null}
        </div>
      </div>
      <Table
        cols={cols}
        tableData={tableData}
        loading={isFetching}
        hidePageSize
        subHeaderNameClassName="text-center"
        wrapperClassName="w-full"
        currentSort={sort}
        onSortChanged={setSort}
      />
    </Card>
  );
};
