/**
 * BE, FE, Design
 */
export type TimeTuple = [number, number, number];

export interface IRawLeafTask {
  name: string;
  type: 'rawLeaf';
  readyHours: TimeTuple;
  todoHours: TimeTuple;
}

export interface IRawNodeTask {
  name: string;
  type: 'rawNode';
  subtasks: AnyRawTask[];
}

export type AnyRawTask = IRawNodeTask | IRawLeafTask;

export interface IRawRoadmapData {
  checklist: IRawNodeTask[];
}

export enum ETaskStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  UNKNOWN_ESTIMATE = 'UNKNOWN_ESTIMATE',
}

export interface IBaseCalculatedTask {
  isRootTask?: boolean;
  readyHours: TimeTuple;
  todoHours: TimeTuple;
  monoReadyHours: number;
  monoTodoHours: number;
  status: ETaskStatus;
}

export interface ILeafTask extends Omit<IRawLeafTask, 'type'>, IBaseCalculatedTask {
  type: 'leaf';
}

export interface INodeTask extends Omit<IRawNodeTask, 'type' | 'subtasks'>, IBaseCalculatedTask {
  type: 'node';
  countOfLeafTasks: number;
  someLeafHasUnknownEstimate: boolean;
  subtasks: (INodeTask | ILeafTask)[];
}

export interface IRoadmapData {
  checklist: INodeTask[];
  readyHours: TimeTuple;
  todoHours: TimeTuple;
  monoReadyHours: number;
  monoTodoHours: number;
  countOfLeafTasks: number;
}

export type CalculatedTask = INodeTask | ILeafTask;
