import { clsxm } from '@app/styles/clsxm';
import { UIStyledSection } from '../components/UIStyledSection';

export const UISectionColors: React.FC = () => {
  const render = (colors: string[]) =>
    colors.map((e, i) => (
      <div key={i} className="flex flex-col gap-2">
        <div>{e.replace('bg-', '')}</div>
        <div className={clsxm(e, 'p-4')} />
      </div>
    ));

  return (
    <UIStyledSection title="Colors">
      <div className="flex flex-col gap-4 pb-6">
        <div>General colors</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_GENERAL)}</div>
      </div>
      <div className="flex flex-col gap-4 pb-6">
        <div>Feedback colors</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_FEEDBACK)}</div>
      </div>
    </UIStyledSection>
  );
};

const COLORS_GENERAL = /*tw*/ ['bg-primary', 'bg-secondary'];
const COLORS_FEEDBACK = /*tw*/ ['bg-success', 'bg-warning', 'bg-error', 'bg-info'];
