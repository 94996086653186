import { useState, useLayoutEffect } from 'react';

interface Dimensions {
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const useDimensions = (node: HTMLElement | null) => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useLayoutEffect(() => {
    if (node) {
      const measure = () => {
        const { width, height, top, right, bottom, left } = node.getBoundingClientRect();
        setDimensions({ width, height, top, right, bottom, left });
      };

      const resizeObserver = new ResizeObserver(() => measure());
      resizeObserver.observe(node);

      measure(); // Initial measure on mount

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [node]);

  return dimensions;
};
