import { useForm, UseFormProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { ZodSchema } from 'zod';
import type z from 'zod';

/**
 * TODO refactor zod inference to z.input and z.output as needed to handle default values properly
 */
export const useZodForm = <S extends ZodSchema>(
  schema: S,
  props: UseFormProps<z.infer<S>> & {
    onSubmit: (data: z.infer<S>) => unknown;
  }
) => {
  const res = useForm<z.infer<S>>({
    ...props,
    resolver: zodResolver(schema),
  });
  return {
    ...res,
    onSubmit: props.onSubmit,
    preventEnterKeyDown,
  };
};

export const preventEnterKeyDown: React.KeyboardEventHandler<HTMLFormElement> = (e) => {
  const { key, target } = e;
  if (key !== 'Enter' || target instanceof HTMLTextAreaElement) {
    return;
  }
  e.preventDefault();
};
