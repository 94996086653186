import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { CalcErrorResponse, NaicsOutDto, NcciOutDto, SubmissionGiCalcOutDto } from '@app/swagger-types';
import { GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS } from '@app/domain/sti/const/sti-access.const';
import { AwsCalcOutDtoResponse, FullCalcOutDtoResponse } from '@app/domain/sti/types/calc.types';
import { useGetAllNCCIs, useGetNaics } from '@app/domain/sti/api/sti-api.hooks';
import { ESubmissionSafeTierRatingTab } from '../widgets/SubmissionTabs';
import { useRoleRequired } from '@app/auth/useRoleRequired.hook';
import { ENcciType } from '@app/domain/sti/api/sti-api';
import { PaginatedResponse } from '@app/api/api.type';

/* 
  Context adapted from src/domain/sti/contexts/CalculatorFormsContext.tsx
*/

const SubmissionCalculatorFormsContext = createContext({
  // AWS CALC
  awsGiData: undefined as SubmissionGiCalcOutDto | undefined,
  awsCalcResult: undefined as AwsCalcOutDtoResponse | undefined,
  awsCalcErrors: null as CalcErrorResponse | null,

  onChangeAwsGiData: (_: SubmissionGiCalcOutDto | undefined) => {},
  onChangeAwsCalcResult: (_: AwsCalcOutDtoResponse | undefined) => {},
  onChangeAwsCalcErrors: (_: CalcErrorResponse | null) => {},
  // FULL CALC
  fullCalcResult: undefined as FullCalcOutDtoResponse | undefined,
  onChangeFullCalcResult: (_: FullCalcOutDtoResponse | undefined) => {},
  fullCalcErrors: null as CalcErrorResponse | null,
  onChangeFullCalcErrors: (_: CalcErrorResponse | null) => {},

  isCalcEditing: false,
  setCalcEditing: (_: boolean) => {},
  calcTab: ESubmissionSafeTierRatingTab.STANDARD,
  setCalcTab: (_: ESubmissionSafeTierRatingTab) => {},

  naics: undefined as PaginatedResponse<NaicsOutDto> | undefined,
  ncci: undefined as undefined | PaginatedResponse<NcciOutDto>,

  pageNode: null as HTMLElement | null,
  setPageNode: (_: null | HTMLElement) => {},
});

interface Props {
  children: ReactNode;
}

export const SubmissionCalculatorFormsProvider: FC<Props> = ({ children }) => {
  // AWS CALC
  const [awsGiData, setAwsGiData] = useState<SubmissionGiCalcOutDto | undefined>(undefined);
  const [awsCalcResult, setAwsCalcResult] = useState<AwsCalcOutDtoResponse | undefined>(undefined);
  const [awsCalcErrors, setAwsCalcErrors] = useState<CalcErrorResponse | null>(null);
  // FULL CALC
  const [fullCalcResult, setFullCalcResult] = useState<FullCalcOutDtoResponse | undefined>(undefined);
  const [fullCalcErrors, setFullCalcErrors] = useState<CalcErrorResponse | null>(null);

  const [calcTab, setCalcTab] = useState(ESubmissionSafeTierRatingTab.STANDARD);
  const [isCalcEditing, setCalcEditing] = useState(false);

  const [pageNode, setPageNode] = useState<HTMLElement | null>(null);

  const userHasAccessToGiData = useRoleRequired({ oneOf: GROUPS_WITH_ACCESS_TO_ALTERNATIVE_RESULTS });

  const onChangeAwsCalcResult = useCallback(
    (results: AwsCalcOutDtoResponse | undefined) => {
      setAwsCalcResult(results);
      setAwsGiData(undefined);

      if (pageNode) {
        pageNode.scrollTo(0, 0);
      }
    },
    [pageNode]
  );

  const onChangeFullCalcResult = useCallback(
    (results: FullCalcOutDtoResponse | undefined) => {
      setFullCalcResult(results);

      if (pageNode) {
        pageNode.scrollTo(0, 0);
      }
    },
    [pageNode]
  );

  const { data: naics } = useGetNaics({ keepPreviousData: true });
  const { data: ncci } = useGetAllNCCIs({ type: ENcciType.FULL }, { keepPreviousData: true });

  const value = useMemo(
    () => ({
      setCalcEditing,
      isCalcEditing,
      setCalcTab,
      calcTab,
      // AWS CALC
      awsGiData: userHasAccessToGiData ? awsGiData : undefined,
      awsCalcResult,
      onChangeAwsGiData: setAwsGiData,
      onChangeAwsCalcResult,
      awsCalcErrors,
      onChangeAwsCalcErrors: setAwsCalcErrors,
      // FULL CALC
      fullCalcResult,
      onChangeFullCalcResult,
      fullCalcErrors,
      onChangeFullCalcErrors: setFullCalcErrors,
      naics,
      ncci,
      pageNode,
      setPageNode,
    }),
    [
      awsCalcErrors,
      awsCalcResult,
      awsGiData,
      calcTab,
      fullCalcErrors,
      fullCalcResult,
      isCalcEditing,
      naics,
      ncci,
      onChangeAwsCalcResult,
      onChangeFullCalcResult,
      pageNode,
      userHasAccessToGiData,
    ]
  );

  return (
    <SubmissionCalculatorFormsContext.Provider value={value}>{children}</SubmissionCalculatorFormsContext.Provider>
  );
};

export const useSubmissionCalculatorFormsContext = () => useContext(SubmissionCalculatorFormsContext);
