import React, { memo } from 'react';

import { ReactComponent as AppDarkLogoSvg } from './app-dark-logo.svg';
import { ReactComponent as AppLogoSvg } from './app-logo.svg';
import { ReactComponent as AppLogoNoTextSvg } from './app-logo-no-text.svg';

type Props = {
  dark?: boolean;
  noText?: boolean;
};

export const AppLogo: React.FC<React.ComponentProps<typeof AppLogoSvg> & Props> = memo(
  ({ width = 144, height = 40, dark = false, noText, ...rest }) => {
    if (noText) {
      return <AppLogoNoTextSvg width={height} height={height} {...rest} />;
    }

    return dark ? (
      <AppDarkLogoSvg width={width} height={height} {...rest} />
    ) : (
      <AppLogoSvg width={width} height={height} {...rest} />
    );
  }
);
