import { truncate } from 'lodash';
import Draggable from 'react-draggable';

import { clsxm } from '@app/styles/clsxm';

import {
  Dialog as MuiDialog,
  useMediaQuery,
  DialogProps,
  DialogTitle,
  Typography,
  PaperProps,
  useTheme,
  Paper,
} from '@mui/material';
import { FC } from 'react';

const PaperComponent: FC<PaperProps> = (props) => (
  <Draggable handle="#draggable-dialog-title" cancel='[class*="MuiDialogContent-root"], .not-draggable'>
    <Paper {...props} />
  </Draggable>
);

type Props = {
  headerTitle?: string;
  headerSubtitle?: string;
  headerEntity?: string;
  paperClassName?: string;
  hideHeader?: boolean;
  headerContent?: React.ReactNode;
} & DialogProps;

export const Dialog: FC<Props> = ({
  children,
  open,
  headerTitle,
  headerEntity,
  onClose,
  paperClassName,
  hideHeader,
  headerContent,
  headerSubtitle,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MuiDialog
      open={open}
      PaperProps={{ className: clsxm('rounded', paperClassName) }}
      onClose={onClose}
      {...props}
      {...(!isMobile
        ? {
            PaperComponent: PaperComponent,
            'aria-labelledby': 'draggable-dialog-title',
          }
        : {})}
      disablePortal
    >
      {!hideHeader && (
        <DialogTitle id="draggable-dialog-title" className="cursor-move">
          <div>
            <Typography className="text-l font-bold" noWrap>
              {truncate(headerTitle, { length: 120 })}
            </Typography>
            {!!headerSubtitle && <Typography noWrap>{truncate(headerSubtitle, { length: 120 })}</Typography>}
          </div>

          {headerEntity && <Typography>{headerEntity}</Typography>}
          {headerContent}
        </DialogTitle>
      )}

      {children}
    </MuiDialog>
  );
};
