import { clsxm } from '@app/styles/clsxm';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { UISectionBranding } from './sections/UISectionBranding';
import { UISectionColors } from './sections/UISectionColors';
import { UISectionFeatured } from '@app/modules/ui-kit/sections/UISectionFeatured';

export const UIKitPage: React.FC = () => {
  const [tab, setTab] = useQueryParam('tab', withDefault(StringParam, DEFAULT_SECTION));

  return (
    <div>
      <div className="flex justify-center gap-4">
        {TABS.map((e, i) => (
          <button key={i} onClick={() => setTab(e, 'replace')} className={clsxm(e === tab && 'underline')}>
            {e}
          </button>
        ))}
      </div>
      {SECTIONS.find((e) => e.title === tab)?.jsx}
    </div>
  );
};

const SECTIONS: {
  title: string;
  jsx: React.ReactNode;
}[] = [
  { title: 'Branding', jsx: <UISectionBranding /> },
  { title: 'Colors', jsx: <UISectionColors /> },
  { title: 'Layout', jsx: <div>TODO UISectionLayout</div> },
  { title: 'Formatting', jsx: <div>TODO UISectionFormatting</div> },
  { title: 'Animations', jsx: <div>TODO UISectionAnimations</div> },
  { title: 'Forms', jsx: <div>TODO UISectionForms</div> },
  { title: 'Placeholders', jsx: <div>TODO UISectionPlaceholder</div> },
  { title: 'Icons', jsx: <div>TODO UISectionIcons</div> },
  { title: 'Featured', jsx: <UISectionFeatured /> },
];

const DEFAULT_SECTION = SECTIONS[0]?.title || '';
const TABS = SECTIONS.map((e) => e.title);
