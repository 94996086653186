import { FC } from 'react';
import { Card, Skeleton, Typography } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useConfirmUploadSubmissionFileDialog } from '../modals/ConfirmUploadSubmissionFileDialog';
import { FileDropZoneWrapper } from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import { SubmissionFileRow } from '../components/SubmissionFileRow';
import { ESubmissionFileType } from '@app/swagger-types';
import { useHandler } from '@app/hooks/useHandler.hook';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import {
  useUploadSubmissionFileMutation,
  EUploadSubmissFileMutationKey,
  useGetSubmissionByIdQuery,
} from '../api/submission.api.hooks';
import { showErrorToast } from '@app/utils/toast.utils';
import { SubmissionDocumentSection } from '../components/SubmissionDocumentSection';
import { LOADING_ESubmissionFileStatus_VALUES } from '../const/submission.const';

const allowedTypes = ['application/pdf'];

interface Props {
  submissionId: string;
  setInsightsIndicated: (value: boolean) => void;
}

export const SubmissionDocumentsTab: FC<Props> = ({ submissionId, setInsightsIndicated }) => {
  const { data, isFetching } = useGetSubmissionByIdQuery(submissionId, {
    refetchOnMount: 'always',
    refetchInterval: (query) => {
      const accordFile = query?.fileOutAccordApplicationDtos?.[0];
      const emrFile = query?.fileOutEmrDtos?.[0];
      const lossRunsFiles = query?.fileOutLossRunsDtos;

      if (
        (accordFile?.status && LOADING_ESubmissionFileStatus_VALUES.includes(accordFile.status)) ||
        (emrFile?.status && LOADING_ESubmissionFileStatus_VALUES.includes(emrFile.status)) ||
        (lossRunsFiles && lossRunsFiles.find((file) => LOADING_ESubmissionFileStatus_VALUES.includes(file.status)))
      ) {
        return 1000 * 7;
      }

      return false;
    },
  });

  const accordFile = data?.fileOutAccordApplicationDtos?.[0];
  const emrFile = data?.fileOutEmrDtos?.[0];
  const lossRunsFiles = data?.fileOutLossRunsDtos;

  const confirmUploadSubmissionFileDialog = useConfirmUploadSubmissionFileDialog();

  const { mutateAsync: uploadAccordFiles, isLoading: isUploadingAccord } = useUploadSubmissionFileMutation({
    mutationKey: [EUploadSubmissFileMutationKey.UPLOAD_ACCORD],
  });
  const { mutateAsync: uploadEmrFiles, isLoading: isUploadingEmr } = useUploadSubmissionFileMutation({
    mutationKey: [EUploadSubmissFileMutationKey.UPLOAD_EMR],
  });
  const { mutateAsync: uploadLossRunsFiles } = useUploadSubmissionFileMutation({
    mutationKey: [EUploadSubmissFileMutationKey.UPLOAD_LOSS_RUNS],
  });

  const isUploadingLossRuns = Boolean(useIsMutating({ mutationKey: [EUploadSubmissFileMutationKey.UPLOAD_LOSS_RUNS] }));

  const isUploading = isUploadingAccord || isUploadingEmr || isUploadingLossRuns;

  const handleUploadAccordFile = useHandler(async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (accordFile) {
        confirmUploadSubmissionFileDialog.open({
          fileId: accordFile.id,
          submissionId,
          formData,
        });
      } else {
        await uploadAccordFiles({
          submissionId,
          fileType: ESubmissionFileType.ACCORD,
          formData,
        });

        if (data?.hasGeneratedInsights) {
          setInsightsIndicated(false);
        }
      }
    } catch (err) {
      console.error(err);
      console.warn('Failed to upload Accord file. Please try again.');
    }
  });

  const handleUploadEmrFile = useHandler(async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      if (emrFile) {
        confirmUploadSubmissionFileDialog.open({
          fileId: emrFile.id,
          submissionId,
          formData,
        });
      } else {
        await uploadEmrFiles({
          submissionId,
          fileType: ESubmissionFileType.EMR,
          formData,
        });

        if (data?.hasGeneratedInsights) {
          setInsightsIndicated(false);
        }
      }
    } catch (err) {
      console.error(err);
      console.warn('Failed to upload EMR file. Please try again.');
    }
  });

  const handleUploadLossRunsFiles = useHandler(async (files: File[]) => {
    try {
      const uploadPromises = files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);

        return uploadLossRunsFiles({
          submissionId,
          fileType: ESubmissionFileType.LOSS_RUNS,
          formData,
        });
      });

      await Promise.all(uploadPromises);
      if (data?.hasGeneratedInsights) {
        setInsightsIndicated(false);
      }
    } catch (err) {
      console.error(err);
      console.warn('Failed to upload one or more Loss Runs files. Please try again.');
    }
  });

  const handleNotAllowed = useHandler(() => {
    showErrorToast(
      <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography style={{ fontWeight: 700 }}>Invalid File Type</Typography>
        <Typography>Submission documents must be in PDF format. Please check your document and try again.</Typography>
      </div>
    );
  });

  return (
    <Card className="flex w-full flex-col gap-2.5 rounded-lg bg-white p-6 shadow-card">
      <Typography className="border-b border-b-black/10 py-4 text-xxxl">Submission Documents</Typography>
      <SubmissionDocumentSection
        title="Acord 130 Application"
        dropZoneText="Drag and drop Acord or"
        noDataText="For best results, ensure that payroll information is up to date and complete"
        isFetching={!data && isFetching}
        onUploadFile={handleUploadAccordFile}
        file={accordFile}
        submissionId={submissionId}
        isUploading={isUploadingAccord}
        allowedTypes={allowedTypes}
        onNotAllowed={handleNotAllowed}
        disabled={isUploading}
      />
      <SubmissionDocumentSection
        title="Ex Mod Worksheet"
        dropZoneText="Drag and drop ExMod or"
        noDataText="Ensure that the rating effective date matches the policy effective date"
        isFetching={!data && isFetching}
        onUploadFile={handleUploadEmrFile}
        file={emrFile}
        submissionId={submissionId}
        isUploading={isUploadingEmr}
        allowedTypes={allowedTypes}
        onNotAllowed={handleNotAllowed}
        disabled={isUploading}
      />
      <div className="border-b border-b-black/10 pb-2">
        {!data && isFetching ? (
          <div>
            <Skeleton height={180} variant="rectangular" className="rounded" />
          </div>
        ) : (
          <div className="grid min-h-[180px] grid-cols-3 gap-2.5">
            <FileDropZoneWrapper
              onMultipleChange={handleUploadLossRunsFiles}
              accept="application/pdf"
              loading={isUploadingLossRuns}
              className="col-span-1 max-h-56"
              multiple
              text="Drag and drop Loss Runs or"
              allowedTypes={allowedTypes}
              onNotAllowed={handleNotAllowed}
            />

            <div className="col-span-2 flex flex-col justify-center gap-4 p-4">
              <Typography className="text-xl font-bold">Loss Run Reports</Typography>
              {lossRunsFiles?.length ? (
                lossRunsFiles
                  .sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
                  .map((file) => (
                    <SubmissionFileRow submissionId={submissionId} disabled={isUploading} key={file.id} file={file} />
                  ))
              ) : (
                <div className="flex items-center justify-between gap-2">
                  <Typography className="text-s text-black/40">
                    We require 3-5 years of the most recent Loss Runs, including the current year
                  </Typography>
                  <IconButton size="large" disabled>
                    <InfoOutlinedIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
