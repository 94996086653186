import { FC } from 'react';

import { Table } from '../../../components/table/Table';
import { TableColumn } from '../../../components/table/TableTypes';
import { StateComparison } from '../../../swagger-types';

interface Props {
  states: StateComparison[];
  pdf?: boolean;
}

export const ComparisonStatesTable: FC<Props> = ({ states, pdf }) => {
  const cols: TableColumn<StateComparison>[] = [
    {
      headerName: 'Payroll State',
      field: 'state',
      align: 'center',
    },
    {
      headerName: 'Impact Weight',
      field: 'impactWeightPercent',
      align: 'center',
      render: ({ impactWeightPercent }) => `${impactWeightPercent || 0}%`,
    },
    {
      headerName: 'BLS Source Used',
      field: 'blsSourceUsed',
      align: 'center',
    },
    {
      headerName: 'Perfect Match?',
      field: 'perfectMatch',
      align: 'center',
    },
    {
      headerName: 'NAICS Code Used',
      field: 'naicsCodeUsed',
      align: 'center',
    },
    {
      headerName: 'Phraseology',
      field: 'phraseology',
      align: 'left',
    },
  ];

  return <Table title="BLS DATA AVAILABILITY & IMPACT:" cols={cols} tableData={states} pdf={pdf} hidePageSize />;
};
