import { AxiosResponse } from 'axios';

import { ClaimPdfParams } from '@app/domain/claim/pdf/utils/claim.pdf.utils';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { PaginationQueryParams } from '@app/types/query-params.types';
import {
  PageResponseDataExplorationPromptOutDto,
  DataExplorationTypesOutDto,
  DataExplorationOutDto,
  DataExplorationInDto,
  FieldFilter,
} from '@app/swagger-types';
import {
  PageResponseDataExplorationPromptOutDtoSchema,
  DataExplorationTypesOutDtoSchema,
  DataExplorationOutDtoSchema,
} from '../schemas/data-exploration.schema';

export interface FieldsTermQueryParams extends PaginationQueryParams, FieldFilter {
  term?: string;
  characterSearch?: string;
  sort?: string;
}

export interface ClaimsExportQueryParams extends Partial<ClaimPdfParams> {
  all: boolean;
}

export interface GetClaimsQueryParams extends PaginationQueryParams {
  filterId?: string;
}

export abstract class DataExplorationApi {
  static async requestDataExploration(Dto: DataExplorationInDto) {
    return validateAndAssert(
      apiService.post<DataExplorationOutDto, DataExplorationInDto>('/private/data-exploration', Dto),
      DataExplorationOutDtoSchema
    );
  }
  static async getDataExplorationTypes() {
    return validateAndAssert(
      apiService.get<DataExplorationTypesOutDto>('/private/data-exploration/types'),
      DataExplorationTypesOutDtoSchema
    );
  }
  static async getDataExplorationPrompts(page = 0, size = 25, sort?: string) {
    return validateAndAssert(
      apiService.get<PageResponseDataExplorationPromptOutDto>(`/private/data-exploration/prompts`, {
        params: {
          page,
          size,
          sort,
        },
      }),
      PageResponseDataExplorationPromptOutDtoSchema
    );
  }
  static async sendBadResponse(logId: string) {
    return apiService.post<AxiosResponse>('/private/data-exploration/bad-response', undefined, { params: { logId } });
  }
}
