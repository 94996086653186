import { UNCHANGEABLE_CLASS_CODES } from '../const/calc.const';
import { CalcResponseState } from '../types/calc.types';

export const getColorByFinalCrDB = (finalCrDB: number) => {
  if (finalCrDB < 0) {
    return 'green';
  } else if (finalCrDB === 0) {
    return 'grey';
  }
  return 'red';
};

export const calculateExpectedClaims = (
  expectedClaims: number,
  originalCrDb: number,
  overrideStiCrDb: number
): number => (expectedClaims / (1 + originalCrDb / 100)) * (1 + overrideStiCrDb / 100);

export const renderStiModelName = (modelVersion: string, originalCrDb: number) =>
  `${modelVersion}${originalCrDb >= 0 ? 'P' : 'N'}${Math.abs(originalCrDb)}`;

export const getStiValueFromStates = (states: CalcResponseState[]) =>
  Number(states.filter(({ classCode }) => !UNCHANGEABLE_CLASS_CODES.includes(classCode))[0].stiCrDB ?? 0);
