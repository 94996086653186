import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { typedEnv } from '@app/environment/typed-env';
import { LicenseInfo } from '@mui/x-license';

import App from './App';
import '@app/styles/index.scss';

LicenseInfo.setLicenseKey(typedEnv.REACT_APP_MUI_LICENSE_KEY);

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
