import { Outlet } from 'react-router-dom';

import { ClaimFiltersContextProvider } from '@app/domain/claim/context/ClaimFiltersContext';

export const ClaimsModule = () => {
  return (
    <ClaimFiltersContextProvider>
      <Outlet />
    </ClaimFiltersContextProvider>
  );
};
