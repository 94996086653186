import { Box, CircularProgressProps, CircularProgress as MuiCircularProgress } from '@mui/material';
import { FC } from 'react';

export const CircularProgress: FC<CircularProgressProps> = (props) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <MuiCircularProgress
      sx={{ color: (theme) => theme.palette.grey[100] }}
      size={props.size || 40}
      variant="determinate"
      thickness={4}
      value={100}
    />
    <MuiCircularProgress
      sx={{
        animationDuration: '1000ms',
        height: props.size || 40,
        width: props.size || 40,
        position: 'absolute',
        left: 0,
        top: 0,
      }}
      variant="indeterminate"
      size={props.size || 40}
      disableShrink
      thickness={4}
      {...props}
    />
  </Box>
);
