import { FC } from 'react';
import { isNumber } from 'lodash';
import { Skeleton, Typography } from '@mui/material';

import { useGetRiskFactorsBySubmissionIdQuery } from '../api/submission.api.hooks';

interface Props {
  submissionId: string;
}

export const SubmissionRiskFactorsTotalAdjustmentBlock: FC<Props> = ({ submissionId }) => {
  const { data, isFetching } = useGetRiskFactorsBySubmissionIdQuery(submissionId);

  const totalAdjustment = data?.totalRiskFactor;

  if (isFetching && !data) {
    return <Skeleton variant="rounded" height={77} className="rounded-xl" />;
  }

  if (isNumber(totalAdjustment)) {
    return (
      <div className="flex items-center justify-between gap-2 rounded-xl border border-black/10 px-3 py-2">
        <Typography className="text-xs text-black/90">Total Adjustment</Typography>
        <Typography className="text-5xl font-medium text-black/90">{totalAdjustment}%</Typography>
      </div>
    );
  }

  return null;
};
