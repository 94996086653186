import clsx from 'clsx';
import { isBoolean, isString } from 'lodash';
import { nanoid } from 'nanoid';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useCallback, useState, useRef, Ref, forwardRef, ReactElement, useMemo } from 'react';
import { useThrottledCallback } from 'use-debounce';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Autocomplete as MuiAutocomplete, AutocompleteProps as MuiAutocompleteProps } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';

import styles from './Autocomplete.module.scss';
import { Input, InputProps } from '../input/Input';

enum AutocompleteState {
  NO_OPTIONS_AVAILABLE,
  OPTIONS_FETCH_REQUIRED,
  OPTIONS_FETCHED,
  OPTIONS_LOADING,
  OPTIONS_FETCH_ERROR,
}

enum AutocompleteFetchFrom {
  SEARCH,
  SCROLL,
}

export interface MyAutocomplete<
  K,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<K, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'options' | 'onInputChange' | 'onOpen' | 'onClose'
  > {
  getOptions: (search?: string, page?: number, loadedCount?: number) => Promise<K[]>;
  forceOptions?: K[];
  browserAutocompleteOff?: boolean;
  fetchOnOpen?: boolean;
  noEmptyFetch?: boolean;
  onOptionsClose?: () => void;
  InputProps?: InputProps;
  asyncSearch?: boolean;
  asyncDelay?: number;
  paperClassName?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  hideEndIcon?: boolean;
  hasMore?: boolean;
  highlightTextMatchAccessor?: boolean;
  scrollOffset?: number;
  clearOptionsOnClose?: boolean;
  withTextMatchAccessor?: string;
  endIconWithToggleIcon?: boolean;
  minCharactersForSearch?: number;
  onOpen?: () => void;
  onClose?: () => void;
  search?: string;
  onChangeSearch?: (search: string) => void;
  disableSearchReset?: boolean;
  showClearIcon?: boolean;
  sortMethod?: (optionA: K, optionB: K) => number;
}

function MyAutocomplete<
  K = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  {
    getOptions,
    onOptionsClose,
    scrollOffset = 0,
    asyncDelay = 400,
    clearOptionsOnClose,
    noEmptyFetch,
    fetchOnOpen,
    browserAutocompleteOff,
    InputProps,
    asyncSearch,
    paperClassName,
    startIcon,
    endIcon,
    hideEndIcon,
    withTextMatchAccessor,
    highlightTextMatchAccessor,
    hasMore,
    endIconWithToggleIcon,
    minCharactersForSearch = 0,
    disabled,
    open,
    onOpen,
    onClose,
    forceOptions,
    search = '',
    onChangeSearch,
    disableSearchReset = false,
    showClearIcon = false,
    sortMethod,
    ...props
  }: MyAutocomplete<K, Multiple, DisableClearable, FreeSolo>,
  ref: Ref<any>
) {
  const [autocompleteStateMachine, setAutocompleteState] = useState<AutocompleteState>(
    fetchOnOpen ? AutocompleteState.NO_OPTIONS_AVAILABLE : AutocompleteState.OPTIONS_FETCH_REQUIRED
  );
  const [autocompleteFetchFromMachine, setAutocompleteFetchFrom] = useState<AutocompleteFetchFrom>(
    AutocompleteFetchFrom.SEARCH
  );

  const [page, setPage] = useState(0);
  const [options, setOptions] = useState<K[]>([]);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(search);
  const searchRequestId = useRef('');

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  const loading =
    autocompleteStateMachine === AutocompleteState.OPTIONS_LOADING ||
    autocompleteStateMachine === AutocompleteState.OPTIONS_FETCH_REQUIRED;

  const throttledSetSearch = useThrottledCallback(() => {
    setPage(0);
    setAutocompleteFetchFrom(AutocompleteFetchFrom.SEARCH);
    setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
    searchRequestId.current = nanoid(10);
  }, asyncDelay);

  useEffect(() => {
    if (autocompleteStateMachine === AutocompleteState.OPTIONS_FETCH_REQUIRED || open) {
      (async () => {
        setAutocompleteState(AutocompleteState.OPTIONS_LOADING);
        try {
          const savedRequestLocalId = searchRequestId.current;
          const optionsData = forceOptions || (await getOptions(searchValue, page, options.length));

          if (autocompleteFetchFromMachine === AutocompleteFetchFrom.SEARCH) {
            if (searchRequestId.current === savedRequestLocalId) {
              setOptions(optionsData);
              setAutocompleteState(AutocompleteState.OPTIONS_FETCHED);
            }
          } else if (autocompleteFetchFromMachine === AutocompleteFetchFrom.SCROLL) {
            setOptions((prev) => [...prev, ...optionsData]);
            setAutocompleteState(AutocompleteState.OPTIONS_FETCHED);
          }
        } catch (err) {
          setAutocompleteState(AutocompleteState.OPTIONS_FETCH_ERROR);
          console.error(err);
        }
      })();
    }
  }, [
    autocompleteFetchFromMachine,
    autocompleteStateMachine,
    page,
    options.length,
    searchValue,
    getOptions,
    forceOptions,
    open,
  ]);

  const handleOptionsToggle = useCallback(
    (open?: boolean) => () => {
      if (!open && clearOptionsOnClose) {
        setPage(0);
        setAutocompleteState(
          fetchOnOpen ? AutocompleteState.NO_OPTIONS_AVAILABLE : AutocompleteState.OPTIONS_FETCH_REQUIRED
        );
        setAutocompleteFetchFrom(AutocompleteFetchFrom.SEARCH);
        setOptions([]);
        onOpen?.();
      }
      if (!open) {
        setSearchValue('');
        onChangeSearch?.('');
      }
      const newOpenValue = isBoolean(open) ? open : !optionsOpen;
      setOptionsOpen(newOpenValue);
      const emptySearch = noEmptyFetch ? !!searchValue : true;
      if (newOpenValue && autocompleteStateMachine === AutocompleteState.NO_OPTIONS_AVAILABLE && emptySearch) {
        setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
      }
      if (onOptionsClose && !newOpenValue) {
        onOptionsClose();
      }
      onClose?.();
    },
    [
      autocompleteStateMachine,
      clearOptionsOnClose,
      fetchOnOpen,
      noEmptyFetch,
      onOptionsClose,
      optionsOpen,
      searchValue,
      onOpen,
      onClose,
      onChangeSearch,
    ]
  );

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      setAutocompleteFetchFrom(AutocompleteFetchFrom.SCROLL);
      setPage((prevPageValue) => prevPageValue + 1);
      setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
    }
  }, [loading, hasMore]);

  const renderClearButton = () => {
    if (!showClearIcon || !search) {
      return null;
    }

    return (
      <IconButton
        variant="transparent"
        color="primary"
        onClick={() => {
          setSearchValue('');
          onChangeSearch?.('');

          if (asyncSearch) {
            throttledSetSearch();
          } else {
            setPage(0);
            setAutocompleteFetchFrom(AutocompleteFetchFrom.SEARCH);
            setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
          }
        }}
        tabIndex={-1}
        disableRipple
        className="p-1 hover:text-red-500"
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const memoizedOptions = useMemo(() => {
    const baseOptions = forceOptions || options;

    if (sortMethod) {
      return baseOptions.sort(sortMethod);
    }

    return baseOptions;
  }, [forceOptions, options, sortMethod]);

  return (
    <MuiAutocomplete
      ref={ref}
      disabled={disabled}
      classes={{
        paper: clsx(styles.Paper, paperClassName),
        ...props.classes,
      }}
      ListboxProps={
        hasMore
          ? {
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget;
                if (listboxNode.scrollTop + listboxNode.clientHeight + scrollOffset >= listboxNode.scrollHeight) {
                  loadMore();
                }
              },
            }
          : undefined
      }
      open={open || (optionsOpen && searchValue.length >= minCharactersForSearch)}
      onOpen={handleOptionsToggle(true)}
      onClose={handleOptionsToggle(false)}
      loading={loading}
      options={memoizedOptions}
      inputValue={searchValue}
      onInputChange={(_, value = '', reason) => {
        if (reason !== 'reset' || (reason === 'reset' && !disableSearchReset)) {
          setSearchValue(value);
          onChangeSearch?.(value);
        }

        if (asyncSearch) {
          throttledSetSearch();
        } else {
          setPage(0);
          setAutocompleteFetchFrom(AutocompleteFetchFrom.SEARCH);
          setAutocompleteState(AutocompleteState.OPTIONS_FETCH_REQUIRED);
        }
      }}
      renderInput={(params) => {
        return (
          <Input
            ref={params.InputProps.ref}
            {...InputProps}
            className={clsx(styles.InputWrapper, InputProps?.className)}
            startAdornment={startIcon || params.InputProps.startAdornment}
            data-cy="autocomplete-input"
            fullWidth
            disabled={disabled}
            endAdornment={
              !hideEndIcon &&
              (endIcon ? (
                endIconWithToggleIcon ? (
                  <>
                    {endIcon}
                    {renderClearButton()}
                    <IconButton
                      variant="transparent"
                      color="primary"
                      onClick={handleOptionsToggle()}
                      tabIndex={-1}
                      disableRipple
                      className={styles.ArrowIconWrapper}
                    >
                      <ChevronDownIcon className={clsx(styles.ArrowIcon, { [styles.ArrowIconOpen]: optionsOpen })} />
                    </IconButton>
                  </>
                ) : (
                  endIcon
                )
              ) : (
                <>
                  {renderClearButton()}
                  <IconButton
                    variant="transparent"
                    color="primary"
                    onClick={handleOptionsToggle()}
                    disableRipple
                    className={styles.ArrowIconWrapper}
                    tabIndex={-1}
                  >
                    <ChevronDownIcon
                      className={clsx(styles.ArrowIcon, {
                        [styles.ArrowIconOpen]: optionsOpen && searchValue.length >= minCharactersForSearch,
                      })}
                    />
                  </IconButton>
                </>
              ))
            }
            classes={{
              root: styles.InputRoot,
              ...InputProps?.classes,
            }}
            inputProps={{
              ...params.inputProps,
              ...InputProps?.inputProps,
              autoComplete: browserAutocompleteOff ? 'off' : 'new-password',
            }}
          />
        );
      }}
      renderOption={
        withTextMatchAccessor
          ? (props, option, { inputValue }) => {
              const optionGet = option as unknown as Record<string, string>;
              const optionLabel = isString(optionGet) ? optionGet : optionGet[withTextMatchAccessor];
              const matches = match(optionLabel, inputValue);
              const parts = parse(optionLabel, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        className={highlightTextMatchAccessor && part.highlight ? 'highlight-primary' : undefined}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }
          : props.renderOption
      }
      {...props}
    />
  );
}

export const Autocomplete = forwardRef(MyAutocomplete) as <
  K = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  p: MyAutocomplete<K, Multiple, DisableClearable, FreeSolo> & { ref?: Ref<any> }
) => ReactElement;
