import { Blocker, BlockerFunction, useBlocker } from 'react-router-dom';
import { FC } from 'react';

import { ConfirmationModal, ConfirmationModalProps } from '@app/hoc/confirm-dialog/ConfirmationModal';
import { useHandler } from '@app/hooks/useHandler.hook';

interface Props
  extends Pick<
    ConfirmationModalProps,
    'headerTitle' | 'title' | 'text' | 'confirmText' | 'confirmIcon' | 'cancelText'
  > {
  blockerFn: BlockerFunction;
  onConfirm?: VoidFunction;
  onCancel?: (navBlocker: Blocker) => void;
}

export const NavigationBlocker: FC<Props> = ({
  blockerFn,
  headerTitle,
  title = 'Are you sure you want to leave?',
  text = 'You will lost your changes',
  confirmText = 'Proceed',
  confirmIcon,
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
}) => {
  const navBlocker = useBlocker(blockerFn);

  const open = navBlocker.state === 'blocked';

  const handleClose = useHandler(() => {
    navBlocker.reset?.();
  });

  const handleCancel = useHandler(() => {
    if (onCancel && navBlocker) {
      onCancel(navBlocker);
    } else {
      navBlocker.proceed?.();
    }
  });

  const handleConfirm = useHandler(() => {
    navBlocker.proceed?.();
    onConfirm?.();
  });

  return (
    <ConfirmationModal
      open={open}
      onCancel={handleCancel}
      onClose={handleClose}
      onConfirm={handleConfirm}
      headerTitle={headerTitle}
      title={title}
      text={text}
      confirmText={confirmText}
      confirmIcon={confirmIcon}
      cancelText={cancelText}
    />
  );
};
