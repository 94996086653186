import React from 'react';

import { Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableTypes';
import { AwsCalcOutDto } from '../../types/calc.types';

type RowType = Pick<AwsCalcOutDto, 'expectedEmr' | 'coEmr'>;

type Props = Omit<RowType, 'header'> & {
  pdf?: boolean;
};

export const AwsEmrTable: React.FC<Props> = ({ pdf, expectedEmr, coEmr }) => {
  const rows: RowType[] = [
    {
      expectedEmr,
      coEmr,
    },
  ];

  const cols: TableColumn<RowType>[] = [
    {
      headerName: 'Actual',
      field: 'coEmr',
      headAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'Benchmark',
      field: 'expectedEmr',
      headAlign: 'center',
      align: 'center',
      bold: true,
      width: 150,
    },
  ];

  return <Table cols={cols} tableData={rows} pdf={pdf} hidePageSize title="EMR:" />;
};
