import { Typography, SelectChangeEvent } from '@mui/material';
import { useRef, PropsWithChildren, FC } from 'react';
import PlusIcon from '@mui/icons-material/AddBox';
import { isUndefined } from 'lodash';
import clsx from 'clsx';

import { Button } from '@app/components/buttons/button/Button';
import { Select, OptionItem } from '@app/components/select';
import { Pagination } from '../pagination/Pagination';

import styles from './TableCard.module.scss';

interface PropTypes {
  wrapperClassName?: string;
  cardHeaderClassName?: string;
  total?: number;
  currentPage?: number;
  pageSize?: number;
  headerContent?: React.ReactNode;
  rightHeaderContent?: React.ReactNode;
  leftHeaderContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  onPagination?: (page: number) => void;
  hasMore?: boolean;
  onHasMoreClick?: () => void;
  onSizeChange?: (newSize: number) => void;
  hidePageSize?: boolean;
}

export const TableCard: FC<PropsWithChildren<PropTypes>> = ({
  wrapperClassName,
  cardHeaderClassName,
  headerContent,
  leftHeaderContent,
  rightHeaderContent,
  footerContent,
  currentPage,
  total = 0,
  pageSize = 10,
  onPagination,
  hasMore,
  onHasMoreClick,
  onSizeChange,
  children,
  hidePageSize,
}) => {
  const defaultPageSize = useRef(pageSize);

  return (
    <div className={clsx(styles.TableCardWrapper, wrapperClassName)}>
      {(rightHeaderContent || leftHeaderContent || headerContent) && (
        <div className={clsx(styles.TableCardHeader, cardHeaderClassName)}>
          {leftHeaderContent}
          {rightHeaderContent && <div className={styles.RightContent}>{rightHeaderContent}</div>}
          {headerContent}
        </div>
      )}
      {children}
      <div className={clsx(styles.CardFooter, { [styles.NoPagination]: !currentPage && !total })}>
        {!!currentPage && !!total && (
          <Pagination currentPage={currentPage} pageSize={pageSize} total={total} onPagination={onPagination} />
        )}
        {!!hasMore && !!onHasMoreClick && (
          <Button
            disableRipple
            startIcon={<PlusIcon />}
            variant="text"
            color="primary"
            className={styles.LoadMoreButton}
            onClick={onHasMoreClick}
          >
            Load More
          </Button>
        )}
        {isUndefined(hasMore) && (
          <div className={styles.SizeWrapper}>
            {!!pageSize && !hidePageSize && (
              <>
                <Typography variant="subtitle2" className={styles.SizeTitle}>
                  Per Page:
                </Typography>
                <Select
                  value={pageSize}
                  onChange={(event: SelectChangeEvent<unknown>) => {
                    if (onSizeChange) {
                      onSizeChange(event.target.value as number);
                    }
                  }}
                  size="small"
                  inputProps={{
                    variant: 'filled',
                    className: styles.SizeInput,
                  }}
                  className={styles.SizeSelect}
                >
                  <OptionItem value={defaultPageSize.current}>{defaultPageSize.current} Rows</OptionItem>
                  <OptionItem value={50}>50 Rows</OptionItem>
                  <OptionItem value={100}>100 Rows</OptionItem>
                </Select>
              </>
            )}
          </div>
        )}
      </div>
      {footerContent && <footer>{footerContent}</footer>}
    </div>
  );
};
