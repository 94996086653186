import { FC, useState } from 'react';

import { useRequestDataExplorationMutation } from '@app/domain/data-exploration/api/data-exploration.api.hooks';
import { DataExplorationResults } from '@app/domain/data-exploration/components/DataExplorationResults';
import { DataExplorationSearch } from '@app/domain/data-exploration/components/DataExplorationSearch';
import { DataExplorationOutDto } from '@app/swagger-types';
import { Page } from '@app/hoc';

export const DataExplorationPage: FC = () => {
  const [data, setData] = useState<DataExplorationOutDto | null>(null);
  const [submittedQuestion, setSubmittedQuestion] = useState('');

  const { mutateAsync: requestDataExploration, isLoading: isRequestingDataExploration } =
    useRequestDataExplorationMutation({ onSuccess: setData });

  return (
    <Page responsiveExpansion title="Data Exploration" contentClassName="gap-6">
      <DataExplorationSearch
        isRequestingDataExploration={isRequestingDataExploration}
        requestDataExploration={requestDataExploration}
        setSubmittedQuestion={setSubmittedQuestion}
        setData={setData}
      />
      <DataExplorationResults
        isRequestingDataExploration={isRequestingDataExploration}
        submittedQuestion={submittedQuestion}
        data={data}
      />
    </Page>
  );
};
