import { FC, PropsWithChildren } from 'react';

interface Props {
  title: string;
}

export const UIStyledSection: FC<PropsWithChildren<Props>> = ({ title, children }) => {
  return (
    <div className="flex flex-col">
      <div className="p-2 text-center font-bold">{title}</div>
      {children}
    </div>
  );
};
