import { FC, useState } from 'react';
import { ESubmissionFileStatus, SubmissionFileCompactOutDto } from '@app/swagger-types';
import { Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { SubmissionUtils } from '../utils/submission.utils';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { ReUploadSubmissionDocumentModal } from '../modals/ReUploadSubmissionDocumentModal';
import {
  useDownloadSubmissionFileUrlMutation,
  useDeleteSubmissionFilesMutation,
  useGetSubmissionFileMutation,
} from '../api/submission.api.hooks';
import { useHandler } from '@app/hooks/useHandler.hook';
import { showErrorToast } from '@app/utils/toast.utils';
import { Button as ButtonUnstyled } from '@mui/base';
import { CircularProgress } from '@app/components/CircularProgress';
import { Tooltip } from '@app/components/tooltip/Tooltip';

interface Props {
  submissionId: string;
  file: SubmissionFileCompactOutDto;
  disabled?: boolean;
}

export const SubmissionFileRow: FC<Props> = ({ submissionId, file, disabled }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const { filename, status, id: fileId } = file;

  const { mutate: getFileInfo, isLoading } = useGetSubmissionFileMutation();
  const { mutate: deleteFile, isLoading: isDeleting } = useDeleteSubmissionFilesMutation();
  const { mutateAsync: downloadFile, isLoading: isDownloading } = useDownloadSubmissionFileUrlMutation();

  const handleDownload = useHandler(() => {
    getFileInfo(
      { submissionId, fileId },
      {
        onSuccess: async (data) => {
          const downloadLink = data.resultFiles?.find((file) => file?.includes('.pdf'));

          if (downloadLink) {
            await downloadFile({
              fileId: file.id,
              filename: downloadLink,
            });
          } else {
            showErrorToast('File not found');
          }
        },
      }
    );
  });

  const handleDelete = useHandler(() => {
    deleteFile({ submissionId, fileId: file.id });
  });

  return (
    <div className="flex flex-col gap-1">
      <div className="grid grid-cols-1 items-start  justify-between gap-3 lg:grid-cols-[2fr_1fr] lg:items-center">
        <div>
          {[ESubmissionFileStatus.PROCESSING_FAILED, ESubmissionFileStatus.ERROR, ESubmissionFileStatus.DONE].includes(
            status
          ) ? (
            <Tooltip title="Click to Download">
              <ButtonUnstyled
                className="group flex items-center gap-2 disabled:pointer-events-none"
                onClick={handleDownload}
                disabled={disabled || isDeleting}
              >
                <Typography
                  className="max-w-xs text-left text-s text-black/90 group-hover:truncate group-hover:underline"
                  style={{ textWrap: 'wrap' }}
                >
                  {filename}
                </Typography>
                {isLoading || isDownloading ? (
                  <CircularProgress size={24} />
                ) : (
                  <FileDownloadOutlinedIcon className="invisible text-black/50 group-hover:visible" />
                )}
              </ButtonUnstyled>
            </Tooltip>
          ) : (
            <Typography
              className="max-w-xs text-left text-s text-black/90 group-hover:truncate"
              style={{ textWrap: 'wrap' }}
            >
              {filename}
            </Typography>
          )}
        </div>
        <div className="flex w-full items-center justify-between gap-3">
          <div className="flex w-full max-w-52 items-center justify-between gap-3">
            <div className="flex items-center gap-3">
              {SubmissionUtils.getFileStatusIcon(status)}
              <Typography color="#00000099" className="text-m font-semibold">
                {SubmissionUtils.getFriendlyFileStatusLabel(status)}
              </Typography>
            </div>
            {[ESubmissionFileStatus.PROCESSING_FAILED, ESubmissionFileStatus.ERROR].includes(status) ? (
              <Tooltip title="Retry">
                <IconButton
                  size="large"
                  className="p-1"
                  onClick={() => setOpenDialog(true)}
                  disabled={disabled || isLoading || isDeleting || isDownloading}
                >
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
          <Tooltip title="Delete">
            <IconButton
              size="large"
              className="p-1"
              onClick={handleDelete}
              disabled={disabled || isLoading || isDownloading}
              loading={isDeleting}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <ReUploadSubmissionDocumentModal
        submissionId={submissionId}
        fileId={fileId}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </div>
  );
};
