import { useMemo, useEffect, PropsWithChildren, FC } from 'react';

import { EDeviceBreakpointType } from '@app/store/main-ui/types';
import { useStore } from '@app/store/useStore.hook';

import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

export const ResponsiveManager: FC<PropsWithChildren> = ({ children }) => {
  const { currentBreakpointDevice, updateBreakpoint } = useStore(({ currentBreakpointDevice, updateBreakpoint }) => ({
    currentBreakpointDevice,
    updateBreakpoint,
  }));

  const isMobileMq = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });
  const isTabletMq = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const finalDeviceBreakpoint = useMemo(() => {
    // DETECT IF THIS IS DESKTOP
    if (!isMobileMq && !isTabletMq) {
      return EDeviceBreakpointType.DESKTOP;
    }

    // DETECT IF THIS IS TABLET

    if (!isMobileMq && isTabletMq) {
      return EDeviceBreakpointType.TABLET;
    }

    // DETECT IF THIS IS MOBILE

    if (isMobileMq) {
      return EDeviceBreakpointType.PHONE;
    }

    return EDeviceBreakpointType.UNKNOWN;
  }, [isMobileMq, isTabletMq]);

  useEffect(() => {
    if (finalDeviceBreakpoint !== currentBreakpointDevice.deviceType) {
      updateBreakpoint(finalDeviceBreakpoint);
    }
  }, [currentBreakpointDevice.deviceType, finalDeviceBreakpoint, updateBreakpoint]);

  if (currentBreakpointDevice.deviceType === EDeviceBreakpointType.UNKNOWN) {
    return null;
  }

  return <>{children}</>;
};
