import styles from './Tooltip.module.scss';
import clsx from 'clsx';

import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

export type TooltipProps = MuiTooltipProps & {
  variant?: 'light' | 'dark';
  text?: string;
};

export const Tooltip: React.FC<TooltipProps> = ({ variant = 'light', title, text, children, ...props }) => {
  return (
    <MuiTooltip
      {...props}
      title={
        text ? (
          <div className="flex flex-col gap-0.5 py-3">
            <span className="text-xs font-semibold text-gray-800">{title}</span>
            <span className="pt-2 text-xxs text-gray-500">{text}</span>
          </div>
        ) : (
          title
        )
      }
      classes={{
        ...props?.classes,
        tooltip: clsx(styles.Tooltip, variant === 'light' ? styles.Light : styles.Dark, props.classes?.tooltip),
      }}
    >
      <span>{children}</span>
    </MuiTooltip>
  );
};
