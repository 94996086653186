import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { FC } from 'react';

import { IconButton } from '../buttons/icon-button/IconButton';

interface Props {
  name: string;
  onDelete?: () => void;
  disabled?: boolean;
}

export const FileBlock: FC<Props> = ({ name, onDelete, disabled }) => {
  return (
    <div className="flex w-full items-center justify-between gap-3 rounded-xl bg-indigo-100 p-3">
      <div className="flex items-center gap-3">
        <InsertDriveFileIcon />
        <Typography className="break-all">{name}</Typography>
      </div>
      {onDelete ? (
        <IconButton
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};
