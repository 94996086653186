import { AuthResDto } from './auth.dto';

import { authApiService } from '@app/services/api-service';

export abstract class AuthApi {
  static async me() {
    return authApiService.get<AuthResDto>('/private/auth/me');
  }

  static async signOut() {
    return authApiService.post<AuthResDto>('/private/auth/sign-out');
  }
}
