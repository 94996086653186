import { mapValues } from 'lodash';

import { parseNumbersFromString } from '@app/utils/string.utils';

import { PaletteMode, ThemeOptions } from '@mui/material';

import { tailwindTheme } from './tailwind-theme-generated';

type CustomTypographyVariantsKeys = keyof typeof tailwindTheme.fontSize;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    '2xl': true;
  }

  interface TypographyVariants extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends Record<CustomTypographyVariantsKeys, React.CSSProperties> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Record<CustomTypographyVariantsKeys, true> {}
}

const mapFontSizes = () => {
  const mapped = mapValues(tailwindTheme.fontSize, (fontSize: [string, { lineHeight: string }]) => ({
    fontSize: fontSize[0],
    lineHeight: fontSize[1].lineHeight,
  }));

  return mapped as unknown as Record<CustomTypographyVariantsKeys, React.CSSProperties>;
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: tailwindTheme.colors.primary,
            contrastText: tailwindTheme.colors.white,
          },
          text: {
            primary: tailwindTheme.colors.zinc[900],
            secondary: tailwindTheme.colors.zinc[400],
          },
        }
      : {
          // palette values for dark mode

          primary: {
            main: tailwindTheme.colors.primary,
          },
          background: {
            paper: tailwindTheme.colors.zinc[900],
          },
          text: {
            primary: tailwindTheme.colors.white,
            secondary: tailwindTheme.colors.zinc[400],
          },
        }),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: parseNumbersFromString(tailwindTheme.screens.sm),
      md: parseNumbersFromString(tailwindTheme.screens.md),
      lg: parseNumbersFromString(tailwindTheme.screens.lg),
      xl: parseNumbersFromString(tailwindTheme.screens.xl),
      '2xl': parseNumbersFromString(tailwindTheme.screens['2xl']),
    },
  },
  typography: {
    fontFamily: tailwindTheme.fontFamily.sans.join(','),
    ...mapFontSizes(),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          xl: 'h4',
          xxl: 'h3',
          xxxl: 'h2',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: tailwindTheme.colors.gray['100'],
          // padding: '4px, 20px, 4px, 20px',
          color: tailwindTheme.colors.gray['500'],
          borderRadius: 50,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: tailwindTheme.colors.gray['400'],
        },
        label: {
          fontWeight: 600,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.MuiTab-root': {
            color: tailwindTheme.colors.gray[500],
            background: 'none',

            '&:hover': {
              color: tailwindTheme.colors.gray[700],
            },
          },
          '&.Mui-selected': {
            color: tailwindTheme.colors.gray[700],
            fontWeight: 600,
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: tailwindTheme.colors.gray[200],
        },
      },
      defaultProps: {
        TabIndicatorProps: { style: { height: 3, borderRadius: '3px 3px 0 0' } },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 7,
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
        colorSecondary: {
          '&.Mui-checked:hover': {
            backgroundColor: 'transparent!important',
          },
        },
        track: {
          opacity: 1,
          backgroundColor: '#F9FAFB',
          borderRadius: 50,
          border: '1px solid #E5E7EB',

          '.Mui-checked.Mui-checked + &': {
            backgroundColor: '#FFB400',
            opacity: 1,
          },
          '.Mui-disabled + &, .Mui-checked.Mui-disabled + &': {
            opacity: '0.5 !important',
          },
        },
        thumb: {
          boxShadow: 'none',
          width: '18px',
          height: '18px',
          backgroundColor: '#6B7280',
          '.Mui-checked &': {
            backgroundColor: '#fff',
          },
        },
        switchBase: {
          padding: '10px',

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&:hover .MuiFormControlLabel-label': {
            color: '#FFB400',
          },
          '& .Mui-checked. + .MuiFormControlLabel-label': {
            color: '#00b400',
          },
          '&.Mui-disabled': {
            pointerEvents: 'none',
          },
        },
        label: {
          color: '#9CA3AF',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#221B4B',
          height: 10,
          margin: 0,
        },
        thumb: {
          height: 40,
          width: 40,
          backgroundColor: '#fff',
          boxShadow: '0px 4px 4px 0px #00000040',
          transition: 'none',

          '&.Mui-active, &:hover, &.Mui-focusVisible': {
            boxShadow: '0px 4px 4px 0px #00000060',
          },
        },
        markLabel: {
          fontSize: 12,
          fontWeight: 500,
          color: '#03AC00',
          top: -30,
        },
        track: {
          transition: 'none',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          backgroundColor: '#1f2937',
          height: 3,
          border: 0,
          borderRadius: 12,
        },
      },
    },
  },
  mixins: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiDataGrid: {
      // Headers, and top & bottom fixed rows
      containerBackground: '#f5f5f5',
    },
  },
});
