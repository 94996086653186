import { useStore } from '@app/store/useStore.hook';
import { Button } from '@app/components/buttons/button/Button';
import { DevKitSection } from '../DevKitSection';

export const AuthDevKitSection: React.FC = () => {
  const { currentUser, signOut } = useStore();

  if (!currentUser) {
    // TODO auth via modal
    return <DevKitSection title="Authentication">not authenticated</DevKitSection>;
  }

  return (
    <DevKitSection title="Authentication">
      <pre>{JSON.stringify(currentUser, null, 2)}</pre>
      <Button onClick={() => signOut()}>Sign Out</Button>
    </DevKitSection>
  );
};
