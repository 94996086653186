import { awsCalcDefaultValues, fullCalcDefaultValues } from '../forms/calc-default-values';
import { FullCalcValidationSchema, AwsCalcValidationSchema } from '../forms/calc.form';
import { useZodForm } from '@app/hooks/useZodForm';

export const useFullSubmissionCalculatorForm = () => {
  const formMethods = useZodForm(FullCalcValidationSchema, {
    mode: 'onChange',
    onSubmit: () => {},
    defaultValues: fullCalcDefaultValues,
  });

  return formMethods;
};

export const useAwsSubmissionCalculatorForm = () => {
  const formMethods = useZodForm(AwsCalcValidationSchema, {
    mode: 'onChange',
    onSubmit: () => {},
    defaultValues: awsCalcDefaultValues,
  });

  return formMethods;
};
