import { useMemo, useEffect, useCallback, PropsWithChildren, FC } from 'react';

import { useStore } from '@app/store/useStore.hook';

import { DarkMode, LightMode } from '@mui/icons-material';
import { createTheme, IconButton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { getDesignTokens } from '@app/styles/material-theme';

import { clsxm } from '@app/styles/clsxm';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { IS_NOT_PROD_ENV } from '@app/environment/typed-env';

export const ThemeWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { theme, setTheme } = useStore(({ theme, setTheme }) => ({ theme, setTheme }));
  const hasHydrated = useStore.use_hasHydrated();

  const muiTheme = useMemo(() => createTheme(getDesignTokens(theme)), [theme]);

  const isDarkTheme = theme === 'dark';

  // TAILWIND RELATED
  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkTheme]);

  const handleChangeTheme = useCallback(() => {
    setTheme(isDarkTheme ? 'light' : 'dark');
  }, [setTheme, isDarkTheme]);

  if (!hasHydrated) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline enableColorScheme />
      <ThemeProvider theme={muiTheme}>
        <div className={clsxm('absolute', 'right-4', 'top-3', 'z-[2000]', IS_NOT_PROD_ENV && 'group')}>
          {IS_NOT_PROD_ENV && (
            <div className="absolute -right-2 -top-2 hidden rounded bg-zinc-600/80 px-6 pb-4 pt-16 group-hover:block">
              <TypedLink
                to={Routes.roadmap.index}
                className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
              >
                Roadmap
              </TypedLink>
              <TypedLink
                to={Routes.uiKit.index}
                className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
              >
                UI Kit
              </TypedLink>
              <TypedLink
                to={Routes.devKit.index}
                className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
              >
                Dev Kit
              </TypedLink>
              <TypedLink
                to={Routes.index}
                className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
              >
                Home
              </TypedLink>
            </div>
          )}
          {IS_NOT_PROD_ENV && (
            <IconButton
              onClick={handleChangeTheme}
              className={clsxm(isDarkTheme ? 'bg-zinc-700' : 'bg-white', 'rounded-full')}
            >
              {isDarkTheme ? <DarkMode /> : <LightMode />}
            </IconButton>
          )}
        </div>
        <div className={isDarkTheme ? 'dark' : ''}>{children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
