import React from 'react';

import { Table } from '../../../../components/table/Table';
import { TableColumn } from '../../../../components/table/TableTypes';
import {
  renderTrendDetectedBgColor,
  renderTrendDetectedLabel,
  renderTrendDetectedTextColor,
} from '../../utils/aws.utils';
import { AssessmentClaimOutDto, AwsCalcOutDto } from '../../types/calc.types';

type RowType = AssessmentClaimOutDto & {
  header: string;
};

type Props = Pick<AwsCalcOutDto, 'assessmentSummary'> & {
  pdf?: boolean;
};

export const AwsAssessmentSummaryTable: React.FC<Props> = ({ pdf, assessmentSummary }) => {
  const rows: RowType[] = [
    {
      header: 'Medical claims',
      trendOver: assessmentSummary.medicalClaims.trendOver,
      trendExpected: assessmentSummary.medicalClaims.trendExpected,
    },
    {
      header: 'Indemnity claims',
      trendOver: assessmentSummary.indemnityClaims.trendOver,
      trendExpected: assessmentSummary.indemnityClaims.trendExpected,
    },
    {
      header: 'Avg medical loss per incident',
      trendOver: assessmentSummary.avgMedicalLossPerIndicent.trendOver,
      trendExpected: assessmentSummary.avgMedicalLossPerIndicent.trendExpected,
    },
    {
      header: 'Avg indemnity loss per incident',
      trendOver: assessmentSummary.avgIndemnityLossPerIncident.trendOver,
      trendExpected: assessmentSummary.avgIndemnityLossPerIncident.trendExpected,
    },
  ];

  const cols: TableColumn<RowType>[] = [
    {
      headerName: 'Macro Claims and Avg Loss Analysis',
      field: 'header',
      headAlign: 'left',
      align: 'left',
      width: 150,
    },
    {
      headerName: 'Trend Over',
      field: 'trendOver',
      headAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      headerName: 'Trend Detected',
      field: 'trendExpected',
      headAlign: 'center',
      align: 'center',
      width: 150,
      render: ({ trendExpected }) => (
        <div
          className="rounded p-2 font-bold"
          style={{
            backgroundColor: renderTrendDetectedBgColor(trendExpected),
            color: renderTrendDetectedTextColor(trendExpected),
          }}
        >
          {renderTrendDetectedLabel(trendExpected)}
        </div>
      ),
    },
  ];

  return <Table cols={cols} tableData={rows} pdf={pdf} hidePageSize />;
};
