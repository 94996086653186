import { NestedKeyOf } from './type.utils';

type SortOrder = 'desc' | 'asc';

export const buildSortParam = <T extends object>(field: NestedKeyOf<T>, mode: 'desc' | 'asc') => `${field},${mode}`;

interface ParsedSortParam {
  field: string;
  order: SortOrder;
}

export const parseSortParam = (param: string): ParsedSortParam | null => {
  const parts = param.split(',');

  if (parts.length !== 2) {
    console.error('Invalid search parameter format. Expected format: "field,order"');
    return null;
  }

  const [field, order] = parts;

  if (order !== 'asc' && order !== 'desc') {
    console.error('Invalid order value. Expected "asc" or "desc"');
    return null;
  }

  return {
    field: field,
    order: order as SortOrder,
  };
};
