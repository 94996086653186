import { Routes } from '@app/constants/routes';

import { UIKitModule } from './UIKitModule';
import { UIKitPage } from './UIKitPage';
import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';

export const UIKitRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.uiKit.index,
  element: <UIKitModule />,
  children: [{ index: true, element: <UIKitPage /> }],
});
