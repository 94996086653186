import { SubmissionStiAwsPrefillInDto, SubmissionStiFullPrefillInDto } from '@app/swagger-types';
import { AwsCalcValidationSchema, FullCalcValidationSchemaType } from '../forms/calc.form';
import { AwsCalcInDto, FullCalcInDto } from '../types/calc.types';

export const createAwsCalcDto = ({
  emr,
  companyName,
  yearsInBusiness,
  stateInputs,
  recentIndemnityClaims,
  recentMedicalClaims,
  recentTotalIndemnityLosses,
  recentTotalMedicalLosses,
  previousIndemnityClaims,
  previousMedicalClaims,
  previousTotalIndemnityLosses,
  previousTotalMedicalLosses,
  olderIndemnityClaims,
  olderMedicalClaims,
  olderTotalIndemnityLosses,
  olderTotalMedicalLosses,
}: AwsCalcValidationSchema): AwsCalcInDto => {
  const Dto: AwsCalcInDto = {
    companyName,
    emr: Number(emr),
    yearsInBusiness: Number(yearsInBusiness),
    mostRecent: {
      medicalClaims: Number(recentMedicalClaims),
      indemnityClaims: Number(recentIndemnityClaims),
      totalMedicalLosses: Number(recentTotalMedicalLosses),
      totalIndemnityLosses: Number(recentTotalIndemnityLosses),
    },
    stateInputs: stateInputs.map(({ payroll, ncci, ...state }) => ({
      ...state,
      ncci: ncci?.ncci || '',
      payroll: Number(payroll),
    })),
  };

  if (previousIndemnityClaims && previousMedicalClaims && previousTotalIndemnityLosses && previousTotalMedicalLosses) {
    Dto.previous = {
      medicalClaims: Number(previousMedicalClaims),
      indemnityClaims: Number(previousIndemnityClaims),
      totalMedicalLosses: Number(previousTotalMedicalLosses),
      totalIndemnityLosses: Number(previousTotalIndemnityLosses),
    };
  }

  if (olderIndemnityClaims && olderMedicalClaims && olderTotalIndemnityLosses && olderTotalMedicalLosses) {
    Dto.twoYearsPrevious = {
      medicalClaims: Number(olderMedicalClaims),
      indemnityClaims: Number(olderIndemnityClaims),
      totalMedicalLosses: Number(olderTotalMedicalLosses),
      totalIndemnityLosses: Number(olderTotalIndemnityLosses),
    };
  }
  return Dto;
};

export const createFullCalcDto = ({
  emr,
  trir,
  da,
  // rt,
  stateInputs,
  naics,
  ...values
}: FullCalcValidationSchemaType): FullCalcInDto => ({
  ...values,
  naicsCode: naics?.naics || '',
  emr: Number(emr),
  trir: Number(trir),
  da: Number(da),
  rt: 0,
  stateInputs: stateInputs.map(({ payroll, ncci, ...state }) => ({
    ...state,
    ncci: ncci?.ncci || '',
    payroll: Number(payroll),
  })),
});

export const createFullPrefillDto = (data: FullCalcValidationSchemaType): SubmissionStiFullPrefillInDto => ({
  naics: data.naics?.naics || '',
  trir: data.trir ? Number(data.trir) : undefined,
  dart: data.da ? Number(data.da) : undefined,
  emr: data.emr ? Number(data.emr) : undefined,
  safetyPrograms: data.safety,
  healthCoverage: data.health,
  supplementalCoverage: data.supplemental,
  payrollEntries: data.stateInputs.map((input) => ({
    ...input,
    amount: input?.payroll ? Number(input.payroll) : undefined,
    ncci: input?.ncci?.ncci,
    state: input.stateCode,
  })),
});

export const createAwsPrefillDto = (data: AwsCalcValidationSchema): SubmissionStiAwsPrefillInDto => ({
  emr: data.emr ? Number(data.emr || 0) : undefined,
  yearsInBusiness: data.yearsInBusiness ? Number(data.yearsInBusiness || 0) : undefined,
  recentYear: {
    medicalClaims: data.recentMedicalClaims ? Number(data.recentMedicalClaims || 0) : undefined,
    totalMedicalLosses: data.recentTotalMedicalLosses ? Number(data.recentTotalMedicalLosses || 0) : undefined,
    indemnityClaims: data.recentIndemnityClaims ? Number(data.recentIndemnityClaims || 0) : undefined,
    totalIndemnityLosses: data.recentTotalIndemnityLosses ? Number(data.recentTotalIndemnityLosses || 0) : undefined,
  },
  previousYear: {
    medicalClaims: data.previousMedicalClaims ? Number(data.previousMedicalClaims || 0) : undefined,
    totalMedicalLosses: data.previousTotalMedicalLosses ? Number(data.previousTotalMedicalLosses || 0) : undefined,
    indemnityClaims: data.previousIndemnityClaims ? Number(data.previousIndemnityClaims || 0) : undefined,
    totalIndemnityLosses: data.previousTotalIndemnityLosses
      ? Number(data.previousTotalIndemnityLosses || 0)
      : undefined,
  },
  twoYearsAgo: {
    medicalClaims: data.olderMedicalClaims ? Number(data.olderMedicalClaims || 0) : undefined,
    totalMedicalLosses: data.olderTotalMedicalLosses ? Number(data.olderTotalMedicalLosses || 0) : undefined,
    indemnityClaims: data.olderIndemnityClaims ? Number(data.olderIndemnityClaims || 0) : undefined,
    totalIndemnityLosses: data.olderTotalIndemnityLosses ? Number(data.olderTotalIndemnityLosses || 0) : undefined,
  },
  payrollEntries: data.stateInputs.map((input) => ({
    zipCode: input?.zipCode,
    amount: input?.payroll ? Number(input.payroll) : undefined,
    ncci: input?.ncci?.ncci,
    state: input.stateCode,
  })),
});
