import { lazy } from 'react';
import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';
import { TypedNavigate } from '@app/router';
import { AdminModule } from './AdminModule';
import { ERole } from '@app/auth-types';
import { HAS_ACCESS_TO_ADMIN } from '@app/environment/typed-env';

const UserManagementPage = lazy(() => import('@app/modules/admin/pages/UserManagementPage'));
const AddUserPage = lazy(() => import('@app/modules/admin/pages/AddUserPage'));
const EditUserPage = lazy(() => import('@app/modules/admin/pages/EditUserPage'));

export const AdminRoutes: MyRouteObject = {
  path: Routes.admin.index,
  element: <AdminModule />,
  role: [ERole.ALL_ADMINISTRATOR, ERole.INSURATE_USER_ADMIN, ERole.USISA_USER_ADMIN],
  disabled: !HAS_ACCESS_TO_ADMIN,
  children: [
    { path: Routes.admin.users, element: <UserManagementPage /> },
    { path: Routes.admin.createUser, element: <AddUserPage /> },
    { path: Routes.admin.editUser, element: <EditUserPage /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.admin.users} /> },
  ],
};
