import { ClaimFilter } from '@app/swagger-types';
import {
  generateDefaultFilters,
  getCoinFilterKeys,
  getDateFilterKeys,
} from '@app/domain/claim/utils/claim.filter.utils';

export const ALL_VALUES_OPTION = 'ALL_VALUES_OPTION';

export const DEFAULT_CLAIM_FILTER_VALUES: ClaimFilter = generateDefaultFilters();

export const COIN_FILTER_FIELDS: Array<keyof ClaimFilter> = getCoinFilterKeys();

export const DATE_FILTER_FIELDS: Array<keyof ClaimFilter> = getDateFilterKeys();
