import { FC } from 'react';

import { useSubmissionCalculatorFormsContext } from '../contexts/SubmissionCalculatorFormsContext';
import { ESubmissionSafeTierRatingTab } from '../widgets/SubmissionTabs';
import { Button } from '@app/components/buttons/button/Button';
import { clsxm } from '@app/styles/clsxm';

interface Props {}

export const SubmissionCalculateSwitcher: FC<Props> = () => {
  const { calcTab, setCalcTab } = useSubmissionCalculatorFormsContext();

  return (
    <div className="grid w-full max-w-[300px] grid-cols-2 items-center gap-4 rounded-md bg-gray-100 p-1">
      <Button
        variant="contained"
        disabled={calcTab === ESubmissionSafeTierRatingTab.STANDARD}
        onClick={() => setCalcTab(ESubmissionSafeTierRatingTab.STANDARD)}
        className={clsxm(
          'rounded-md border-none p-1 text-center text-xs font-normal hover:shadow-none',
          calcTab === ESubmissionSafeTierRatingTab.STANDARD
            ? 'cursor-auto bg-white shadow-tabs'
            : 'cursor-pointer bg-transparent text-[#00000061] hover:text-[#5b5b5b61]'
        )}
      >
        Standard
      </Button>
      <Button
        disabled={calcTab === ESubmissionSafeTierRatingTab.LAST_MILE}
        variant="contained"
        onClick={() => setCalcTab(ESubmissionSafeTierRatingTab.LAST_MILE)}
        className={clsxm(
          'rounded-md border-none p-1 text-center text-xs font-normal hover:shadow-none',
          calcTab === ESubmissionSafeTierRatingTab.LAST_MILE
            ? 'cursor-auto bg-white shadow-tabs'
            : 'cursor-pointer bg-transparent text-[#00000061] hover:text-[#5b5b5b61]'
        )}
      >
        Last Mile
      </Button>
    </div>
  );
};
