import { EOutcomeType } from '@app/swagger-types';
import { z, ZodTypeAny } from 'zod';

// Function to validate an object against a given schema
export function validateObject(obj: Record<string, any>, schema: ZodTypeAny): boolean {
  return schema.safeParse(obj)?.success;
}

export const pageableData = <T extends z.ZodTypeAny>(data: T) => {
  return z.object({
    result: z.array(data),
    total: z.number(),
  });
};

export const ResponseDetailErrorSchema = z.object({
  code: z.string().optional().default('Unknown'),
  message: z.string().optional().default('Unknown'),
});

export const VersionSchema = z.object({
  reqTs: z.string().optional(),
  model: z.string().optional(),
});

export const dataWithTempCopyWithVersionAndWarnings = <R extends z.ZodTypeAny, P extends z.ZodTypeAny>(
  data: R,
  payload: P
) => {
  return z.object({
    isSuccessful: z.boolean().optional(),
    responseId: z.number().optional(),
    responseType: z.nativeEnum(EOutcomeType).optional(),
    version: VersionSchema.optional().default({ reqTs: '', model: '' }),
    data: data.optional(),
    payload,
    warnings: z.array(ResponseDetailErrorSchema).optional(),
    errors: z.array(ResponseDetailErrorSchema).optional(),
  });
};
