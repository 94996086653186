import { Button } from '@app/components/buttons/button/Button';
import { Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useState } from 'react';
import { useHandler } from '@app/hooks/useHandler.hook';
import { FileDropZoneWrapper } from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import { FileBlock } from '@app/components/file-drop-zone-wrapper/FileBlock';
import { useReuploadSubmissionFilesMutation } from '../api/submission.api.hooks';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';

interface Props {
  submissionId: string;
  fileId: string | number;
  open: boolean;
  onClose: () => void;
}

export const ReUploadSubmissionDocumentModal: FC<Props> = ({ fileId, submissionId, open, onClose }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleClose = useHandler(() => {
    onClose();
    setFile(null);
  });

  const { mutateAsync: reupload, isLoading } = useReuploadSubmissionFilesMutation({
    onSuccess: () => {
      handleClose();
    },
  });

  const handleSubmit = useHandler(async () => {
    if (file) {
      const formData = new FormData();

      formData.append('file', file);

      await reupload({ fileId, submissionId, formData });
    }
  });

  return (
    <Modal open={open} onClose={handleClose} onClick={(e) => e.stopPropagation()}>
      <div className="absolute left-1/2 top-1/2 w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-7">
        <div className="flex justify-between pb-5">
          <Typography className="text-l font-semibold">Reupload</Typography>
          <IconButton onClick={handleClose} className="absolute right-4 top-4" disabled={isLoading}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="flex flex-col items-center gap-6">
          <Typography className="text-center text-s">
            Re-upload your document and let us process it for you. Ensure this is a document we can process (Acord
            Application, EMR, or Loss Run Report).
          </Typography>
          <div className="flex w-full flex-col items-center justify-start gap-2">
            <FileDropZoneWrapper onChange={setFile} accept="application/pdf" disabled={isLoading} />
            {file ? (
              <FileBlock key={file.name} name={file.name} onDelete={() => setFile(null)} disabled={isLoading} />
            ) : null}
          </div>
          <div className="mt-10 flex w-full justify-evenly gap-4">
            <Button onClick={handleClose} variant="outlined" color="primary" fullWidth disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              disabled={!file}
              loading={isLoading}
            >
              Extract Data
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
