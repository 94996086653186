import styles from './Input.module.scss';
import { get } from 'lodash';
import React, { useState } from 'react';

import { FormControl as FormControlUnstyled } from '@mui/base';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormHelperText,
  FormHelperTextProps,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
  InputLabelProps,
} from '@mui/material';
import { clsxm } from '@app/styles/clsxm';
import { withQA } from '@app/debug/withQA';

export interface InputProps extends Omit<InputBaseProps, 'ref'> {
  errorMessage?: string | boolean | undefined;
  label?: string | React.ReactNode;
  labelRequired?: boolean;
  labelProps?: InputLabelProps;
  labelVariant?: 'regular' | 'futura';
  helperTextProps?: FormHelperTextProps;
  variant?: 'outlined' | 'filled' | 'standard';
  inputSize?: 'large' | 'medium' | 'small';
  inAutocomplete?: boolean;
  contentClassName?: string;
  notched?: boolean;
}

export const Input = withQA(
  {
    name: 'Input',
    debugProps: ({ asString, asEnum }) => ({
      errorMessage: asString(''),
      inputSize: asEnum(['small', 'large', 'medium']),
      type: asEnum(['text', 'password']),
    }),
  },
  React.forwardRef<HTMLInputElement, InputProps>(
    (
      {
        errorMessage,
        label,
        required,
        labelProps,
        labelVariant = 'futura',
        helperTextProps,
        className,
        classes,
        variant = 'outlined',
        inputSize = 'medium',
        contentClassName,
        ...props
      },
      ref
    ) => {
      const [hiddenPassword, toggleVisibility] = useState(true);
      const propsCopy = { ...props };

      if ('notched' in props) {
        delete propsCopy.notched;
      }

      return (
        <FormControlUnstyled className={clsxm(styles.root, className)} error={!!errorMessage || !!props.error}>
          {label && (
            <InputLabel
              htmlFor={props.id}
              disableAnimation
              {...labelProps}
              className={clsxm(
                'text-slate-800',
                styles.label,
                get(styles, `${labelVariant}`),
                get(styles, inputSize),
                labelProps?.className,
                props.disabled && 'text-gray-500/40'
              )}
            >
              {required && <span>*</span>}
              {label}
            </InputLabel>
          )}

          <InputBase
            error={!!errorMessage || !!props.error}
            className={clsxm(
              styles.input,
              get(styles, inputSize),
              get(styles, variant),
              variant !== 'standard' && 'bg-white',
              'schema-light dark:schema-dark',
              contentClassName
            )}
            classes={{
              ...classes,
              focused: styles.focused,
              error: styles.error,
              input: clsxm(classes?.input, !!props?.startAdornment && 'pl-1'),
            }}
            inputRef={ref}
            {...propsCopy}
            type={props.type === 'password' ? (hiddenPassword ? 'password' : 'text') : props.type}
            endAdornment={
              props.type === 'password' ? (
                <InputAdornment position="end" className={styles['end-adornment']}>
                  <IconButton disableRipple color="inherit" onClick={() => toggleVisibility((prev) => !prev)}>
                    {hiddenPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : (
                props.endAdornment && (
                  <InputAdornment position="end" className={styles['end-adornment']}>
                    {props.endAdornment}
                  </InputAdornment>
                )
              )
            }
          />
          {errorMessage && (
            <FormHelperText className={styles['error-text']} {...helperTextProps}>
              {errorMessage}
            </FormHelperText>
          )}
        </FormControlUnstyled>
      );
    }
  )
);
